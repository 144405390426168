import React from "react";
import "../Footer/footer.css";
import logoImg from "../../assets/images/logo_amis.png";
import cards from "../../assets/images/cards.png";
import social_icon1 from "../../assets/images/social_icon1.png";
import social_icon2 from "../../assets/images/social_icon2.png";
import social_icon3 from "../../assets/images/social_icon3.png";
import social_icon4 from "../../assets/images/social_icon4.png";
import social_icon5 from "../../assets/images/social_icon5.png";
import social_icon6 from "../../assets/images/social_icon6.png";
import foot_design from "../../assets/images/foot_design.png";

const Footer = () => {
  return (
    <div>
      <div class="reg_section">
        <div class="bg_m4 pt-4 pb-4">
          <div class="container mb-2">
            <h1 class="block__title fw-bold mb-4 text-center">
              AIMS Registration Details
            </h1>
            <div class="row">
              <div class="col">
                <table class="table table-hover bg-white">
                  <tbody>
                    <tr>
                      <td class="table-warning">Unique Id of VO/NGO</td>
                      <td>TS/2017/0167167</td>
                    </tr>
                    <tr>
                      <td class="table-warning">Registered With</td>
                      <td>Registrar of Societies</td>
                    </tr>
                    <tr>
                      <td class="table-warning">Type of NGO</td>
                      <td>Registered Societies (Non-Government)</td>
                    </tr>
                    <tr>
                      <td class="table-warning">Registration No</td>
                      <td>1808</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col">
                <table class="table table-hover bg-white">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td class="table-warning">Act name </td>
                      <td>
                        A.P.STATE SOCIETIES REGISTRATION ACT, 2001(No 35 of 2001)
                      </td>
                    </tr>
                    <tr>
                      <td class="table-warning">City of Registration</td>
                      <td>Secunderabad</td>
                    </tr>
                    <tr>
                      <td class="table-warning">State of Registration</td>
                      <td>TELANGANA</td>
                    </tr>
                    <tr>
                      <td class="table-warning">Date of Registration</td>
                      <td>10-11-2005</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div class="col-12">
                <a href="https://ngolist.trustngo.net/aims-human-rights-society-surveys/">
                  https://ngolist.trustngo.net/aims-human-rights-society-surveys/
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer py-3">
        <div className="container mb-2">
          <div className="row ">
            <div className="col-md-6 text-start">
              {/* <img src={logoImg} alt="Logo" className="max_width_200" /> */}
              {/* <ul className="p-0 mt-4 social_icons">
                            <li><a href="#!"><i class="fa fa-facebook-square" aria-hidden="true"></i></a></li>
                            <li><a href="#!"><i class="fa fa-twitter-square" aria-hidden="true"></i></a></li>
                            <li><a href="#!"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                             </ul> */}
              <h6 className="text-start mt-2">INDIA (Registered Office)</h6>
              <p className="text-start">
                {/* Plot No : 1, H.No - 1-24-21/4 (24-387, Om Sri Sai Nagar Colony,
                Lothukunta, Alwal, Secunderabad, Telangana 500015{" "} */}
                Plot No. 1, H.No. 1-24-21/4, Sri Sai Nagar, Lothukunta, Alwal, Secunderabad - 500015, Telangana, India.
              </p>

              <h6 className="text-start mt-2">INDIA (Admin Office)</h6>
              <p className="text-start">
                {/* Extension to Road Number 36, Phase 1, Jubilee Hills, Plot
                No.115, Madhapur Rd, Kavuri Hills, Hyderabad, Telangana 500033,
                India. */}
                Extension to Road Number 36, Phase 1, Jubilee Hills, Plot No.115, Madhapur Rd, Kavuri Hills, Hyderabad, Telangana 500033, India.
              </p>

              {/* <h6 className="text-start  mt-2">USA</h6>
                                <p  className="text-start">2 Kilmer Rd , Edison NJ 08817</p> */}
            </div>
            <div className="col-sm-6 col-md-3">
              <h6 className="text-start">Links</h6>
              <ul className="list_m8">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/activities">Activities</a>
                </li>
                <li>
                  <a href="/team">Team</a>
                </li>
                <li>
                  <a href="/event-gallery">Event Gallery</a>
                </li>
                <li><a href="/english-art-gallery">English Art Gallery</a></li>
                <li><a href="/telugu-art-gallery">Telugu Art Gallery</a></li>


                <li>
                  <a href="/know-your-right">Know Your Right </a>
                </li>



              </ul>
            </div>

            <div className="col-sm-6 col-md-3">
              <h6 className="text-start">CONTACT</h6>
              <ul className="text-start m-0 p-0">
                <li className="mb-2">
                  <a href="#"><span className="fw-semibold">Phone: </span>
                  <p className="m-0 mb-1">040-27970510 / 040-27960130</p>
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#"><span className="fw-semibold">Mobile: </span>
                  <p  className="m-0 mb-1">+91-9848861801</p>
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#"><span className="fw-semibold">Email: </span>
                  <p  className="m-0 mb-1">
                  madhavvasu@hotmail.com
                  </p>
                  </a>
                </li>
              </ul>
            </div>

          </div>
        </div>
        <img src={foot_design} className="img-fluid" />
        <div className="container">
          <div className="row flex-column flex-md-row px-2 mt-2 justify-content-center">
            <p className="block-41__copyrights mb-0">
              © 2024 AIMS Human Rights Society Survey’s. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
