import React from "react";
import "../../styles/featured.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Pagination, Navigation } from "swiper";

import campainsImg1 from "../../assets/images/women-rights.jpg";
import child_abuse from "../../assets/images/child_abuse.jpg";


import road from "../../assets/images/road.webp";


import campainsImg2 from "../../assets/images/image-3.png";
//import campainsImg3 from "../../assets/images/campaigns3.png";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Link } from "react-router-dom";

import 'react-circular-progressbar/dist/styles.css';
const percentage = 66;

const Featured = () => {
    return (
        <div className="featured off_bg d-none">
              <div className="block__header col-md-8  offset-md-2 text-center mb-4">
                    <h1 className="block__title mb-3">Featured  <span className="highlight">Campaigns</span></h1>
                    <p className="block__paragraph mb-0 color_1">Discover the power of giving - explore our featured campaigns and join a community of changemakers, making a difference for causes that matter, one donation at a time.</p>
                </div>
            <div className="container">
            <div className="row">
              
            </div>
            <div className="row">
                <div className="col-md-12 mb-2-1rem">
            <Swiper
                slidesPerView={3}
                spaceBetween={55}
                pagination={{
                clickable: true,
                }}
                breakpoints={{
                    300: {
                      slidesPerView: 1,
                    
                    },
                    768: {
                      slidesPerView: 2,
                  
                    },
                    1024: {
                      slidesPerView: 3,
                      
                    },
                  }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="featured__card">
                        <div className="featured__card-head">
                        <div class="ribbon ribbon-top-right"><span>Tax Benefit</span></div>
                            <img src={campainsImg1} alt="Swiper Img"/>
                            <p className="banner_cap">Women Rights Protection</p>
                        </div>
                        <div className="featured__card-body">
                            
                            <p className="circle1 text-left">
                                <span>VS</span>
                                <i>by voice of slum</i>
                            </p>

                            <p className="text-left fweight_600">Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                          
                           <div className="row">
                             <div className="col-2 pt-1" >
                             <CircularProgressbar value={percentage} text={`${percentage}%`}   styles={buildStyles({  backgroundColor: '#F9991E' })} />
                             </div>
                             <div className="col-10 ps-0">
                                <p className="m-0"><span className="fweight_600"><i class="fa fa-inr" aria-hidden="true"></i> 3,50,000</span> raised out of<span className=""> <i class="fa fa-inr" aria-hidden="true"></i> <span className="fweight_600">9,00,000</span></span></p>
                                <ul className="list_m7"> 
                                    <li><i class="fa fa-clock-o" aria-hidden="true"></i> <span>35</span> Days Left</li>
                                    <li><i class="fa fa-users" aria-hidden="true"></i> <span>3553</span> Supporters</li>
                                </ul>
                             </div>
                             <div className="col-6">
                             <button className="btn btn_m4 w-100">Share</button>
                             </div>
                             <div className="col-6 ps-0"> 
                              <Link to="exploreview" className="btn btn_m5 w-100 ">Donate</Link> 
                            </div>
                           </div>
                            
                            
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="featured__card">
                        <div className="featured__card-head">
                        <div class="ribbon ribbon-top-right"><span>Tax Benefit</span></div>
                            <img src={child_abuse} alt="Swiper Img"/>
                            <p className="banner_cap">Child Abuse and   Child Labour</p>
                        </div>
                        <div className="featured__card-body">
                            
                            <p className="circle1 text-left">
                                <span>VS</span>
                                <i>by voice of slum</i>
                            </p>

                            <p className="text-left fweight_600">Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                          
                           <div className="row">
                             <div className="col-2 pt-1" >
                             <CircularProgressbar value={percentage} text={`${percentage}%`}   styles={buildStyles({  backgroundColor: '#F9991E' })} />
                             </div>
                             <div className="col-10 ps-0">
                                <p className="m-0"><span className="fweight_600"><i class="fa fa-inr" aria-hidden="true"></i> 3,50,000</span> raised out of<span className=""> <i class="fa fa-inr" aria-hidden="true"></i> <span className="fweight_600">9,00,000</span></span></p>
                                <ul className="list_m7"> 
                                    <li><i class="fa fa-clock-o" aria-hidden="true"></i> <span>35</span> Days Left</li>
                                    <li><i class="fa fa-users" aria-hidden="true"></i> <span>3553</span> Supporters</li>
                                </ul>
                             </div>
                             <div className="col-6">
                             <button className="btn btn_m4 w-100">Share</button>
                             </div>
                             <div className="col-6 ps-0">
                              <Link to="exploreview" className="btn btn_m5 w-100 ">Donate</Link> 
                            </div>
                           </div>
                            
                            
                        </div>
                    </div>
                </SwiperSlide>
                 <SwiperSlide>
                    <div className="featured__card">
                        <div className="featured__card-head">
                        <div class="ribbon ribbon-top-right"><span>Tax Benefit</span></div>
                            <img src={road} alt="Swiper Img"/>
                            <p className="banner_cap">Road Safety  Awareness</p>
                        </div>
                        <div className="featured__card-body">
                            
                            <p className="circle1 text-left">
                                <span>VS</span>
                                <i>by voice of slum</i>
                            </p>

                            <p className="text-left fweight_600">Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                          
                           <div className="row">
                             <div className="col-2 pt-1" >
                             <CircularProgressbar value={percentage} text={`${percentage}%`}   styles={buildStyles({  backgroundColor: '#F9991E' })} />
                             </div>
                             <div className="col-10 ps-0">
                                <p className="m-0"><span className="fweight_600"><i class="fa fa-inr" aria-hidden="true"></i> 3,50,000</span> raised out of<span className=""> <i class="fa fa-inr" aria-hidden="true"></i> <span className="fweight_600">9,00,000</span></span></p>
                                <ul className="list_m7"> 
                                    <li><i class="fa fa-clock-o" aria-hidden="true"></i> <span>35</span> Days Left</li>
                                    <li><i class="fa fa-users" aria-hidden="true"></i> <span>3553</span> Supporters</li>
                                </ul>
                             </div>
                             <div className="col-6">
                             <button className="btn btn_m4 w-100">Share</button>
                             </div>
                             <div className="col-6 ps-0">
                              <Link to="exploreview" className="btn btn_m5 w-100 ">Donate</Link> 
                            </div>
                           </div>
                            
                            
                        </div>
                    </div>
                </SwiperSlide>
                 <SwiperSlide>
                    <div className="featured__card">
                        <div className="featured__card-head">
                        <div class="ribbon ribbon-top-right"><span>Tax Benefit</span></div>
                            <img src={campainsImg1} alt="Swiper Img"/>
                            <p className="banner_cap">Hunger</p>
                        </div>
                        <div className="featured__card-body">
                            
                            <p className="circle1 text-left">
                                <span>VS</span>
                                <i>by voice of slum</i>
                            </p>

                            <p className="text-left fweight_600">Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                          
                           <div className="row">
                             <div className="col-2 pt-1" >
                             <CircularProgressbar value={percentage} text={`${percentage}%`}   styles={buildStyles({  backgroundColor: '#F9991E' })} />
                             </div>
                             <div className="col-10 ps-0">
                                <p className="m-0"><span className="fweight_600"><i class="fa fa-inr" aria-hidden="true"></i> 3,50,000</span> raised out of<span className=""> <i class="fa fa-inr" aria-hidden="true"></i> <span className="fweight_600">9,00,000</span></span></p>
                                <ul className="list_m7"> 
                                    <li><i class="fa fa-clock-o" aria-hidden="true"></i> <span>35</span> Days Left</li>
                                    <li><i class="fa fa-users" aria-hidden="true"></i> <span>3553</span> Supporters</li>
                                </ul>
                             </div>
                             <div className="col-6">
                             <button className="btn btn_m4 w-100">Share</button>
                             </div>
                             <div className="col-6 ps-0">
                              <Link to="exploreview" className="btn btn_m5 w-100 ">Donate</Link> 
                            </div>
                           </div>
                            
                            
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
            </div>
            </div>
            </div>
        </div>
//         <div className="featured off_bg">
//         <div className="block__header col-md-8  offset-md-2 text-center mb-4">
//               <h1 className="block__title mb-3">Featured  <span className="highlight">Campaigns</span></h1>
//               <p className="block__paragraph mb-0 color_1">Discover the power of giving - explore our featured campaigns and join a community of changemakers, making a difference for causes that matter, one donation at a time.</p>
//           </div>
//       <div className="container">
//       <div className="row">
        
//       </div>
//       <div className="row">
//           <div className="col-md-12 mb-2-1rem">
//       <Swiper
//           slidesPerView={3}
//           spaceBetween={55}
//           pagination={{
//           clickable: true,
//           }}
//           breakpoints={{
//               300: {
//                 slidesPerView: 1,
              
//               },
//               768: {
//                 slidesPerView: 2,
            
//               },
//               1024: {
//                 slidesPerView: 3,
                
//               },
//             }}
//           navigation={true}
//           modules={[Pagination, Navigation]}
//           className="mySwiper"
//       >
//           <SwiperSlide>
//               <div className="featured__card">
//                   <div className="featured__card-head">
//                   <div class="ribbon ribbon-top-right"><span>Tax Benefit</span></div>
//                       <img src={campainsImg1} alt="Swiper Img"/>
//                       <p className="banner_cap">Hunger</p>
//                   </div>
//                   <div className="featured__card-body">
                      
//                       <p className="circle1 text-left">
//                           <span>VS</span>
//                           <i>by voice of slum</i>
//                       </p>

//                       <p className="text-left fweight_600">Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                    
//                      <div className="row">
//                        <div className="col-2 pt-1" >
//                        <CircularProgressbar value={percentage} text={`${percentage}%`}   styles={buildStyles({  backgroundColor: '#F9991E' })} />
//                        </div>
//                        <div className="col-10 ps-0">
//                           <p className="m-0"><span className="fweight_600"><i class="fa fa-inr" aria-hidden="true"></i> 3,50,000</span> raised out of<span className=""> <i class="fa fa-inr" aria-hidden="true"></i> <span className="fweight_600">9,00,000</span></span></p>
//                           <ul className="list_m7"> 
//                               <li><i class="fa fa-clock-o" aria-hidden="true"></i> <span>35</span> Days Left</li>
//                               <li><i class="fa fa-users" aria-hidden="true"></i> <span>3553</span> Supporters</li>
//                           </ul>
//                        </div>
//                        <div className="col-6">
//                        <button className="btn btn_m4 w-100">Share</button>
//                        </div>
//                        <div className="col-6 ps-0"> 
//                         <Link to="exploreview" className="btn btn_m5 w-100 ">Donate</Link> 
//                       </div>
//                      </div>
                      
                      
//                   </div>
//               </div>
//           </SwiperSlide>
//           <SwiperSlide>
//               <div className="featured__card">
//                   <div className="featured__card-head">
//                   <div class="ribbon ribbon-top-right"><span>Tax Benefit</span></div>
//                       <img src={campainsImg1} alt="Swiper Img"/>
//                       <p className="banner_cap">Hunger</p>
//                   </div>
//                   <div className="featured__card-body">
                      
//                       <p className="circle1 text-left">
//                           <span>VS</span>
//                           <i>by voice of slum</i>
//                       </p>

//                       <p className="text-left fweight_600">Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                    
//                      <div className="row">
//                        <div className="col-2 pt-1" >
//                        <CircularProgressbar value={percentage} text={`${percentage}%`}   styles={buildStyles({  backgroundColor: '#F9991E' })} />
//                        </div>
//                        <div className="col-10 ps-0">
//                           <p className="m-0"><span className="fweight_600"><i class="fa fa-inr" aria-hidden="true"></i> 3,50,000</span> raised out of<span className=""> <i class="fa fa-inr" aria-hidden="true"></i> <span className="fweight_600">9,00,000</span></span></p>
//                           <ul className="list_m7"> 
//                               <li><i class="fa fa-clock-o" aria-hidden="true"></i> <span>35</span> Days Left</li>
//                               <li><i class="fa fa-users" aria-hidden="true"></i> <span>3553</span> Supporters</li>
//                           </ul>
//                        </div>
//                        <div className="col-6">
//                        <button className="btn btn_m4 w-100">Share</button>
//                        </div>
//                        <div className="col-6 ps-0">
//                         <Link to="exploreview" className="btn btn_m5 w-100 ">Donate</Link> 
//                       </div>
//                      </div>
                      
                      
//                   </div>
//               </div>
//           </SwiperSlide>
//            <SwiperSlide>
//               <div className="featured__card">
//                   <div className="featured__card-head">
//                   <div class="ribbon ribbon-top-right"><span>Tax Benefit</span></div>
//                       <img src={campainsImg2} alt="Swiper Img"/>
//                       <p className="banner_cap">Physically Disabled</p>
//                   </div>
//                   <div className="featured__card-body">
                      
//                       <p className="circle1 text-left">
//                           <span>VS</span>
//                           <i>by voice of slum</i>
//                       </p>

//                       <p className="text-left fweight_600">Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                    
//                      <div className="row">
//                        <div className="col-2 pt-1" >
//                        <CircularProgressbar value={percentage} text={`${percentage}%`}   styles={buildStyles({  backgroundColor: '#F9991E' })} />
//                        </div>
//                        <div className="col-10 ps-0">
//                           <p className="m-0"><span className="fweight_600"><i class="fa fa-inr" aria-hidden="true"></i> 3,50,000</span> raised out of<span className=""> <i class="fa fa-inr" aria-hidden="true"></i> <span className="fweight_600">9,00,000</span></span></p>
//                           <ul className="list_m7"> 
//                               <li><i class="fa fa-clock-o" aria-hidden="true"></i> <span>35</span> Days Left</li>
//                               <li><i class="fa fa-users" aria-hidden="true"></i> <span>3553</span> Supporters</li>
//                           </ul>
//                        </div>
//                        <div className="col-6">
//                        <button className="btn btn_m4 w-100">Share</button>
//                        </div>
//                        <div className="col-6 ps-0">
//                         <Link to="exploreview" className="btn btn_m5 w-100 ">Donate</Link> 
//                       </div>
//                      </div>
                      
                      
//                   </div>
//               </div>
//           </SwiperSlide>
//            <SwiperSlide>
//               <div className="featured__card">
//                   <div className="featured__card-head">
//                   <div class="ribbon ribbon-top-right"><span>Tax Benefit</span></div>
//                       <img src={campainsImg1} alt="Swiper Img"/>
//                       <p className="banner_cap">Hunger</p>
//                   </div>
//                   <div className="featured__card-body">
                      
//                       <p className="circle1 text-left">
//                           <span>VS</span>
//                           <i>by voice of slum</i>
//                       </p>

//                       <p className="text-left fweight_600">Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                    
//                      <div className="row">
//                        <div className="col-2 pt-1" >
//                        <CircularProgressbar value={percentage} text={`${percentage}%`}   styles={buildStyles({  backgroundColor: '#F9991E' })} />
//                        </div>
//                        <div className="col-10 ps-0">
//                           <p className="m-0"><span className="fweight_600"><i class="fa fa-inr" aria-hidden="true"></i> 3,50,000</span> raised out of<span className=""> <i class="fa fa-inr" aria-hidden="true"></i> <span className="fweight_600">9,00,000</span></span></p>
//                           <ul className="list_m7"> 
//                               <li><i class="fa fa-clock-o" aria-hidden="true"></i> <span>35</span> Days Left</li>
//                               <li><i class="fa fa-users" aria-hidden="true"></i> <span>3553</span> Supporters</li>
//                           </ul>
//                        </div>
//                        <div className="col-6">
//                        <button className="btn btn_m4 w-100">Share</button>
//                        </div>
//                        <div className="col-6 ps-0">
//                         <Link to="exploreview" className="btn btn_m5 w-100 ">Donate</Link> 
//                       </div>
//                      </div>
                      
                      
//                   </div>
//               </div>
//           </SwiperSlide>
//       </Swiper>
//       </div>
//       </div>
//       </div>
//   </div>
    )
};

export default Featured;