import React from "react";
import "../styles/organisationreg.css";

import { useFormik } from "formik";
import * as Yup from "yup";
import Helmet from "../components/Helmet/Helmet";

export const signUpSchema = Yup.object({
    nameOrg: Yup.string().min(2).max(25).required("Please enter your Organisation name"),
    emailOfc: Yup.string().email().required("Please enter your Office email"),
    phoneNumberOfc: Yup.string().min(6).max(12).required("Please enter your Office Phone Number"),
    website: Yup.string().min(2).required("Please enter your Website"),
    NgoNumber: Yup.string().min(6).max(12).required("Please enter your NGO Number"),
    address: Yup.string().min(2).required("Please enter your Address"),
    city: Yup.string().min(2).required("Please enter your City"),
    state: Yup.string().min(2).required("Please enter your State"),
    country: Yup.string().min(2).required("Please enter your Country"),
    pincode: Yup.string().min(6).max(6).required("Please enter your Pin Code"),
    name: Yup.string().min(2).max(25).required("Please enter your name"),
    post: Yup.string().min(2).max(25).required("Please enter your Post"),
    email: Yup.string().email().required("Please enter your email"),
    phoneNumber: Yup.string().min(6).max(12).required("Please enter your Phone Number"),
})

const initialValues = {
    nameOrg:"",
    emailOfc:"",
    phoneNumberOfc:"",
    website:"",
    NgoNumber:"",
    address:"",
    city:"",
    state:"",
    country:"",
    pincode:"",
    name:"",
    post:"",
    email:"",
    phoneNumber:"",
}

const OrganisationReg = () => {


    // form validation

    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues,
        validationSchema: signUpSchema,
        onSubmit: (values, action) => {
          console.log(
            "~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
            values
          );
          action.resetForm();
        }
    })
    return (
        <Helmet title={'Register New Organisation'}>
        <div className="organisation__reg py-5">
            <div className="container">
                <div className="organisation__header text-center mb-5">
                    <h5>Register New Organisation</h5>
                </div>
              
              <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                                <div className="input_m2">
                                    <label>Name of the Organisation <span className="star_imp">*</span></label>
                                    <input value={values.nameOrg} onChange={handleChange} onBlur={handleBlur} type="text" name="nameOrg"/>
                                    { errors.nameOrg && touched.nameOrg ? <p>{errors.nameOrg}</p> : null}
                                </div>
                    </div>
                    <div className="col-md-6">
                           <div className="input_m2">
                                <label>Office Email ID  <span className="star_imp">*</span></label>
                                <input type="text" value={values.emailOfc} onChange={handleChange} onBlur={handleBlur} name="emailOfc"/>
                                { errors.emailOfc && touched.emailOfc ? <p>{errors.emailOfc}</p> : null}
                            </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                            <div className="input_m2">
                                <label>Office Phone Number <span className="star_imp">*</span></label>
                                <input type="number" value={values.phoneNumberOfc} onChange={handleChange} onBlur={handleBlur} name="phoneNumberOfc"/>
                                { errors.phoneNumberOfc && touched.phoneNumberOfc ? <p>{errors.phoneNumberOfc}</p> : null}
                            </div>
                    </div>
                    <div className="col-md-6">
                            <div className="input_m2">
                                <label>Organisation Website (If Any) <span className="star_imp">*</span></label>
                                <input type="text" value={values.website} onChange={handleChange} onBlur={handleBlur} name="website"/>
                                { errors.website && touched.website ? <p>{errors.website}</p> : null}
                            </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                            <div className="input_m2">
                                <label>NGO Registration Number <span className="star_imp">*</span></label>
                                <input type="number" value={values.NgoNumber} onChange={handleChange} onBlur={handleBlur} name="NgoNumber"/>
                                { errors.NgoNumber && touched.NgoNumber ? <p>{errors.NgoNumber}</p> : null}
                            </div>
                    </div>
                </div>

                <div className="sub__header text-center my-3">
                    <h5 className="py-3">Address</h5>
                </div>

                <div className="row">
                    <div className="col-md-6">
                               <div className="input_m2">
                                    <label>Address <span className="star_imp">*</span></label>
                                    <input value={values.address} onChange={handleChange} onBlur={handleBlur} type="text" name="address"/>
                                    { errors.address && touched.address ? <p>{errors.address}</p> : null}
                                </div>
                    </div>
                    <div className="col-md-6">
                           <div className="input_m2">
                                <label>City <span className="star_imp">*</span></label>
                                <input type="text" value={values.city} onChange={handleChange} onBlur={handleBlur} name="city"/>
                                { errors.city && touched.city ? <p>{errors.city}</p> : null}
                            </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                            <div className="input_m2">
                                <label>State <span className="star_imp">*</span></label>
                                <input type="text" value={values.state} onChange={handleChange} onBlur={handleBlur} name="state"/>
                                { errors.state && touched.state ? <p>{errors.state}</p> : null}
                            </div>
                    </div>
                    <div className="col-md-6">
                            <div className="input_m2">
                                <label>Country <span className="star_imp">*</span></label>
                                <input type="text" value={values.country} onChange={handleChange} onBlur={handleBlur} name="country"/>
                                { errors.country && touched.country ? <p>{errors.country}</p> : null}
                            </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                            <div className="input_m2">
                                <label>Pin Code <span className="star_imp">*</span></label>
                                <input type="number" value={values.pincode} onChange={handleChange} onBlur={handleBlur} name="pincode"/>
                                { errors.pincode && touched.pincode ? <p>{errors.pincode}</p> : null}
                            </div>
                    </div>
                </div>

                <div className="sub__header text-center py-3">
                    <h5 className="py-3">Contact Person</h5>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="input_m2">
                                    <label>Name of Person Registering <span className="star_imp">*</span></label>
                                    <input value={values.name} onChange={handleChange} onBlur={handleBlur} type="text" name="name"/>
                                    { errors.name && touched.name ? <p>{errors.name}</p> : null}
                                </div>
                    </div>
                    <div className="col-md-6">
                           <div className="input_m2">
                                <label>post  <span className="star_imp">*</span></label>
                                <input type="text" value={values.post} onChange={handleChange} onBlur={handleBlur} name="post"/>
                                { errors.post && touched.post ? <p>{errors.post}</p> : null}
                            </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                            <div className="input_m2">
                                <label>Phone Number <span className="star_imp">*</span></label>
                                <input type="number" value={values.phoneNumber} onChange={handleChange} onBlur={handleBlur} name="phoneNumber"/>
                                { errors.phoneNumber && touched.phoneNumber ? <p>{errors.phoneNumber}</p> : null}
                            </div>
                    </div>
                    <div className="col-md-6">
                    <div className="input_m2">
                                <label>Email ID  <span className="star_imp">*</span></label>
                                <input type="text" value={values.email} onChange={handleChange} onBlur={handleBlur} name="email"/>
                                { errors.email && touched.email ? <p>{errors.email}</p> : null}
                            </div>
                    </div>

                    <div className="col-sm-12 text-center">
                            <button  type="submit" className="btn_m3">Register</button>
                    </div>
                </div>
                </form>

            </div>
        </div>
        </Helmet>
    )
};

export default OrganisationReg;