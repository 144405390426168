 
import "../styles/about.css";
import React, { useRef, useState } from "react";
//import aboutBanner from "../assets/images/about_banner.png";
import whyImg1 from "../assets/images/frame1.png";
import whyImg2 from "../assets/images/frame2.png";
import whyImg3 from "../assets/images/frame3.png";
import whyImg4 from "../assets/images/frame4.png";
import whyImg5 from "../assets/images/frame5.png";
import whyImg6 from "../assets/images/frame6.png";
import our_mission from "../assets/images/achievements.png";
import our_Vision from "../assets/images/main_object.png";
import aboutBanner1 from "../assets/images/about_banner_new.png";
import what_we_do from "../assets/images/history_of_AIMS.png";
import campaign from "../assets/images/campaign.png";
import mask_group from "../assets/images/mask_group.png";
import our_vision_mission_banner from "../assets/images/our_vision_mission_banner.png";

import CustomizedAccordions from "../components/UI/Accordian";
import Contact from "../components/UI/Contact";
import Helmet from "../components/Helmet/Helmet";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { Link } from "react-router-dom";

import under_set1 from "../assets/images/under_section1.png";
import under_set2 from "../assets/images/under_section2.png";
import under_set3 from "../assets/images/under_section3.png";
import under_set4 from "../assets/images/under_section4.png";
import under_set5 from "../assets/images/under_section5.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// define state with initial value to let the tabs start with that value

// define a onClick function to bind the value on tab click

const About = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [activeTab, setActiveTab] = React.useState(0);
  const onTabClick = (e, index) => {
    console.log(e);
    setActiveTab(index);
  };
  return (
    <Helmet title={"About Us"}>
      <div className="about">
         {/* Section start here */}
        <div className="sub_level_banner">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-4  offset-md-1">
                <div className="about__banner-text">
                  <h1 className="block__title">About Us</h1>
                  <h5>
                    women and child <br></br> welfare / freedom / rights
                  </h5>
                </div>
              </div>
              <div className="col-md-6 offset-md-1 about__banner-img pr-md-0">
                <div className=" sub_pages_banner">
                  <img
                    src={aboutBanner1}
                    alt="About Banner"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
         {/* Section End here */}
        {/* Section start here */}
        <div className="bg_m4 pt-2 pb-2 pt-md-5 pb-md-5">
          <div className="container">
            <div className="row ">
              {/* <div className="col-sm-6 ">
                <img
                  src={our_vision_mission_banner}
                  className="img-fluid rounded-3"
                  alt=""
                />
              </div> */}
              <div className="col-sm-12 align-self-center">
                <h1 class="block__title fw-bold">About AIMS</h1>
                <p className="font_18">
                As stated we are a voluntary organization, registered as a society granted registration number 1808/2005 and formed in the year 2005. Our main aim is to create awareness of Human rights for the welfare of women and children is the society’s main objective. Also to propagate the contents of the constitution of India, related to the right, of every citizen of our country.
                </p>
                <div className="bg_m9 p-3">
                <p > We have a team of advisor’s of the legal fraternity, who elaborate certain contents of the Constitution required to let the Women & Children understand what are their legal rights and how to defend themselves of these atrocities and abuse at the hands of some social evil elements of our society. We are also trying to uplift the living standards of the down trodden, people by letting them understand, through elaboration  of the following i.e., socially, economically & culturally. The Govt. is also involved in various programs through various bodies, one such being an autonomous body by the name NHRC (National Human Rights Commission), which was formed in the year 1993, and National women commission. These commissions explains and elaborates about human rights, which is guaranteed to every Human being of our country. Its main aim is to prevent people from violating of human rights and Atrocities against women and children in various parts of our country. Their areas of operation are urban, semi urban, rural and remote areas of Districts, towns, and villages. They are dealing with issues like, child marriages, dowry, human trafficking, sexual harassment domestic violence exploitation of women and children. Our organization AIMS Society is coordinating through various modes of projection and following guidelines of our constitution and commission guidelines. Our organization is coordinating and publishing First regional human rights magazine named <b>UDBHAVA</b> which is publishing literature and exposing violations against women and children within these various places in the state of Telangana and Andhra Pradesh as above mentioned.</p>
                <p>We have also taken up the subject of ragging and violations against women and children in schools and colleges and conducted several exhibitions regarding rights’ of women and children for awareness in various places of Hyderabad, Rangareddy Districts in Telangana and East Godavari dist., of Andhra Pradesh.

</p>
<p>Our society obtained Government of India permissions to generate funds in India and aboard by 12A, 80G, and FRCA for noble cause as well as for doing social service through society.

</p>
<p>
This is a herculean task our organization has undertaken which requires lot of time, commitment devotion, selfless service, and sacrifice, with involvement of precious time and funds to make this program of awareness an achievement a grand success for time to come.
</p>
</div>
              
                {/* <div className="text-end">
                <Button  className="btn btn_m5 btn_m5_small " onClick={handleShow}>
        Read more
      </Button></div> */}
              </div>
            </div>
          </div>
        </div>
        {/* Section End here */}
        <div className=" bg_gradent1 d-none">
          <div className="container py-5">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="about__banner-text">
                  <h1 className="block__title">About AIMS</h1>
                  <p className="my-4">
                    {" "}
                    As stated we are a voluntary organization, registered as a
                    society granted registration number 1808/2005 and formed in
                    the year 2005. Our main aim is to create awareness of Human
                    Rights for the welfare of women and children is the
                    society’s main objective. Also to propagate the contents of
                    the Constitution of India, related to the right, of every
                    citizen of our country. We have a team of advisors of the
                    legal fraternity, who elaborate certain contents of the
                    Constitution required to let the women and Children
                    understand what are their legal rights and how to defend
                    themselves of these atrocities and abuse at the hands of
                    some social evil elements of our society. We are also trying
                    to uplift the living standards of the down trodden people by
                    letting them understand, through elaboration of the
                    following i.e., socially, economically and culturally.
                  </p>
                  <p>
                    The Govt. is also involved in various programs through
                    various bodies, one such being an autonomous body by the
                    name NHRC (National Human Rights Commission), which was
                    formed in the year 1993. NHRC which not only explains and
                    elaborates about Human Rights, which is guaranteed to every
                    human being of our country. Its main aim is to prevent
                    people from violation of Human Rights and for various
                    Atrocities committed against women and children in various
                    parts of our country. Their areas of operation are urban,
                    semi urban, rural and remote areas of Districts, Towns, and
                    Villages. They are dealing with issues like child marriages,
                    dowry, human trafficking, sexual harassment, domestic
                    violence, exploitation of women and children.
                  </p>
                
                  {/*    <button className="btn btn_m3 me-2 mb-2">Contribute Now</button>
                                <button className="btn btn_m5 mb-2">Start a Campaign</button>
                                <p class="mt-2 mb-0">Are you an NGO / Organisation?  <Link to="" class="link_m3">Register</Link> </p> */}
                </div>
              </div>
              <div className="col-md-5 offset-sm-1">
                <div className="about__banner-img">
                  <img
                    src={aboutBanner1}
                    alt="About Banner"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="why__Usaver py-5 d-none">
          <div className="basic__header text-center mb-2">
            <h1 className="block__title">
              Why <span className="highlight">U-saver?</span>
            </h1>
          </div>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-4 text-center">
                <img src={whyImg1} alt="Why U-Saver" />
              </div>
              <div className="col-md-4 text-center">
                <img src={whyImg2} alt="Why U-Saver" />
              </div>
              <div className="col-md-4 text-center">
                <img src={whyImg3} alt="Why U-Saver" />
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-md-3 text-left">
                <img src={whyImg4} alt="Why U-Saver" />
              </div>
              <div className="col-md-6 text-center">
                <p className="text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  sodales aliquam leo eget finibus. Aliquam erat volutpat. Duis
                  sit amet nibh id sem posuere varius sit amet quis orci. Morbi
                  sed sem justo. Praesent nisl sem, vulputate nec sem sed,
                  dignissim ornare dolor.
                </p>
                <p className="text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  sodales aliquam leo eget finibus. Aliquam erat volutpat. Duis
                  sit amet nibh id sem posuere varius sit amet quis orci. Morbi
                  sed sem justo. Praesent nisl sem, vulputate nec sem sed,
                  dignissim ornare dolor.
                </p>
              </div>
              <div className="col-md-3 text-right rightImg">
                <img src={whyImg5} alt="Why U-Saver" />
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-md-4 text-center">
                <img src={whyImg6} alt="Why U-Saver" />
              </div>
              <div className="col-md-4 text-center">
                <button className="btn btn_m3 px-5">Donate</button>
              </div>
              <div className="col-md-4 text-center">
                {/* <img src={whyImg7} alt="Why U-Saver"/> */}
              </div>
            </div>
          </div>
        </div>

        <div className="basic__Faqs py-5  d-none">
          <div className="container">
            <div className="basic__header text-center mb-5">
              <h1 className="block__title">
                Basic <span className="highlight">FAQs</span>
              </h1>
            </div>
            <div className="basic__accordian">
              <CustomizedAccordions />
            </div>
          </div>
        </div>

        <div className="contact__cmpnt  d-none">
          <Contact />
        </div>

        {/* New Theme */}

        <div className="container">
          <div className="row mt-5  ">
            <div className="col-md-12 ">
                <h2 className="block__title mb-0">What to be done?</h2>
                <h5 className="mb-4">A Pictorial way of Awareness is the only way</h5>
                <p>Everyone has the right, individually and in association with others, to promote and to strive for the protection and realization of human rights of women and children. But there is a gap between the human rights norms and enforcement of those norms. This provides space for NGOs such as AIMS Human Rights Society Surveys to operate for the protection of human rights. </p>
                <p>We are striving hard to bring awareness on socio-humanitarian issues such as domestic violence, child abuse, alcoholism, women empowerment, human trafficking etc. Our aim is to become advocates in the above areas through awareness campaigns, seminars, and processions.</p>
                <p>We align all our programs with this motto to achieve 3Es, edify, equip, and enable. What else can help us to achieve them other than pictorial depiction. It is our sincerity, commitment, passion and compassion makes it a better place to live for women and children.</p>
              </div>
              </div>
              </div>

        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-md-4 text-center  mb-2 mb-md-5 ">
              <div className="card_m6">
                <img src={under_set1} alt=" " className="img-fluid rounded-3" />
                <p className="font_18 fweight_600 c2 mt-3">
                  Protection of Women under<br></br> Domestic Violence Act 2005
                </p>
              </div>
            </div>

            <div className="col-md-4 text-center  mb-2 mb-md-5 ">
              <div className="card_m6">
                <img src={under_set2} alt=" " className="img-fluid rounded-3" />
                <p className="font_18 fweight_600 c2 mt-3">
                  Nirbhaya Act – the new Criminal<br></br> Law Amendment Act –
                  2013
                </p>
              </div>
            </div>

            <div className="col-md-4 text-center  mb-2 mb-md-5 ">
              <div className="card_m6">
                <img src={under_set3} alt=" " className="img-fluid rounded-3" />
                <p className="font_18 fweight_600 c2 mt-3">
                  Protection of Children under<br></br> Sexual Violence Act –
                  2012
                </p>
              </div>
            </div>

            <div className="col-md-4 text-center  mb-2 mb-md-5 ">
              <div className="card_m6">
                <img src={under_set4} alt=" " className="img-fluid rounded-3" />
                <p className="font_18 fweight_600 c2 mt-3">
                  Prohibition of Child<br></br> Marriage Act – 2006
                </p>
              </div>
            </div>

            <div className="col-md-4 text-center  mb-2 mb-md-5 ">
              <div className="card_m6">
                <img src={under_set5} alt=" " className="img-fluid rounded-3" />
                <p className="font_18 fweight_600 c2 mt-3">
                  Protection of Human Rights<br></br> under our Constitution
                </p>
              </div>
            </div>
            <div className="col-sm-6 text-center"></div>
          </div>
        </div>

        <div className="bg_m4">
          <div className="container">
            <div className="row py-5">
              <div className="col-sm-6 text-left">
                <div className="basic__header mb-2 pt-5">
                  <h1 className="block__title">History of AIMS</h1>
                  <div className="list_m9"></div>
                </div>
                <p>
                  AIMS Human Rights Society Survey’s is a voluntary organisation
                  registered for awareness of Human Rights for Women and Child
                  welfare registered as a Society – 1808/2005 and existing since
                  2005.
                </p>
                <p>
                  AIMS Society has emerged to enlighten the citizens about their
                  basic Human Rights which are envisaged to every citizen by the
                  constitution of India. The largest democracy of the world is
                  the Indian Nation and fundamental rights to every citizen and
                  more or less a freedom of life.
                </p>
                <p>
                  {" "}
                  The whole concept of Constitutional Rights that too which are
                  envisages to women and children, now being deprived by some of
                  the sections of the society which is causing injury on the
                  body and mind and to their free movement. The AIM’s” and its
                  objective of the society is to take the concept, spirit voice
                  of Constitution of India to uplift their living standards
                  socially, economically, culturally to the people those who are
                  neglected deprived and suffering at the hands of social evils.{" "}
                </p>
              </div>

              <div className="col-sm-6 text-center">
                <img
                  src={what_we_do}
                  alt="Why U-Saver "
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="shape_m2 d-none">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <div className="basic__header text-center mb-5 pt-5">
                  <h1 className="block__title">
                    you can <span className="highlight">campaign</span> for
                  </h1>
                </div>
              </div>
              <div className="tabs_m4">
                <Tabs activeTab={activeTab} onTabClick={onTabClick}>
                  <Tab key="Tab1">Poverty</Tab>
                  <Tab key="Tab2">Health</Tab>
                  <Tab key="Tab3">Children</Tab>
                  <Tab key="Tab4">Hunger</Tab>
                  <Tab key="Tab5">Education</Tab>
                  <Tab key="Tab6">Old Age</Tab>
                  <Tab key="Tab7">Environment</Tab>
                </Tabs>
                <TabScreen
                  key="Tab1"
                  activeTab={activeTab}
                  index={0}
                  className="some-animation-class"
                >
                  <div className="row">
                    <div className="col-sm-8">
                      <h5>Poverty</h5>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                      <p className="mt-5">
                        It has survived not only five centuries, but also the
                        leap into electronic typesetting, remaining essentially
                        unchanged. It was popularised in the 1960s with the
                        release of Letraset sheets containing Lorem Ipsum
                        passages, and more recently with desktop publishing
                        software like Aldus PageMaker including versions of
                        Lorem Ipsum.
                      </p>

                      <div class="pt-4 pb-4">
                        <button
                          type="button"
                          class="btn btn_m5  btn btn-outline-success"
                        >
                          <a href="/signin"> Start a Campaign</a>
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <img
                        src={campaign}
                        alt="Why U-Saver "
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </TabScreen>
                <TabScreen
                  key="Tab2"
                  activeTab={activeTab}
                  index={1}
                  className="some-animation-class"
                >
                  2
                </TabScreen>
                <TabScreen
                  key="Tab3"
                  activeTab={activeTab}
                  index={2}
                  className="some-animation-class"
                >
                  3
                </TabScreen>
                <TabScreen
                  key="Tab4"
                  activeTab={activeTab}
                  index={3}
                  className="some-animation-class"
                >
                  4
                </TabScreen>
                <TabScreen
                  key="Tab5"
                  activeTab={activeTab}
                  index={4}
                  className="some-animation-class"
                >
                  5
                </TabScreen>
                <TabScreen
                  key="Tab6"
                  activeTab={activeTab}
                  index={5}
                  className="some-animation-class"
                >
                  6
                </TabScreen>
                <TabScreen
                  key="Tab7"
                  activeTab={activeTab}
                  index={6}
                  className="some-animation-class"
                >
                  7
                </TabScreen>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row   mb-5">
            <div className="col-sm-5">
              <img src={our_Vision} className="img-fluid" alt="" />
            </div>
            <div className="col-sm-7 align-self-center">
              <h1 class="block__title">Main objectives and its constitution</h1>
              <h4>
                AIMS Human Rights Society surveys is a voluntary organisation in
                Andhra Pradesh.
              </h4>
              <p>
                Our mission is to spreading human rights awareness among the
                common people in their language and culture is vital for
                creating a culture of human rights unless people are aware about
                their human rights.{" "}
              </p>
              <p>
                They cannot protect their human rights or seeks redressed of its
                violations the language of human rights literature and paintings
                of violations against women and children also gives good
                attention among rural and urban people thus should be simple
                enough to understood like rural and urban women and children.
                Our society wants to reach grass roots of villages, mandals and
                districts to express and explain fundamental rights and new acts
                like domestic violence act of them under parameters of our
                Indian democratic constitution.
              </p>
            </div>
          </div>
        </div>
        <div className="bg_m4 py-5">
          <div className="container">
            <div className="row  ">
              <div className="col-sm-6 align-self-center">
                <h1 class="block__title">
                  Our <span class="highlight">Main achievements</span>
                </h1>
                <h4>
                  Our society established and registered in 2005 in Rangareddy
                  Dt., Andhra Pradesh.{" "}
                </h4>
                <p>
                  We can proudly say in India its first society started very
                  innovative way by exhibiting visual paintings of violations
                  against women and children, statistics and details of Domestic
                  Violence Act as well as Child Rights.{" "}
                </p>
                <p>
                  Apart from this our society published books on Rights of Women
                  and Children as well as Ragging under the parameters of our
                  Constitution of India and Laws of India. Last five years we
                  have conducted six exhibitions in schools, colleges, and
                  auditoriums as well as A.P. Women Commission Office. Our
                  organisation activities appreciated by Na- tional Human Rights
                  Commission and A.P. Women Commission as well as praised by
                  Print Media as well as Electronic Media.
                </p>
              </div>
              <div className="col-sm-6 mb-5">
                <img src={our_mission} className="img-fluid" alt="" />
              </div>
              <div className="col-md-12">
                <p>
                  Significantly all visitors of this exhibitions responded
                  magnificently for the awakening of their rights. In these
                  visitors we proudly say most of them are students,
                  academicians and employees. We can also proudly say we are
                  only the society in India associated with first regional
                  magazine “UDBHAVA”, which brings rights/awareness
                  thoughts/violations against women and children.
                </p>
              </div>
            </div>
          </div>
        </div>
      
        {/* <Modal show={show} onHide={handleClose}    size="lg"
       >
        <Modal.Header closeButton>
          <Modal.Title>About AIMS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <p> We have also taken up the subject of ragging and violations against women and children in schools and colleges and conducted several exhibitions regarding rights’ of women and children for awareness in various places of Hyderabad, Rangareddy Districts in Telangana and East Godavari dist., of Andhra Pradesh.</p>
          <p>
          Our society obtained Government of India permissions to generate funds in India and aboard by 12A, 80G, and FRCA for noble cause as well as for doing social service through society.

          </p>
          <p>This is a herculean task our organization has undertaken which requires lot of time, commitment devotion, selfless service, and sacrifice, with involvement of precious time and funds to make this program of awareness an achievement a grand success for time to come.
</p>
 
        </Modal.Body>
      
      </Modal> */}
        {/* New Theme End*/}
      </div>
    </Helmet>
  );
};

export default About;
