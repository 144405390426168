import React from "react";
import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images4, images5, images6 } from "./paintimages";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import event23 from "../assets/images/amis/events/event1/event23.JPG";
const Paintsenglish = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  //  GALLERY 4 SET START HERE
  const [windowWidth4, setWindowWidth4] = useState(window.innerWidth);
  const [index4, setIndex4] = useState(-1);
  const currentImage4 = images4[index4];
  const nextIndex4 = (index4 + 1) % images4.length;
  const nextImage4 = images4[nextIndex4] || currentImage4;
  const prevIndex4 = (index4 + images4.length - 1) % images4.length;
  const prevImage4 = images4[prevIndex4] || currentImage4;
  const handleClick4 = (index4, item) => {
    setIndex4(index4);
    setTimeout(() => {
      setWindowWidth4((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose4 = () => setIndex4(-1);
  const handleMovePrev4 = () => setIndex4(prevIndex4);
  const handleMoveNext4 = () => setIndex4(nextIndex4);
  //  GALLERY 4 SET END HERE

  //  GALLERY 4 SET START HERE
  const [windowWidth5, setWindowWidth5] = useState(window.innerWidth);
  const [index5, setIndex5] = useState(-1);
  const currentImage5 = images5[index5];
  const nextIndex5 = (index5 + 1) % images5.length;
  const nextImage5 = images5[nextIndex5] || currentImage5;
  const prevIndex5 = (index5 + images5.length - 1) % images5.length;
  const prevImage5 = images5[prevIndex5] || currentImage5;
  const handleClick5 = (index5, item) => {
    setIndex5(index5);
    setTimeout(() => {
      setWindowWidth5((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose5 = () => setIndex5(-1);
  const handleMovePrev5 = () => setIndex5(prevIndex5);
  const handleMoveNext5 = () => setIndex5(nextIndex5);
  //  GALLERY 4 SET END HERE

  //  GALLERY 4 SET START HERE
  const [windowWidth6, setWindowWidth6] = useState(window.innerWidth);
  const [index6, setIndex6] = useState(-1);
  const currentImage6 = images6[index6];
  const nextIndex6 = (index6 + 1) % images6.length;
  const nextImage6 = images6[nextIndex6] || currentImage6;
  const prevIndex6 = (index6 + images6.length - 1) % images6.length;
  const prevImage6 = images6[prevIndex6] || currentImage6;
  const handleClick6 = (index6, item) => {
    setIndex6(index6);
    setTimeout(() => {
      setWindowWidth6((prevWidth) => prevWidth + 1);
    }, 600);
  };
  const handleClose6 = () => setIndex6(-1);
  const handleMovePrev6 = () => setIndex6(prevIndex6);
  const handleMoveNext6 = () => setIndex6(nextIndex6);
  //  GALLERY 4 SET END HERE

  return (
    <div className="Paints">
       <div className="sub_level_banner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-4  offset-md-1">
              <div className="about__banner-text">
                <h1 className="block__title">Art Gallery</h1>
                <h5>
                AMIS HUMAN RIGHTS SOCIETY Art GALLERY DURING THE YEAR 2009 TO 2024
                </h5>
              </div>
            </div>
            <div className="col-md-6 offset-md-1 about__banner-img pr-md-0">
              <div className=" sub_pages_banner">
                <img
                  src={event23}
                  alt="About Banner"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg_m4">
        <div className="container pt-5">
          {/* <div className="row pt-2 mb-0 pb-4">
            <div className="col-md-8 offset-md-2 text-center block__header mb-0">
              <h1 className="block__title mb-1 position-relative">
                OUR PAINTS
              </h1>
              <p className="block__paragraph mb-0 color_1">
                Aims Human Rights Society Paints during the year 2009 to 2024
              </p>
            </div>
          </div> */}

          <div className="tabs_m4">
            <Tabs activeTab={activeTab} onTabClick={onTabClick}>
              <Tab key="Tab1">Childrens</Tab>
              <Tab key="Tab2">Womens</Tab>
              <Tab key="Tab3">Society</Tab>
            </Tabs>

            <TabScreen
              key="Tab1"
              activeTab={activeTab}
              index={0}
              className="some-animation-class"
            >
              <Gallery
                images={images4}
                onClick={handleClick4}
                enableImageSelection={false}
              />
              {!!currentImage4 && (
                <Lightbox
                  mainSrc={currentImage4.original}
                  imageTitle={currentImage4.caption}
                  mainSrcThumbnail={currentImage4.src}
                  nextSrc={nextImage4.original}
                  nextSrcThumbnail={nextImage4.src}
                  prevSrc={prevImage4.original}
                  prevSrcThumbnail={prevImage4.src}
                  onCloseRequest={handleClose4}
                  onMovePrevRequest={handleMovePrev4}
                  onMoveNextRequest={handleMoveNext4}
                />
              )}
            </TabScreen>
            <TabScreen
              key="Tab2"
              activeTab={activeTab}
              index={1}
              className="some-animation-class"
            >
              <Gallery
                images={images5}
                onClick={handleClick5}
                enableImageSelection={false}
              />
              {!!currentImage5 && (
                <Lightbox
                  mainSrc={currentImage5.original}
                  imageTitle={currentImage5.caption}
                  mainSrcThumbnail={currentImage5.src}
                  nextSrc={nextImage5.original}
                  nextSrcThumbnail={nextImage5.src}
                  prevSrc={prevImage5.original}
                  prevSrcThumbnail={prevImage5.src}
                  onCloseRequest={handleClose5}
                  onMovePrevRequest={handleMovePrev5}
                  onMoveNextRequest={handleMoveNext5}
                />
              )}
            </TabScreen>

            <TabScreen
              key="Tab3"
              activeTab={activeTab}
              index={2}
              className="some-animation-class"
            >
              <Gallery
                images={images6}
                onClick={handleClick6}
                enableImageSelection={false}
              />
              {!!currentImage6 && (
                <Lightbox
                  mainSrc={currentImage6.original}
                  imageTitle={currentImage6.caption}
                  mainSrcThumbnail={currentImage6.src}
                  nextSrc={nextImage6.original}
                  nextSrcThumbnail={nextImage6.src}
                  prevSrc={prevImage6.original}
                  prevSrcThumbnail={prevImage6.src}
                  onCloseRequest={handleClose6}
                  onMovePrevRequest={handleMovePrev6}
                  onMoveNextRequest={handleMoveNext6}
                />
              )}
            </TabScreen>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paintsenglish;
