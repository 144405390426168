import React from "react";
import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images, CustomImage,images1,images3} from "./paintimages";
 
const Awards = () => {
    const [activeTab, setActiveTab] = React.useState(0);
    const onTabClick = (e, index) => {
       setActiveTab(index);
     };

     

  //  GALLERY 3 SET START HERE
  const [windowWidth3, setWindowWidth3] = useState(window.innerWidth);
  const [index3, setIndex3] = useState(-1);
  const currentImage3 = images3[index3];
  const nextIndex3 = (index3 + 1) % images3.length;
  const nextImage3 = images3[nextIndex3] || currentImage3;
  const prevIndex3 = (index3 + images3.length - 1) % images3.length;
  const prevImage3 = images3[prevIndex3] || currentImage3;
 const handleClick3 = (index3, item) => {
  setIndex3(index3);
  setTimeout(() => {  setWindowWidth3(prevWidth => prevWidth + 1);}, 500); 
 };
   const handleClose3 = () => setIndex3(-1);
  const handleMovePrev3 = () => setIndex3(prevIndex3);
  const handleMoveNext3 = () => setIndex3(nextIndex3);
//  GALLERY 1 SET END HERE


    return (
        <div className="Awards">
             <div className="bg_m4">
                <div className="container pt-5">

                <div className="row pt-2 mb-0 pb-4">
                    <div className="col-md-8 offset-md-2 text-center block__header mb-0">
                        <h1 className="block__title mb-1 position-relative">OUR APPRECIATIONS</h1>
                        <p className="block__paragraph mb-0 color_1">Aims Human  Rights Society Appreciations during the year 2009 to 2024</p>
                    </div>
                </div>

             

                     <div className="text-center">
             <Gallery
        images={images3}
        onClick={handleClick3}
        enableImageSelection={false}
      />
      {!!currentImage3 && (
        <Lightbox
        mainSrc={currentImage3.original}
        imageTitle={currentImage3.caption}
        mainSrcThumbnail={currentImage3.src}
        nextSrc={nextImage3.original}
        nextSrcThumbnail={nextImage3.src}
        prevSrc={prevImage3.original}
        prevSrcThumbnail={prevImage3.src}
        onCloseRequest={handleClose3}
        onMovePrevRequest={handleMovePrev3}
        onMoveNextRequest={handleMoveNext3}
        />
        )}
                </div>
                </div>
                </div>
        </div>
    )
};

export default Awards;
 