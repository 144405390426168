import React from "react";
import "../../styles/contact.css";

import { useFormik } from "formik";
import * as Yup from "yup";

import contactImg from "../../assets/images/contactImg.png";


export const signUpSchema = Yup.object({
    name: Yup.string().min(2).max(25).required("Please enter your name"),
    phoneNumber: Yup.string().min(6).max(12).required("Please enter your Phone Number"),
    email: Yup.string().email().required("Please enter your email"),
    comment: Yup.string().min(10).max(150).required("Please enter your Comment"),
})

const initialValues = {
    name:"",
    phoneNumber:"",
    email:"",
    comment:"",
}

const Contact = () => {

    // form validation
    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues,
        validationSchema: signUpSchema,
        onSubmit: (values, action) => {
          console.log(
            "~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
            values
          );
          action.resetForm();
        }
    });

    return (
        <div className="contact pb-5">
           <div className="container">
                <div className="contact__header text-center py-5 ">
                    <h1 className="block__title">Contact <span className="highlight">Details</span></h1>
                </div>
                <div className="contact__body">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-6 text-center">
                            <img src={contactImg} alt="contact img" className="w-100"/>
                            <h3 className="pt-5">TollPlus New Facility</h3>
                            <p>Vittal Rao Nagar, Madhapur, Gafoornagar,Telangana 500081</p>
                        </div>
                        <div className="col-md-6">
                            <form onSubmit={handleSubmit}>
                            <div className="input_m2">
                                <label>Full Name <span className="star_imp">*</span></label>
                                <input value={values.name} onChange={handleChange} onBlur={handleBlur} type="text" name="name"/>
                                { errors.name && touched.name ? <p>{errors.name}</p> : null}
                            </div>

                            <div className="input_m2">
                                <label>Email ID  <span className="star_imp">*</span></label>
                                <input type="text" value={values.email} onChange={handleChange} onBlur={handleBlur} name="email"/>
                                { errors.email && touched.email ? <p>{errors.email}</p> : null}
                            </div>

                            <div className="input_m2">
                                <label>Phone Number <span className="star_imp">*</span></label>
                                <input type="number" value={values.phoneNumber} onChange={handleChange} onBlur={handleBlur} name="phoneNumber"/>
                                { errors.phoneNumber && touched.phoneNumber ? <p>{errors.phoneNumber}</p> : null}
                            </div>

                            <div className="input_m2">
                                <label>Write Your Comment </label>
                                <textarea className="w-100" value={values.comment} onChange={handleChange} onBlur={handleBlur} name="comment" rows="4"></textarea>
                                { errors.comment && touched.comment ? <p>{errors.comment}</p> : null}
                            </div>

                            <button  type="submit" className="btn_m3">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default Contact;