//import React, { useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";

import child1 from "../../assets/images/amis/paints/childrens/child-(1).jpg";
import child2 from "../../assets/images/amis/paints/childrens/child-(2).JPG";
import child3 from "../../assets/images/amis/paints/childrens/child-(3).jpg";
import child4 from "../../assets/images/amis/paints/childrens/child-(4).jpg";
import child5 from "../../assets/images/amis/paints/childrens/child-(5).jpg";

import womens1 from "../../assets/images/amis/paints/womens/women-(1).jpg";
import womens2 from "../../assets/images/amis/paints/womens/women-(2).jpg";
import womens3 from "../../assets/images/amis/paints/womens/women-(3).jpg";
import womens4 from "../../assets/images/amis/paints/womens/women-(4).jpg";
import womens5 from "../../assets/images/amis/paints/womens/women-(5).jpg";

import society1 from "../../assets/images/amis/paints/society/society-(1).jpg";
import society2 from "../../assets/images/amis/paints/society/society-(2).jpg";
import society3 from "../../assets/images/amis/paints/society/society-(3).jpg";
import society4 from "../../assets/images/amis/paints/society/society-(4).jpg";
import society5 from "../../assets/images/amis/paints/society/society-(5).jpg";

import banner_1 from "../../assets/images/banner_1.png";
import banner_2 from "../../assets/images/Banner_2.png";
import banner_3 from "../../assets/images/Banner_3.png";
import vision_mission_banner from "../../assets/images/vision_mission_banner.png";

import home_banner1 from "../../assets/images/amis/home_banner/1.jpg";
import home_banner2 from "../../assets/images/amis/home_banner/2.jpg";
import home_banner3 from "../../assets/images/amis/home_banner/3.jpg";
import home_banner4 from "../../assets/images/amis/home_banner/4.jpg";
import home_banner4_1 from "../../assets/images/amis/home_banner/4-1.jpg";
import home_banner4_2 from "../../assets/images/amis/home_banner/4-2.jpg";
import home_banner5 from "../../assets/images/amis/home_banner/5.jpg";
import home_banner5_ from "../../assets/images/amis/home_banner/5_.jpg";
import home_banner6 from "../../assets/images/amis/home_banner/6.jpg";
import home_banner8 from "../../assets/images/amis/home_banner/8.jpg";
import home_banner9 from "../../assets/images/amis/home_banner/9.jpg";
import home_banner10 from "../../assets/images/amis/home_banner/10.jpg";
import home_banner11 from "../../assets/images/amis/home_banner/11.jpg";
import home_banner12 from "../../assets/images/amis/home_banner/12.jpg";
import home_banner13 from "../../assets/images/amis/home_banner/13.jpg";
import home_banner14 from "../../assets/images/amis/home_banner/14.jpg";
import home_banner15 from "../../assets/images/amis/home_banner/15.jpg";
import home_banner16 from "../../assets/images/amis/home_banner/16.jpg";
import home_banner17 from "../../assets/images/amis/home_banner/17.jpg";
import home_banner18_1 from "../../assets/images/amis/home_banner/18(1).JPG";
import home_banner18 from "../../assets/images/amis/home_banner/18.jpg";
import home_banner19 from "../../assets/images/amis/home_banner/19.jpg";

import home_banner20_1 from "../../assets/images/amis/home_banner/20(1).jpg";
import home_banner20 from "../../assets/images/amis/home_banner/20.JPG";
import home_banner21 from "../../assets/images/amis/home_banner/21.jpg";
import home_banner22 from "../../assets/images/amis/home_banner/22.jpg";
import home_banner24 from "../../assets/images/amis/home_banner/24.jpg";
import home_banner25 from "../../assets/images/amis/home_banner/25.jpg";
import home_banner26 from "../../assets/images/amis/home_banner/26.jpg";
import home_banner27 from "../../assets/images/amis/home_banner/27.jpg";
import home_banner28 from "../../assets/images/amis/home_banner/28.JPG";
import home_banner29 from "../../assets/images/amis/home_banner/29.JPG";

import home_banner30 from "../../assets/images/amis/home_banner/30.JPG";
import home_banner31 from "../../assets/images/amis/home_banner/31.jpg";
import home_banner32 from "../../assets/images/amis/home_banner/32.jpg";
import home_banner33 from "../../assets/images/amis/home_banner/33.jpg";
import home_banner34 from "../../assets/images/amis/home_banner/34.jpg";
import home_banner35 from "../../assets/images/amis/home_banner/35.jpg";
import home_banner36 from "../../assets/images/amis/home_banner/36.jpg";
import home_banner37 from "../../assets/images/amis/home_banner/37.jpg";
import home_banner38 from "../../assets/images/amis/home_banner/38.jpg";


import english_paint1 from "../../assets/images/amis/english/1.png";
import english_paint2 from "../../assets/images/amis/english/2.png";
import english_paint3 from "../../assets/images/amis/english/3.png";
import english_paint4 from "../../assets/images/amis/english/4.png";
import english_paint5 from "../../assets/images/amis/english/5.png";
import english_paint6 from "../../assets/images/amis/english/6.png";
import english_paint7 from "../../assets/images/amis/english/7.png";
import english_paint8 from "../../assets/images/amis/english/8.png";
import english_paint9 from "../../assets/images/amis/english/9.png";
import english_paint10 from "../../assets/images/amis/english/10.png";
import english_paint11 from "../../assets/images/amis/english/11.png";
import english_paint12 from "../../assets/images/amis/english/12.png";
import english_paint13 from "../../assets/images/amis/english/13.png";
import english_paint14 from "../../assets/images/amis/english/14.png";
import english_paint15 from "../../assets/images/amis/english/15.png";
import english_paint16 from "../../assets/images/amis/english/16.png";
import english_paint17 from "../../assets/images/amis/english/17.png";
import english_paint18 from "../../assets/images/amis/english/18.png";
import english_paint19 from "../../assets/images/amis/english/19.png";
import english_paint20 from "../../assets/images/amis/english/20.png";
import english_paint21 from "../../assets/images/amis/english/21.png";
import english_paint22 from "../../assets/images/amis/english/22.png";
import english_paint23 from "../../assets/images/amis/english/23.png";
import english_paint24 from "../../assets/images/amis/english/24.png";
import english_paint25 from "../../assets/images/amis/english/25.png";
import english_paint26 from "../../assets/images/amis/english/26.png";
import english_paint27 from "../../assets/images/amis/english/27.png";
import english_paint28 from "../../assets/images/amis/english/28.png";
import english_paint29 from "../../assets/images/amis/english/29.png";
import english_paint30 from "../../assets/images/amis/english/30.png";
import english_paint31 from "../../assets/images/amis/english/31.png";
import english_paint32 from "../../assets/images/amis/english/32.png";
import english_paint33 from "../../assets/images/amis/english/33.png";
import english_paint34 from "../../assets/images/amis/english/34.png";
import english_paint35 from "../../assets/images/amis/english/35.png";
import english_paint36 from "../../assets/images/amis/english/36.png";
import english_paint37 from "../../assets/images/amis/english/37.png";
import english_paint38 from "../../assets/images/amis/english/38.png";
import english_paint39 from "../../assets/images/amis/english/39.png";
import english_paint40 from "../../assets/images/amis/english/40.png";
import english_paint41 from "../../assets/images/amis/english/41.png";
import english_paint42 from "../../assets/images/amis/english/42.png";
 

import "../../styles/hero.css";

import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";

import React, { useRef, useState } from "react";
// Import Swiper React components

const Hero = () => {
  const onAutoplayTimeLeft = (s, time, progress) => {};

  return (
    <div className="">
      <div className="banner_m1">
        <div className="plr-100 ">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <h1 className="text-uppercase">AIMS explains awareness of their rights</h1>
              <h2>Laws safegaurd
                proceedings of government and courts under our constitution
                against violations for women and children.
                {/* AIMS Explain Women and Children Their Fundamental Rights and how
                to safe guard themselves */}
              </h2>
            </div>
            <div className="col-lg-6">
              <Carousel>
                <Carousel.Item>
                  <img src={home_banner1} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner2} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner3} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner4} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner4_1} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner4_2} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner5} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner5_} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner6} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner8} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner9} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner10} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner11} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner12} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner13} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner14} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner15} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner16} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner17} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner18_1} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner18} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner19} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner20_1} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner20} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner21} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner22} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner24} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner25} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner26} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner27} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner28} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner29} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner30} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner31} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner32} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner33} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner34} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner35} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner36} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner37} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={home_banner38} alt="" />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div class="marquee_m1">
        <span>News</span>
        <marquee onmouseout="this.start();" onmouseover="this.stop();">
          <ul>
            <li>
            NHRC’s online human rights photography competition-2024 (Posted on : 23-08-2024)
            </li>
            <li>
            Engagement of Young Professionals as 'Legal Research Consultants' on a contract basis, in the National Human Rights Commission (Posted on : 02-08-2024)
            </li>
            <li>
            Minutes of meeting of the Core Group on Human Rights Defenders held on 19th July 2024 (Posted on : 02-08-2024)
            </li>
            <li>Educational visits of NHRC by Universities & Colleges (Posted on : 30-07-2024)</li>
            <li>Minutes of meeting of the Core Group on Business and Human Rights held on 11th July 2024 (Posted on : 30-07-2024)</li>
          </ul>
        </marquee>
      </div>

      <div className="bg_m4">
        <div className="container pb-5">
          <div className="row">
            <div className="col-md-6 mt-5">
              <div className="bg-white tabs_m1 rounded-4 overflow-hidden p-3 border">
                <Tabs defaultActiveKey="home" id="my-tabs">
                  <Tab eventKey="home" title="NHRC in News">
                    {/* Section start here */}
                    <div className="news_list">
                      <div class="news_list list_m4 list_m4_sup1 pt-3">
                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">01-Sep-2024</p>
                          <p class="px-2">
                          NHRC’s open house discussion organized with D S National Law University, Visakhapatnam highlights various key concerns to ensuring rights and welfare of fishermen
                          </p>
                        </a>
                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">31-Aug-2024</p>
                          <p class="px-2">
                          National Human Rights Commission in partnership with University of Manipur organized a 2-day ‘training programme on human rights in India’
                          </p>
                        </a>
                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">30-Aug-2024</p>
                          <p class="px-2">
                          NHRC, India takes suo motu cognizance of the reported manual scavenging involved in the de-silting of about 2200 km stormwater cum sewage drain network in Mumbai before monsoon every year
                          </p>
                        </a>
                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">30-Aug-2024	</p>
                          <p class="px-2">
                          NHRC, India organizes an open house discussion on ‘Tribal Education in India: Problems, Policies and Perspective
                          </p>
                        </a>
                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">30-Aug-2024</p>
                          <p class="px-2">
                          NHRC, India organizes an open house discussion on preventing beggary and rehabilitation of individuals engaged in beggary
                          </p>
                        </a>
                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">27-Aug-2024	</p>
                          <p class="px-2">
                          NHRC, India declares the winners of its online human rights photography competition, 2024
                          </p>
                        </a>

                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">23-Aug-2024</p>
                          <p class="px-2">
                          NHRC takes suo motu cognizance of the two reported incidents of food poisoning causing serious health problems to 70 students at Chittoor Apollo Health University and the death of three children and illness of 37 others at an orphanage in Anakapalli distr
                          </p>
                        </a>

                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">23-Aug-2024</p>
                          <p class="px-2">
                          NHRC, India takes suo motu cognizance of the reported death of several workers in an explosion at a private industrial unit in the Anakapalli district of Andhra Pradesh
                          </p>
                        </a>

                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">21-Aug-2024</p>
                          <p class="px-2">
                          NHRC takes suo motu cognizance of the reported sexual assault of 13 girl students in an alleged fake NCC camp at a school in the Krishnagiri district of Tamil Nadu
                          </p>
                        </a>

                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">20-Aug-2024</p>
                          <p class="px-2">
                          NHRC, India takes suo motu cognizance of the reported sexual assault of two minor girl students by a school staff in Thane district of Maharashtra
                          </p>
                        </a>

                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">16-Aug-2024</p>
                          <p class="px-2">
                          NHRC’s two-week online short-term internship concludes
                          </p>
                        </a>

                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">13-Aug-2024</p>
                          <p class="px-2">
                          NHRC, India takes suo motu cognizance of the reported death of a junior woman doctor in mysterious circumstances at the state-run RG Kar Medical College & Hospital in Kolkata, West Bengal
                          </p>
                        </a>

                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">05-Aug-2024</p>
                          <p class="px-2">
                          NHRC, India’s online short-term internship programme for university-level students started today
                          </p>
                        </a>

                        <a href="#!">
                          <p class="px-2 c1 fw-semibold ">03-Aug-2024	</p>
                          <p class="px-2">
                          NHRC, India takes suo motu cognizance of the reported death of 13 people due to drowning and electrocution in the Delhi-NCR region amid heavy rain
                          </p>
                        </a>

                      

                        
                      </div>
                      <div className="text-end">
                        {/* <button className="btn btn_m5 m-3">Read More</button> */}
                      </div>
                    </div>
                    {/* Section End here */}
                  </Tab>
                  <Tab eventKey="profile" title="NCW in News">
                    <div class="news_list list_m4 list_m4_sup1 pt-3">
                      <a href="#!">
                       
                        <p class="px-2">
                        Extension of last date of Submission for submitting Proposals for conducting Law Review
                        </p>
                      </a>
                      <a href="#!">
                        
                        <p class="px-2">
                        Call for Proposal for conducting Regional Law Review Consultation for the Financial Year 2024-25 reg
                        </p>
                      </a>
                      <a href="#!">
                     
                        <p class="px-2">
                        Meeting with State Women Comission External Members of Advisory Committee 
                        </p>
                      </a>
                      <a href="#!">
                        
                        <p class="px-2">Digital Shakti 50</p>
                      </a>
                      <a href="#!">
                        
                        <p class="px-2">
                        Internship Programme for the Quarter from July to September, 2024 calendar year 2024 in the National Commission for Women under the Schemes A, B and C
                        </p>
                      </a>
                      <a href="#!">
                       
                        <p class="px-2">
                        Filling up of vacant and anticipated vacant posts in the National Commission for Women by deputation on Foreign Service Terms basis
                        </p>
                      </a>

                      <a href="#!">
                        
                        <p class="px-2">
                        Extension of last date of receipt of application for filling up of posts advertised on 7 May, 2024
                        </p>
                      </a>

                      <a href="#!">
                       
                        <p class="px-2">
                        Request for Proposal (RFP) for engaging Institute for conducting research on - women in unorganized sectors
                        </p>
                      </a>

                      <a href="#!">
                         <p class="px-2">
                        National Commission for Women organises Northern and Final Law Review Consultation on Rights of Women under Property Law on 1st June 2024 at Vigyan Bhawan New Delhi
                        </p>
                      </a>

                      <a href="#!">
                         <p class="px-2">
                         RFP for engaging Institute for conducing Impact Assessment of 73rd and 74th Amendments in Indian Constitution: Assessing the Role of Women Representatives in PRIs and ULBs
                        </p>
                      </a>

                      <a href="#!">
                         <p class="px-2">
                         Filling up of vacant and anticipated vacant posts in the National Commission for Women by deputation on Foreign Service Terms basis
                        </p>
                      </a>

                      <a href="#!">
                         <p class="px-2">
                         International Womens Day Tu Bol
                        </p>
                      </a>
 

                    </div>
                    <div className="text-end">
                      {/* <button className="btn btn_m5 m-3">Read More</button> */}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div className="col-md-6 mt-5">
              <div className="bg-white tabs_m1 rounded-4 overflow-hidden p-3 border">
                <Tabs defaultActiveKey="home" id="my-tabs1">
                  <Tab eventKey="home" title="AIMS Events">
                    {/* Section start here */}
                    <div class="news_list list_m4 list_m4_sup1 pt-3">
                      <a href="#!">
                        <p class="px-2 c1 fw-semibold ">2023</p>
                        <p class="px-2">AIMS HUMAN RIGHTS SOCIETY is primarily concerned about the Rights of Women and Children and has taken up many awareness programs involving women volunteers from different walks of life. As most of the women and children are belongs to backward and downtrodden sectors and are not much literate. AIMS is trying to educate them through photos, paintings and literature.</p>
                      </a>
                      <a href="#!">
                        <p class="px-2 c1 fw-semibold ">2023</p>
                        <p class="px-2">This year we have conducted workshops and seminars on domestic violence, dowry harassment, child labor Sexual harassment, Child Marriage and Ragging. AIMS mainly concentrated on awareness programs by distributing literature pertaining to domestic violence, eve teasing and by conducting group discussions among women, child workers and Students to promote awareness of the prevailing laws for the protection of their Rights in East Godavari dist. Andhra Pradesh.</p>
                      </a>
                      <a href="#!">
                        <p class="px-2 c1 fw-semibold ">2023</p>
                        <p class="px-2">Aims conducted Programs at Indian Famous Social reformers college “Kandukuri Rajalakshmi Govt women’s college, Rajahmundry on 23/8/2023. Seminar conducted and along with Paintings Exhibition of social evils as well as violations against women and children. Dy superintendent of police, DISHA mahila division, Sri Tirumala Rao who chaired with Principal conducted workshop and given Lectures.</p>
                      </a>
                      <a href="#!">
                        <p class="px-2 c1 fw-semibold ">2023</p>
                        <p class="px-2">Aims conducted Program at historical education reformer late Naicker who founded a college M.S.N. Charities, Jagannatha Puram, Kakinada for Downtrodden society on 24/8/2023 with Disha mahila Police Officials conducted Awareness Programme distributed books of their rights to defend themselves under our Constitution.</p>
                      </a>
                      <a href="#!">
                        <p class="px-2 c1 fw-semibold ">2023</p>
                        <p class="px-2">Aims conducted another Programme at St Ann Gloria EM High School, Ramachandra Puram on 26/8/2023 We have conducted a survey about their rights and Modern Life and conducted survey with question paper and answers from them about their ideas and behavior. As a Chief guest Deputy Superintendent of Police, Sri T, S.R. K. Prasad, and Principal Mr. Sharat Kumar participated and given very good Lectures for Young children about their rights and norms of life and keeping their minds free to get good education and Playing Sports to keep their health.</p>
                      </a>
                     
                    </div>
                    <div className="text-end">
                      {/* <button className="btn btn_m5 m-3">Read More</button> */}
                    </div>
                    {/* Section End here */}
                  </Tab>
                  {/* <Tab eventKey="profile" title="NCW in News">
        <p>This is the Profile tab content.</p>
      </Tab> */}
                </Tabs>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </div>

      <div className=" pt-md-5 pb-md-5">
        <div className="container">
          <div className="row  mb-5">
            <div className="col-md-6 ">
              <img
                src={vision_mission_banner}
                className="img-fluid rounded-3"
                alt=""
              />
            </div>
            <div className="col-md-6 align-self-center">
              <h1 class="block__title fw-bold">Our Vision & Mission</h1>
              <p className="font_18">
                To spread mass awareness among the common down trodden people of
                Telangana and Andhra Pradesh in their local language and by
                adopting their culture and through it, letting them know of
                their rights and to defend themselves.
              </p>
              <p className="font_18">
                {" "}
                To project these rights against violation we are using the
                following processes to make them easily understand the subject
                of their rights granted under the Constitution of India, to
                these women and children in particular. AIMS is using projection
                through pamphlets, series of paintings related to atrocities
                against women and children, conducting of camps, exhibitions and
                involving prominent citizen of society.
              </p>
              <p className="font_18">
                This is creating an impact among these people of the society,
                awareness is taking shape, and progress is being appreciated
                which is giving us immense satisfaction. AIM's wants to in
                course of time take this mission, and spread the same to
                Villages, Mandals, and Districts of Telangana and Andhra
                Pradesh. AIMS explain their Fundamental Rights and how to
                safeguard themselves (women children), which is clearly
                described by our founding Fathers of our Constitution as they
                envisaged.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg_m4">
        <div className="container pt-5 pb-5 tabs_m1">
          <div className="row testimonial__body">
            <div className="col-md-8 offset-md-2  text-center pb-3">
              <h1 class="block__title fw-bold mb-3">Featured Campaigns</h1>
              <p className="font_24">
                Discover the power of giving - explore our featured campaigns
                and join a community of changemakers, making a difference for
                causes that matter, one donation at a time.
              </p>
            </div>
            <div className="col-sm-12 ">
              <div className="bg-white tabs_m1 rounded-4 overflow-hidden p-2 px-3 px-md-5">
                <Tabs defaultActiveKey="english_paints" id="my-tabs1">
                  <Tab eventKey="english_paints" title="English">
                  <div className="mt-3">
                    <Swiper
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      // pagination={{
                      //   clickable: true,
                      // }}
                      navigation={true}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                      slidesPerView={1}
                      spaceBetween={20}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                        480: {
                          slidesPerView: 2,
                        },
                        767: {
                          slidesPerView: 3,
                        },
                        991: {
                          slidesPerView: 4,
                        },
                      }}
                 >
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint5} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint6} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint6} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint7} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint9} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint10} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint11} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint12} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint13} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint14} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint15} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint16} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint17} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint18} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint19} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint20} />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint21} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint22} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint23} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint24} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint25} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint26} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint27} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint28} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint29} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={english_paint30} />
                        </div>
                      </SwiperSlide>
 
                    </Swiper>
                    <div class="text-end pt-3 pb-2">
                      <a class="btn btn_m5 btn_m5_small" href="/english-art-gallery">
                        View More
                      </a>
                    </div>
                    </div>
                  </Tab>
                  <Tab eventKey="telugu_paints" title="Telugu">
                 <div className="mt-3">
                    <Swiper
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      // pagination={{
                      //   clickable: true,
                      // }}
                      navigation={true}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                      slidesPerView={1}
                      spaceBetween={20}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                        480: {
                          slidesPerView: 2,
                        },
                        767: {
                          slidesPerView: 3,
                        },
                        991: {
                          slidesPerView: 4,
                        },
                      }}
                 >
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={child1} />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={child2} />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={child3} />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={child4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={child5} />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={womens1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={womens2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={womens3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={womens4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={womens5} />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={society1} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={society2} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={society3} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={society4} />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="card_m7">
                          <img src={society5} />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <div class="text-end pt-3 pb-2">
                      <a class="btn btn_m5 btn_m5_small" href="/telugu-art-gallery">
                        View More
                      </a>
                    </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
