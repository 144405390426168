import React from "react";
import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {
  images,
  CustomImage,
  images1,
  images2,
  images3,
  images4,
  images5,
  images6,
  images7,
  images8,
  images9,
  images10,
  images11,
  images12,
  images13,
  images14,
  images15,
} from "./images";

import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import events_activities from "../assets/images/events_activities.png";
import event23 from "../assets/images/amis/events/event1/event23.JPG";
const Gallery1 = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  //  GALLERY 0 SET START HERE
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [index, setIndex] = useState(-1);
  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;
  const handleClick = (index, item) => {
    setIndex(index);
    setTimeout(() => {
      setWindowWidth((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  //  GALLERY 0 SET END HERE

  //  GALLERY 1 SET START HERE
  const [windowWidth1, setWindowWidth1] = useState(window.innerWidth);
  const [index1, setIndex1] = useState(-1);
  const currentImage1 = images1[index1];
  const nextIndex1 = (index1 + 1) % images1.length;
  const nextImage1 = images1[nextIndex1] || currentImage1;
  const prevIndex1 = (index1 + images1.length - 1) % images1.length;
  const prevImage1 = images1[prevIndex1] || currentImage1;
  const handleClick1 = (index1, item) => {
    setIndex1(index1);
    setTimeout(() => {
      setWindowWidth1((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose1 = () => setIndex1(-1);
  const handleMovePrev1 = () => setIndex1(prevIndex1);
  const handleMoveNext1 = () => setIndex1(nextIndex1);
  //  GALLERY 1 SET END HERE

  //  GALLERY 2 SET START HERE
  const [windowWidth2, setWindowWidth2] = useState(window.innerWidth);
  const [index2, setIndex2] = useState(-1);
  const currentImage2 = images2[index2];
  const nextIndex2 = (index2 + 1) % images2.length;
  const nextImage2 = images2[nextIndex2] || currentImage2;
  const prevIndex2 = (index2 + images2.length - 1) % images2.length;
  const prevImage2 = images2[prevIndex2] || currentImage2;
  const handleClick2 = (index2, item) => {
    setIndex2(index2);
    setTimeout(() => {
      setWindowWidth2((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose2 = () => setIndex2(-1);
  const handleMovePrev2 = () => setIndex2(prevIndex2);
  const handleMoveNext2 = () => setIndex2(nextIndex2);
  //  GALLERY 2 SET END HERE

  //  GALLERY 2 SET START HERE
  const [windowWidth3, setWindowWidth3] = useState(window.innerWidth);
  const [index3, setIndex3] = useState(-1);
  const currentImage3 = images3[index3];
  const nextIndex3 = (index3 + 1) % images3.length;
  const nextImage3 = images3[nextIndex3] || currentImage3;
  const prevIndex3 = (index3 + images3.length - 1) % images3.length;
  const prevImage3 = images3[prevIndex3] || currentImage3;
  const handleClick3 = (index3, item) => {
    setIndex3(index3);
    setTimeout(() => {
      setWindowWidth3((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose3 = () => setIndex3(-1);
  const handleMovePrev3 = () => setIndex3(prevIndex3);
  const handleMoveNext3 = () => setIndex3(nextIndex3);
  //  GALLERY 2 SET END HERE

  //  GALLERY 4 SET START HERE
  const [windowWidth4, setWindowWidth4] = useState(window.innerWidth);
  const [index4, setIndex4] = useState(-1);
  const currentImage4 = images4[index4];
  const nextIndex4 = (index4 + 1) % images4.length;
  const nextImage4 = images4[nextIndex4] || currentImage4;
  const prevIndex4 = (index4 + images4.length - 1) % images4.length;
  const prevImage4 = images4[prevIndex4] || currentImage4;
  const handleClick4 = (index4, item) => {
    setIndex4(index4);
    setTimeout(() => {
      setWindowWidth4((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose4 = () => setIndex4(-1);
  const handleMovePrev4 = () => setIndex4(prevIndex4);
  const handleMoveNext4 = () => setIndex4(nextIndex4);
  //  GALLERY 4 SET END HERE

  //  GALLERY 5 SET START HERE
  const [windowWidth5, setWindowWidth5] = useState(window.innerWidth);
  const [index5, setIndex5] = useState(-1);
  const currentImage5 = images5[index5];
  const nextIndex5 = (index5 + 1) % images5.length;
  const nextImage5 = images5[nextIndex5] || currentImage5;
  const prevIndex5 = (index5 + images5.length - 1) % images5.length;
  const prevImage5 = images5[prevIndex5] || currentImage5;
  const handleClick5 = (index5, item) => {
    setIndex5(index5);
    setTimeout(() => {
      setWindowWidth5((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose5 = () => setIndex5(-1);
  const handleMovePrev5 = () => setIndex5(prevIndex5);
  const handleMoveNext5 = () => setIndex5(nextIndex5);
  //  GALLERY 5 SET END HERE

  //  GALLERY 6 SET START HERE
  const [windowWidth6, setWindowWidth6] = useState(window.innerWidth);
  const [index6, setIndex6] = useState(-1);
  const currentImage6 = images6[index6];
  const nextIndex6 = (index6 + 1) % images6.length;
  const nextImage6 = images6[nextIndex6] || currentImage6;
  const prevIndex6 = (index6 + images6.length - 1) % images6.length;
  const prevImage6 = images6[prevIndex6] || currentImage6;
  const handleClick6 = (index6, item) => {
    setIndex6(index6);
    setTimeout(() => {
      setWindowWidth6((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose6 = () => setIndex6(-1);
  const handleMovePrev6 = () => setIndex6(prevIndex6);
  const handleMoveNext6 = () => setIndex6(nextIndex6);
  //  GALLERY 6 SET END HERE

  //  GALLERY 7 SET START HERE
  const [windowWidth7, setWindowWidth7] = useState(window.innerWidth);
  const [index7, setIndex7] = useState(-1);
  const currentImage7 = images7[index7];
  const nextIndex7 = (index7 + 1) % images7.length;
  const nextImage7 = images7[nextIndex7] || currentImage7;
  const prevIndex7 = (index7 + images7.length - 1) % images7.length;
  const prevImage7 = images7[prevIndex7] || currentImage7;
  const handleClick7 = (index7, item) => {
    setIndex7(index7);
    setTimeout(() => {
      setWindowWidth7((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose7 = () => setIndex7(-1);
  const handleMovePrev7 = () => setIndex7(prevIndex7);
  const handleMoveNext7 = () => setIndex7(nextIndex7);
  //  GALLERY 7 SET END HERE

  //  GALLERY 8 SET START HERE
  const [windowWidth8, setWindowWidth8] = useState(window.innerWidth);
  const [index8, setIndex8] = useState(-1);
  const currentImage8 = images8[index8];
  const nextIndex8 = (index8 + 1) % images8.length;
  const nextImage8 = images8[nextIndex8] || currentImage8;
  const prevIndex8 = (index8 + images8.length - 1) % images8.length;
  const prevImage8 = images8[prevIndex8] || currentImage8;
  const handleClick8 = (index8, item) => {
    setIndex8(index8);
    setTimeout(() => {
      setWindowWidth8((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose8 = () => setIndex8(-1);
  const handleMovePrev8 = () => setIndex8(prevIndex8);
  const handleMoveNext8 = () => setIndex8(nextIndex8);
  //  GALLERY 8 SET END HERE

  //  GALLERY 9 SET START HERE
  const [windowWidth9, setWindowWidth9] = useState(window.innerWidth);
  const [index9, setIndex9] = useState(-1);
  const currentImage9 = images9[index9];
  const nextIndex9 = (index9 + 1) % images9.length;
  const nextImage9 = images9[nextIndex9] || currentImage9;
  const prevIndex9 = (index9 + images9.length - 1) % images9.length;
  const prevImage9 = images9[prevIndex9] || currentImage9;
  const handleClick9 = (index9, item) => {
    setIndex9(index9);
    setTimeout(() => {
      setWindowWidth9((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose9 = () => setIndex9(-1);
  const handleMovePrev9 = () => setIndex9(prevIndex9);
  const handleMoveNext9 = () => setIndex9(nextIndex9);
  //  GALLERY 9 SET END HERE

  //  GALLERY 10 SET START HERE
  const [windowWidth10, setWindowWidth10] = useState(window.innerWidth);
  const [index10, setIndex10] = useState(-1);
  const currentImage10 = images10[index10];
  const nextIndex10 = (index10 + 1) % images10.length;
  const nextImage10 = images10[nextIndex10] || currentImage10;
  const prevIndex10 = (index10 + images10.length - 1) % images10.length;
  const prevImage10 = images10[prevIndex10] || currentImage10;
  const handleClick10 = (index10, item) => {
    setIndex10(index10);
    setTimeout(() => {
      setWindowWidth10((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose10 = () => setIndex10(-1);
  const handleMovePrev10 = () => setIndex10(prevIndex10);
  const handleMoveNext10 = () => setIndex10(nextIndex10);
  //  GALLERY 10 SET END HERE

  //  GALLERY 11 SET START HERE
  const [windowWidth11, setWindowWidth11] = useState(window.innerWidth);
  const [index11, setIndex11] = useState(-1);
  const currentImage11 = images11[index11];
  const nextIndex11 = (index11 + 1) % images11.length;
  const nextImage11 = images11[nextIndex11] || currentImage11;
  const prevIndex11 = (index11 + images11.length - 1) % images11.length;
  const prevImage11 = images11[prevIndex11] || currentImage11;
  const handleClick11 = (index11, item) => {
    setIndex11(index11);
    setTimeout(() => {
      setWindowWidth11((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose11 = () => setIndex11(-1);
  const handleMovePrev11 = () => setIndex11(prevIndex11);
  const handleMoveNext11 = () => setIndex11(nextIndex11);
  //  GALLERY 11 SET END HERE

  //  GALLERY 12 SET START HERE
  const [windowWidth12, setWindowWidth12] = useState(window.innerWidth);
  const [index12, setIndex12] = useState(-1);
  const currentImage12 = images12[index12];
  const nextIndex12 = (index12 + 1) % images12.length;
  const nextImage12 = images12[nextIndex12] || currentImage12;
  const prevIndex12 = (index12 + images12.length - 1) % images12.length;
  const prevImage12 = images12[prevIndex12] || currentImage12;
  const handleClick12 = (index12, item) => {
    setIndex12(index12);
    setTimeout(() => {
      setWindowWidth12((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose12 = () => setIndex12(-1);
  const handleMovePrev12 = () => setIndex12(prevIndex12);
  const handleMoveNext12 = () => setIndex12(nextIndex12);
  //  GALLERY 12 SET END HERE

  //  GALLERY 13 SET START HERE
  const [windowWidth13, setWindowWidth13] = useState(window.innerWidth);
  const [index13, setIndex13] = useState(-1);
  const currentImage13 = images13[index13];
  const nextIndex13 = (index13 + 1) % images13.length;
  const nextImage13 = images13[nextIndex13] || currentImage13;
  const prevIndex13 = (index13 + images13.length - 1) % images13.length;
  const prevImage13 = images13[prevIndex13] || currentImage13;
  const handleClick13 = (index13, item) => {
    setIndex13(index13);
    setTimeout(() => {
      setWindowWidth13((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose13 = () => setIndex13(-1);
  const handleMovePrev13 = () => setIndex13(prevIndex13);
  const handleMoveNext13 = () => setIndex13(nextIndex13);
  //  GALLERY 13 SET END HERE

  //  GALLERY 14 SET START HERE
  const [windowWidth14, setWindowWidth14] = useState(window.innerWidth);
  const [index14, setIndex14] = useState(-1);
  const currentImage14 = images14[index14];
  const nextIndex14 = (index14 + 1) % images14.length;
  const nextImage14 = images14[nextIndex14] || currentImage14;
  const prevIndex14 = (index14 + images14.length - 1) % images14.length;
  const prevImage14 = images14[prevIndex14] || currentImage14;
  const handleClick14 = (index14, item) => {
    setIndex14(index14);
    setTimeout(() => {
      setWindowWidth14((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose14 = () => setIndex14(-1);
  const handleMovePrev14 = () => setIndex14(prevIndex14);
  const handleMoveNext14 = () => setIndex14(nextIndex14);
  //  GALLERY 14 SET END HERE

  //  GALLERY 15 SET START HERE
  const [windowWidth15, setWindowWidth15] = useState(window.innerWidth);
  const [index15, setIndex15] = useState(-1);
  const currentImage15 = images15[index15];
  const nextIndex15 = (index15 + 1) % images15.length;
  const nextImage15 = images15[nextIndex15] || currentImage15;
  const prevIndex15 = (index15 + images15.length - 1) % images15.length;
  const prevImage15 = images15[prevIndex15] || currentImage15;
  const handleClick15 = (index15, item) => {
    setIndex15(index15);
    setTimeout(() => {
      setWindowWidth15((prevWidth) => prevWidth + 1);
    }, 500);
  };
  const handleClose15 = () => setIndex15(-1);
  const handleMovePrev15 = () => setIndex15(prevIndex15);
  const handleMoveNext15 = () => setIndex15(nextIndex15);
  //  GALLERY 15 SET END HERE

  // Slider End here
  return (
    <div>
      <div className="sub_level_banner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-4  offset-md-1">
              <div className="about__banner-text">
                <h1 className="block__title">Event Gallery</h1>
                <h5>
                AMIS HUMAN RIGHTS SOCIETY GALLERY DURING THE YEAR 2009 TO 2024
                </h5>
              </div>
            </div>
            <div className="col-md-6 offset-md-1 about__banner-img pr-md-0">
              <div className=" sub_pages_banner">
                <img
                  src={event23}
                  alt="About Banner"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg_m4">
        <div className="container pt-5">
          {/* <div className="row pt-2 mb-0 pb-4">
            <div className="col-md-8 offset-md-2 text-center block__header mb-0">
              <h1 className="block__title mb-1 position-relative">
                OUR GALLERY
              </h1>
              <p className="block__paragraph mb-0 color_1">
                Aims Human Rights Society Gallery during the year 2009 to 2024
              </p>
            </div>
          </div> */}

          <div className="tabs_m4 tabs_m4_sup1">
            <Tabs activeTab={activeTab} onTabClick={onTabClick}>
              <Tab key="Tab1">2005</Tab>
              <Tab key="Tab2">2006</Tab>
              <Tab key="Tab3">2007</Tab>
              <Tab key="Tab4">2009</Tab>
              <Tab key="Tab5">2012</Tab>
              <Tab key="Tab6">2013</Tab>
              <Tab key="Tab7">2015</Tab>
              <Tab key="Tab8">2019</Tab>
              <Tab key="Tab9">2022</Tab>
              <Tab key="Tab10">2023</Tab>

              {/* <Tab key="Tab12">Rajhamandruy</Tab>
              <Tab key="Tab13">Ramachandrapuram</Tab>
              <Tab key="Tab14">WC 2</Tab>
              <Tab key="Tab15">Wesley</Tab>
              <Tab key="Tab16">Women Commn</Tab> */}
            </Tabs>
            <TabScreen
              key="Tab1"
              activeTab={activeTab}
              index={0}
              className="some-animation-class"
            >
              <p>
                Justice T.H.D. Chalapathi chaired the function at Kutch
                Kadava Pattedar Samaj, Secunderabad, 57th Anniversary of
                Universal Declaration of Human Rights. Smt Mary Ravindranath,
                chairperson, AP State Women's Commission, 2005
              </p>
              <Gallery
                images={images2}
                onClick={handleClick2}
                enableImageSelection={false}
              />
              {!!currentImage2 && (
                <Lightbox
                  mainSrc={currentImage2.original}
                  imageTitle={currentImage2.caption}
                  mainSrcThumbnail={currentImage2.src}
                  nextSrc={nextImage2.original}
                  nextSrcThumbnail={nextImage2.src}
                  prevSrc={prevImage2.original}
                  prevSrcThumbnail={prevImage2.src}
                  onCloseRequest={handleClose2}
                  onMovePrevRequest={handleMovePrev2}
                  onMoveNextRequest={handleMoveNext2}
                />
              )}
            </TabScreen>
            <TabScreen
              key="Tab2"
              activeTab={activeTab}
              index={1}
              className="some-animation-class"
            >
              <p>
               Smt M Shobha Rani, IV Additional Munsif Magistrate,
                Kakinada, at Smt Kantipudi Subbamma Kamma Mahajana Sangh
                Community Hall, Kakinada, on September 3, 2006. Smt Kavikondala
                Saroja, honourable mayor, Kakinada Municipal Corporation.
              </p>
              <Gallery
                images={images}
                onClick={handleClick}
                enableImageSelection={false}
              />
              {!!currentImage && (
                <Lightbox
                  mainSrc={currentImage.original}
                  imageTitle={currentImage.caption}
                  mainSrcThumbnail={currentImage.src}
                  nextSrc={nextImage.original}
                  nextSrcThumbnail={nextImage.src}
                  prevSrc={prevImage.original}
                  prevSrcThumbnail={prevImage.src}
                  onCloseRequest={handleClose}
                  onMovePrevRequest={handleMovePrev}
                  onMoveNextRequest={handleMoveNext}
                />
              )}

              <p>
              Mr Lokendra Sarma, IPS, Additional Director General of Police
                (Human Rights) 58th Anniversary of Universal Declaration of
                Human Rights at Art Gallery, Ravindra Bharati, Hyderabad, on
                December 10, 2006.
              </p>
              <Gallery
                images={images3}
                onClick={handleClick3}
                enableImageSelection={false}
              />
              {!!currentImage3 && (
                <Lightbox
                  mainSrc={currentImage3.original}
                  imageTitle={currentImage3.caption}
                  mainSrcThumbnail={currentImage3.src}
                  nextSrc={nextImage3.original}
                  nextSrcThumbnail={nextImage3.src}
                  prevSrc={prevImage3.original}
                  prevSrcThumbnail={prevImage3.src}
                  onCloseRequest={handleClose3}
                  onMovePrevRequest={handleMovePrev3}
                  onMoveNextRequest={handleMoveNext3}
                />
              )}
            </TabScreen>

            {/* <TabScreen
              key="Tab3"
              activeTab={activeTab}
              index={2}
              className="some-animation-class"
            >
              46546565
            </TabScreen> */}

            <TabScreen
              key="Tab3"
              activeTab={activeTab}
              index={2}
              className="some-animation-class"
            >
              <p>
                Former Rajya Sabha MP, Smt Vanga Geeta, presided over the
                function held at Satyadev Women's Degree College. Smt Dr Surya
                Kumari, Superintendent of Government Hospital, Kakinada, Smt
                Bhagya Lakshmi, MA, Principal, Satyadev Women's Degree College,
                Kakinada, and Sri TV Srinivas, MA LLM, Project Director, Women
                and Child Welfare, Kakinada. Dated: 19 Dec, 2007
              </p>

              <Gallery
                images={images5}
                onClick={handleClick5}
                enableImageSelection={false}
              />
              {!!currentImage5 && (
                <Lightbox
                  mainSrc={currentImage5.original}
                  imageTitle={currentImage5.caption}
                  mainSrcThumbnail={currentImage5.src}
                  nextSrc={nextImage5.original}
                  nextSrcThumbnail={nextImage5.src}
                  prevSrc={prevImage5.original}
                  prevSrcThumbnail={prevImage5.src}
                  onCloseRequest={handleClose5}
                  onMovePrevRequest={handleMovePrev5}
                  onMoveNextRequest={handleMoveNext5}
                />
              )}
            </TabScreen>

            <TabScreen
              key="Tab4"
              activeTab={activeTab}
              index={3}
              className="some-animation-class"
            >
              <p>Word and Deed high school,Hayat Nagar ,2009</p>
              <Gallery
                images={images6}
                onClick={handleClick6}
                enableImageSelection={false}
              />
              {!!currentImage6 && (
                <Lightbox
                  mainSrc={currentImage6.original}
                  imageTitle={currentImage6.caption}
                  mainSrcThumbnail={currentImage6.src}
                  nextSrc={nextImage6.original}
                  nextSrcThumbnail={nextImage6.src}
                  prevSrc={prevImage6.original}
                  prevSrcThumbnail={prevImage6.src}
                  onCloseRequest={handleClose6}
                  onMovePrevRequest={handleMovePrev6}
                  onMoveNextRequest={handleMoveNext6}
                />
              )}
            </TabScreen>

            <TabScreen
              key="Tab5"
              activeTab={activeTab}
              index={4}
              className="some-animation-class"
            >
              <p>Press's CLUB , Somaji Guda, Hyderabad -2012</p>

              <Gallery
                images={images9}
                onClick={handleClick9}
                enableImageSelection={false}
              />
              {!!currentImage9 && (
                <Lightbox
                  mainSrc={currentImage9.original}
                  imageTitle={currentImage9.caption}
                  mainSrcThumbnail={currentImage9.src}
                  nextSrc={nextImage9.original}
                  nextSrcThumbnail={nextImage9.src}
                  prevSrc={prevImage9.original}
                  prevSrcThumbnail={prevImage9.src}
                  onCloseRequest={handleClose9}
                  onMovePrevRequest={handleMovePrev9}
                  onMoveNextRequest={handleMoveNext9}
                />
              )}
            </TabScreen>

            <TabScreen
              key="Tab6"
              activeTab={activeTab}
              index={5}
              className="some-animation-class"
            >
              <p>RAJASREE HIGH SCHOOL, L.B.Nagar on December 10th 2013</p>
              <Gallery
                images={images10}
                onClick={handleClick10}
                enableImageSelection={false}
              />
              {!!currentImage10 && (
                <Lightbox
                  mainSrc={currentImage10.original}
                  imageTitle={currentImage10.caption}
                  mainSrcThumbnail={currentImage10.src}
                  nextSrc={nextImage10.original}
                  nextSrcThumbnail={nextImage10.src}
                  prevSrc={prevImage10.original}
                  prevSrcThumbnail={prevImage10.src}
                  onCloseRequest={handleClose10}
                  onMovePrevRequest={handleMovePrev10}
                  onMoveNextRequest={handleMoveNext10}
                />
              )}


              <p>WOMENS  Commission, Hyderabad 2013</p>

              <Gallery
                images={images7}
                onClick={handleClick7}
                enableImageSelection={false}
              />
              {!!currentImage7 && (
                <Lightbox
                  mainSrc={currentImage7.original}
                  imageTitle={currentImage7.caption}
                  mainSrcThumbnail={currentImage7.src}
                  nextSrc={nextImage7.original}
                  nextSrcThumbnail={nextImage7.src}
                  prevSrc={prevImage7.original}
                  prevSrcThumbnail={prevImage7.src}
                  onCloseRequest={handleClose7}
                  onMovePrevRequest={handleMovePrev7}
                  onMoveNextRequest={handleMoveNext7}
                />
              )}
            </TabScreen>

            <TabScreen
              key="Tab7"
              activeTab={activeTab}
              index={6}
              className="some-animation-class"
            >
              <p>Women commission ,Budha bhavan secunderabad 2015</p>
              <Gallery
                images={images13}
                onClick={handleClick13}
                enableImageSelection={false}
              />
              {!!currentImage13 && (
                <Lightbox
                  mainSrc={currentImage13.original}
                  imageTitle={currentImage13.caption}
                  mainSrcThumbnail={currentImage13.src}
                  nextSrc={nextImage13.original}
                  nextSrcThumbnail={nextImage13.src}
                  prevSrc={prevImage13.original}
                  prevSrcThumbnail={prevImage13.src}
                  onCloseRequest={handleClose13}
                  onMovePrevRequest={handleMovePrev13}
                  onMoveNextRequest={handleMoveNext13}
                />
              )}
            </TabScreen>

            <TabScreen
              key="Tab8"
              activeTab={activeTab}
              index={7}
              className="some-animation-class"
            >
              <p>
               International Women’s Day HUMAN RIGHTS VIOLATION AND
                PAINTING Exhibition at WESLEY COLLEGE FOR WOMEN SECUNDERABAD ON
                08-03-2019
              </p>

              <Gallery
                images={images14}
                onClick={handleClick14}
                enableImageSelection={false}
              />
              {!!currentImage14 && (
                <Lightbox
                  mainSrc={currentImage14.original}
                  imageTitle={currentImage14.caption}
                  mainSrcThumbnail={currentImage14.src}
                  nextSrc={nextImage14.original}
                  nextSrcThumbnail={nextImage14.src}
                  prevSrc={prevImage14.original}
                  prevSrcThumbnail={prevImage14.src}
                  onCloseRequest={handleClose14}
                  onMovePrevRequest={handleMovePrev14}
                  onMoveNextRequest={handleMoveNext14}
                />
              )}
            </TabScreen>

            <TabScreen
              key="Tab9"
              activeTab={activeTab}
              index={8}
              className="some-animation-class"
            >
              <p>
              Sri Narayana school, Military's Dairy farm road,
                Secunderabad - 2022
              </p>
              <Gallery
                images={images8}
                onClick={handleClick8}
                enableImageSelection={false}
              />
              {!!currentImage8 && (
                <Lightbox
                  mainSrc={currentImage8.original}
                  imageTitle={currentImage8.caption}
                  mainSrcThumbnail={currentImage8.src}
                  nextSrc={nextImage8.original}
                  nextSrcThumbnail={nextImage8.src}
                  prevSrc={prevImage8.original}
                  prevSrcThumbnail={prevImage8.src}
                  onCloseRequest={handleClose8}
                  onMovePrevRequest={handleMovePrev8}
                  onMoveNextRequest={handleMoveNext8}
                />
              )}
            </TabScreen>

            <TabScreen
              key="Tab10"
              activeTab={activeTab}
              index={9}
              className="some-animation-class"
            >
              <p>
               Smt Kandukuri Rajalakshmi women's college,Rajhamandruy in
                2023 andruy
              </p>
              <Gallery
                images={images1}
                onClick={handleClick1}
                enableImageSelection={false}
              />
              {!!currentImage1 && (
                <Lightbox
                  mainSrc={currentImage1.original}
                  imageTitle={currentImage1.caption}
                  mainSrcThumbnail={currentImage1.src}
                  nextSrc={nextImage1.original}
                  nextSrcThumbnail={nextImage1.src}
                  prevSrc={prevImage1.original}
                  prevSrcThumbnail={prevImage1.src}
                  onCloseRequest={handleClose1}
                  onMovePrevRequest={handleMovePrev1}
                  onMoveNextRequest={handleMoveNext1}
                />
              )}

              <p>M.S.N.Charties college,Kakinada,East Godavari in 2023</p>

              <Gallery
                images={images4}
                onClick={handleClick4}
                enableImageSelection={false}
              />
              {!!currentImage4 && (
                <Lightbox
                  mainSrc={currentImage4.original}
                  imageTitle={currentImage4.caption}
                  mainSrcThumbnail={currentImage4.src}
                  nextSrc={nextImage4.original}
                  nextSrcThumbnail={nextImage4.src}
                  prevSrc={prevImage4.original}
                  prevSrcThumbnail={prevImage4.src}
                  onCloseRequest={handleClose4}
                  onMovePrevRequest={handleMovePrev4}
                  onMoveNextRequest={handleMoveNext4}
                />
              )}

              <p>St Anns Gloria EM High School,Ramachandra puram -2023</p>
              <Gallery
                images={images15}
                onClick={handleClick15}
                enableImageSelection={false}
              />
              {!!currentImage15 && (
                <Lightbox
                  mainSrc={currentImage15.original}
                  imageTitle={currentImage15.caption}
                  mainSrcThumbnail={currentImage15.src}
                  nextSrc={nextImage15.original}
                  nextSrcThumbnail={nextImage15.src}
                  prevSrc={prevImage15.original}
                  prevSrcThumbnail={prevImage15.src}
                  onCloseRequest={handleClose15}
                  onMovePrevRequest={handleMovePrev15}
                  onMoveNextRequest={handleMoveNext15}
                />
              )}
            </TabScreen>

            <TabScreen
              key="Tab11"
              activeTab={activeTab}
              index={10}
              className="some-animation-class"
            >
              dsfafdasdf
            </TabScreen>

            <TabScreen
              key="Tab12"
              activeTab={activeTab}
              index={11}
              className="some-animation-class"
            >
              <Gallery
                images={images11}
                onClick={handleClick11}
                enableImageSelection={false}
              />
              {!!currentImage11 && (
                <Lightbox
                  mainSrc={currentImage11.original}
                  imageTitle={currentImage11.caption}
                  mainSrcThumbnail={currentImage11.src}
                  nextSrc={nextImage11.original}
                  nextSrcThumbnail={nextImage11.src}
                  prevSrc={prevImage11.original}
                  prevSrcThumbnail={prevImage11.src}
                  onCloseRequest={handleClose11}
                  onMovePrevRequest={handleMovePrev11}
                  onMoveNextRequest={handleMoveNext11}
                />
              )}
            </TabScreen>

            <TabScreen
              key="Tab13"
              activeTab={activeTab}
              index={12}
              className="some-animation-class"
            >
              <Gallery
                images={images12}
                onClick={handleClick12}
                enableImageSelection={false}
              />
              {!!currentImage12 && (
                <Lightbox
                  mainSrc={currentImage12.original}
                  imageTitle={currentImage12.caption}
                  mainSrcThumbnail={currentImage12.src}
                  nextSrc={nextImage12.original}
                  nextSrcThumbnail={nextImage12.src}
                  prevSrc={prevImage12.original}
                  prevSrcThumbnail={prevImage12.src}
                  onCloseRequest={handleClose12}
                  onMovePrevRequest={handleMovePrev12}
                  onMoveNextRequest={handleMoveNext12}
                />
              )}
            </TabScreen>

            <TabScreen
              key="Tab14"
              activeTab={activeTab}
              index={13}
              className="some-animation-class"
            >
              46465465
            </TabScreen>

            <TabScreen
              key="Tab14"
              activeTab={activeTab}
              index={13}
              className="some-animation-class"
            >
             6464646
            </TabScreen>

            <TabScreen
              key="Tab15"
              activeTab={activeTab}
              index={14}
              className="some-animation-class"
            >
              45646
            </TabScreen>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery1;
