import React from "react";
import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images, CustomImage,images1,images2} from "./paintimages";
import { Tabs, Tab,TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
 
import event23 from "../assets/images/amis/events/event1/event23.JPG";
  

const Paints = () => {
    const [activeTab, setActiveTab] = React.useState(0);
    const onTabClick = (e, index) => {
       setActiveTab(index);
     };

     
  //  GALLERY 0 SET START HERE
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [index, setIndex] = useState(-1);
  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;
 const handleClick = (index, item) => {
  setIndex(index);
  setTimeout(() => {  setWindowWidth(prevWidth => prevWidth + 1);}, 500); 
 };
   const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
//  GALLERY 0 SET END HERE



  //  GALLERY 1 SET START HERE
  const [windowWidth1, setWindowWidth1] = useState(window.innerWidth);
  const [index1, setIndex1] = useState(-1);
  const currentImage1 = images1[index1];
  const nextIndex1 = (index1 + 1) % images1.length;
  const nextImage1 = images1[nextIndex1] || currentImage1;
  const prevIndex1 = (index1 + images1.length - 1) % images1.length;
  const prevImage1 = images1[prevIndex1] || currentImage1;
 const handleClick1 = (index1, item) => {
  setIndex1(index1);
  setTimeout(() => {  setWindowWidth1(prevWidth => prevWidth + 1);}, 500); 
 };
   const handleClose1 = () => setIndex1(-1);
  const handleMovePrev1 = () => setIndex1(prevIndex1);
  const handleMoveNext1 = () => setIndex1(nextIndex1);
//  GALLERY 1 SET END HERE



  //  GALLERY 2 SET START HERE
  const [windowWidth2, setWindowWidth2] = useState(window.innerWidth);
  const [index2, setIndex2] = useState(-1);
  const currentImage2 = images2[index2];
  const nextIndex2 = (index2 + 1) % images2.length;
  const nextImage2 = images2[nextIndex2] || currentImage2;
  const prevIndex2 = (index2 + images2.length - 1) % images2.length;
  const prevImage2 = images2[prevIndex2] || currentImage2;
 const handleClick2 = (index2, item) => {
  setIndex2(index2);
  setTimeout(() => {  setWindowWidth1(prevWidth => prevWidth + 1);}, 500); 
 };
   const handleClose2 = () => setIndex2(-1);
  const handleMovePrev2 = () => setIndex2(prevIndex2);
  const handleMoveNext2 = () => setIndex2(nextIndex2);
//  GALLERY 1 SET END HERE

     
    return (
        <div className="Paints">
             <div className="sub_level_banner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-4  offset-md-1">
              <div className="about__banner-text">
                <h1 className="block__title">Art Gallery</h1>
                <h5>
                AMIS HUMAN RIGHTS SOCIETY Art GALLERY DURING THE YEAR 2009 TO 2024
                </h5>
              </div>
            </div>
            <div className="col-md-6 offset-md-1 about__banner-img pr-md-0">
              <div className=" sub_pages_banner">
                <img
                  src={event23}
                  alt="About Banner"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
             <div className="bg_m4">
                <div className="container pt-5">


                {/* <div className="row pt-2 mb-0 pb-4">
                    <div className="col-md-8 offset-md-2 text-center block__header mb-0">
                        <h1 className="block__title mb-1 position-relative">OUR PAINTS</h1>
                        <p className="block__paragraph mb-0 color_1">Aims Human  Rights Society Paints during the year 2009 to 2024</p>
                    </div>
                </div> */}

                
                <div className="tabs_m4">
        <Tabs activeTab={activeTab} onTabClick={onTabClick}>
           <Tab key='Tab1'>Childrens</Tab>
           <Tab key='Tab2'>Womens</Tab>
           <Tab key='Tab3'>Society</Tab>
           
           </Tabs>

           <TabScreen  key='Tab1' activeTab={activeTab}  index={0} className="some-animation-class">
           <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      {!!currentImage && (
        <Lightbox
        mainSrc={currentImage.original}
        imageTitle={currentImage.caption}
        mainSrcThumbnail={currentImage.src}
        nextSrc={nextImage.original}
        nextSrcThumbnail={nextImage.src}
        prevSrc={prevImage.original}
        prevSrcThumbnail={prevImage.src}
        onCloseRequest={handleClose}
        onMovePrevRequest={handleMovePrev}
        onMoveNextRequest={handleMoveNext}
        />
      )}
             </TabScreen>
             <TabScreen  key='Tab2' activeTab={activeTab}  index={1} className="some-animation-class">
             <Gallery
        images={images1}
        onClick={handleClick1}
        enableImageSelection={false}
      />
      {!!currentImage1 && (
        <Lightbox
        mainSrc={currentImage1.original}
        imageTitle={currentImage1.caption}
        mainSrcThumbnail={currentImage1.src}
        nextSrc={nextImage1.original}
        nextSrcThumbnail={nextImage1.src}
        prevSrc={prevImage1.original}
        prevSrcThumbnail={prevImage1.src}
        onCloseRequest={handleClose1}
        onMovePrevRequest={handleMovePrev1}
        onMoveNextRequest={handleMoveNext1}
        />
      )}
  </TabScreen>

             <TabScreen  key='Tab3' activeTab={activeTab}  index={2} className="some-animation-class">
               
             <Gallery
        images={images2}
        onClick={handleClick2}
        enableImageSelection={false}
      />
      {!!currentImage2 && (
        <Lightbox
        mainSrc={currentImage2.original}
        imageTitle={currentImage2.caption}
        mainSrcThumbnail={currentImage2.src}
        nextSrc={nextImage2.original}
        nextSrcThumbnail={nextImage2.src}
        prevSrc={prevImage2.original}
        prevSrcThumbnail={prevImage2.src}
        onCloseRequest={handleClose2}
        onMovePrevRequest={handleMovePrev2}
        onMoveNextRequest={handleMoveNext2}
        />
      )}

             </TabScreen>
           </div>
        
                </div>
                </div>
        </div>
    )
};

export default Paints;
 