 
import "../../styles/donate.css";

import donateImg from "../../assets/images/donate.jpg";

import { useFormik } from "formik";
import * as Yup from "yup";


export const signUpSchema = Yup.object({
    name: Yup.string().min(2).max(25).required("Please enter your name"),
    amount: Yup.string().min(2).max(25).required("Please enter your Amount"),
    phoneNumber: Yup.string().min(6).max(12).required("Please enter your Phone Number"),
    email: Yup.string().email().required("Please enter your email"),
    category: Yup.string().min(2).max(30).required("Please enter your Category"),
    organization: Yup.string().min(2).max(30).required("Please enter your Organization"),
    comment: Yup.string().min(10).max(150).required("Please enter your Comment"),
})

const initialValues = {
    name:"",
    amount:"",
    phoneNumber:"",
    email:"",
    category:"",
    organization:"",
    comment:"",
}


const Donate = () => {

    // const [activeBtn1, setActiveBtn1] = useState(false);
    // const [activeBtn2, setActiveBtn2] = useState(false);

    // const handleActiveBtn1 = (e) => {
    //     e.preventDefault();
    //     setActiveBtn1(!activeBtn1)
    //     setActiveBtn2(false)
    // };

    // const handleActiveBtn2 = (e) => {
    //     e.preventDefault();
    //     setActiveBtn2(!activeBtn2)
    //     setActiveBtn1(false)
    // };
    
    


    // form validation

    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues,
        validationSchema: signUpSchema,
        onSubmit: (values, action) => {
          console.log(
            "~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
            values
          );
          action.resetForm();
        }
    })
    // console.log(
    //     "~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
    //     errors
    //   )

   


    return (
        <div className="donate__fome pt-0">
            <div className="container">
                <div className="row">
                    <div className="block__header col-md-8 offset-md-2 text-center">
                        <h1 className="block__title fw-bold mb-3">
                        Donate To  Make a Difference
                        </h1>
                        <p className="font_24">Every donation counts - join our mission to make a difference and support causes that impact lives, from education and healthcare to social justice and environmental conservation.</p>
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-sm-12 text-center donate_img">
                        <img src={donateImg} alt="donateImg" className="img-fluid"/>
                    </div> */}
                </div>
                <div className="form_m1">
                    <form onSubmit={handleSubmit}>
                      {/* <div className="row">
                        <div className="col donationBtns">
                            <button className={activeBtn1 ? "active" : ""} onClick={handleActiveBtn1}><i className="ri-checkbox-circle-line"></i> One Time</button>
                            <button className={activeBtn2 ? "active" : ""} onClick={handleActiveBtn2}><i className="ri-checkbox-circle-line"></i> Monthly</button>
                        </div>
                        <div className="donationAmountBtns my-5">
                            <button className="btn1"><i className="ri-checkbox-blank-circle-line"></i> $ 1000</button>
                            <button className="btn1"><i className="ri-checkbox-blank-circle-line"></i> $ 2000</button>
                            <button className="btn1"><i className="ri-checkbox-blank-circle-line"></i> $ 5000</button>
                            <button className="btn1"><i className="ri-checkbox-blank-circle-line"></i> Other</button>
                        </div>
                    </div>   */}
                    <div className="row">                                
                        <div className="col-sm-6">     
                            <div className="input_m2 donate_input">
                                <label className="mb-3">Donation Amount  <span className="star_imp">*</span></label>
                                <div className="input_m2sup">
                                <select class="sel_m4">
                                    <option>₹ INR</option>
                                    <option>$ Dollar</option>
                                    </select>
                                    <input type="number" value={values.amount}  onChange={handleChange} onBlur={handleBlur} name="amount" placeholder="0.00"/>
                                { errors.amount && touched.amount ? <p>{errors.amount}</p> : null}
                                </div>
                              
                                {/* { errors.name && touched.name ? <p>{errors.name}</p> : null} */}
                            </div>
                        </div>  

                        <div className="col-sm-6">     
                        <div className="form-check mt-md-5 pt-2">
    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
    <label class="form-check-label label_m1" for="exampleCheck1">Make the donation every month?</label>
  </div>
  </div>  

   

                         {/* <div className="col-sm-6">
                            <div className="input_m2">
                                <label>Category <span className="star_imp">*</span></label>
                                 <select>
                                 <option>Select</option>
                                    <option>Category 1</option>
                                    <option>Category 2</option>
                                    <option>Category 3</option>
                                 </select>
                              
                            </div>
                        </div>
                      <div className="col-sm-6">
                            <div className="input_m2">
                                <label>Name of the Organization <span className="star_imp">*</span></label>
                                 <select>
                                 <option>Select</option>
                                    <option>Name 1</option>
                                    <option>Name 2</option>
                                    <option>Name 3</option>
                                 </select>
                              
                            </div>
                        </div> */}

                        <div className="col-sm-6">     
                            <div className="input_m2">
                                <label>Organization or Person </label>
                                <input type="test" value="" name=""/>
                             </div>
                        </div>

                        <div className="col-sm-6">     
                            <div className="input_m2">
                                <label>PAN No. <span className="star_imp">*</span> </label>
                                <input type="number" value="" name=""/>
                             </div>
                        </div>

                    
                        <div className="col-sm-6">     
                            <div className="input_m2">
                                <label>Phone Number <span className="star_imp">*</span></label>
                                <input type="number" value={values.phoneNumber} onChange={handleChange} onBlur={handleBlur} name="phoneNumber"/>
                                { errors.phoneNumber && touched.phoneNumber ? <p>{errors.phoneNumber}</p> : null}
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="input_m2">
                                <label>Email ID  <span className="star_imp">*</span></label>
                                <input type="text" value={values.email} onChange={handleChange} onBlur={handleBlur} name="email"/>
                                { errors.email && touched.email ? <p>{errors.email}</p> : null}
                            </div>
                        </div>

                        {/* <div className="col-sm-6">
                            <div className="input_m2">
                                <label>Category  <span className="star_imp">*</span></label>
                                <input type="text" value={values.category} onChange={handleChange} onBlur={handleBlur} name="category"/>
                                { errors.category && touched.category ? <p>{errors.category}</p> : null}
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="input_m2">
                                <label>Name of the Organization  <span className="star_imp">*</span></label>
                                <input type="text" value={values.organization} onChange={handleChange} onBlur={handleBlur} name="organization"/>
                                { errors.organization && touched.organization ? <p>{errors.organization}</p> : null}
                            </div>
                        </div> */}

                        <div className="col-sm-12 d-none">
                            <div className="input_m2">
                                <label>Write Your Comment (Optional)</label>
                                <textarea className="w-100" value={values.comment} onChange={handleChange} onBlur={handleBlur} name="comment" rows="1"></textarea>
                                { errors.comment && touched.comment ? <p>{errors.comment}</p> : null}
                            </div>
                        </div>

                        <div className="col-sm-12">                     
<div className="custom_switch  ">
    <p className="d-inline-block me-3 text-secondary">Mark the donation as anonymous to avoid showing any raised list on the website.
</p>

                        <label className="switch">
  <input type="checkbox"  />
  <span className="slider round"></span>
</label>
</div>
</div>
<div className="col-sm-12  text-center mt-5">
                        <button className="btn btn_m5 px-5  ">Connect to payment gateway</button>
                           
                        </div>

                    </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default Donate;