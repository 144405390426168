import React from "react";
import "../styles/signin.css";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Helmet from "../components/Helmet/Helmet";
 
import user_icon from "../assets/images/input_iocn1.png"
import user_icon1 from "../assets/images/input_iocn4.png"
import facebook from "../assets/images/facebook.png"
import google from "../assets/images/google.png"

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

export const signUpSchema = Yup.object({
    email: Yup.string().email().required("Please enter your email"),
    password: Yup.string().min(6).required("Please enter your password"),
})

const initialValues = {
    email:"",
    password:"",
}

const Signin = () => {

    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues,
        validationSchema: signUpSchema,
        onSubmit: (values, action) => {
          console.log(
            "~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
            values
          );
          action.resetForm();
        }
    })


    return (
        <Helmet title={'Sign In'}>
        <div className="signin py-5">
            <div className="container">
                {/* <div className="signin__header text-center pb-3">
                    <h5>Sign In</h5>
                </div> */}
                
                <div className="signin__form">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                        <div className="card card-body shadow">
                        <div className="circle_m4"></div>
                            <div className="circle_m4 left_align"></div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="fw-semibold my-4">Welcome!</h5>
                        <form onSubmit={handleSubmit}>
                        
                     
                        <div className="float_input imp_star mb-3">
                                   <img src={user_icon} />
                     <FloatingLabel controlId="floatingInput" label="Mobile number / Email ID" >
                            <Form.Control value={values.email} onChange={handleChange} onBlur={handleBlur} type="text" name="email" placeholder="Mobile number / Email ID"/>
                       </FloatingLabel>
                    
                      { errors.email && touched.email ? <p className="m-0 c_5">{errors.email}</p> : null}
                                {/* <FloatingLabelInput  label="Mobile number / Email ID" value={values.email} onChange={handleChange} onBlur={handleBlur} type="text" name="email"/>
                                { errors.email && touched.email ? <p className="m-0 c_5">{errors.email}</p> : null} */}
                                   {/*  <label>First Name <span className="star_imp">*</span></label>
                                    <input value={values.fname} onChange={handleChange} onBlur={handleBlur} type="text" name="fname"/>
                                    { errors.fname && touched.fname ? <p>{errors.fname}</p> : null} */}
                                </div>

                            {/* <div className="input_m2">
                                <label>Email Id <span className="star_imp">*</span></label>
                                <input />
                              
                            </div> */}

<div className="float_input imp_star mb-3">

                                   <img src={user_icon1} />
                                   <FloatingLabel controlId="floatingInput" label="Password / OTP" >
                            <Form.Control  value={values.password} onChange={handleChange} onBlur={handleBlur} type="password" name="password" id="password" placeholder="Password / OTP"/>
                       </FloatingLabel>

                                {/* <FloatingLabelInput   label="Password / OTP"  value={values.password} onChange={handleChange} onBlur={handleBlur} type="password" name="password" id="password"/> */}
                               {/* <label>Password <span className="star_imp">*</span></label>
                                <input placeholder=""/> */}
                                { errors.password && touched.password ? <p className="m-0 c_5">{errors.password}</p> : null}
                                <div className="forgot__password ">
                                    <Link>Forgot Password?</Link>
                                </div>
                            </div>

                            <div className="form__btns">
                                <button className="btn_m5 px-4">Sign In</button>
                                {/* <span>or</span>
                                <button className="btn2">Sign In</button> */}
                            </div>
                    </form>
                    </div>
                  
                    <div className="col-md-6 ps-lg-5">
                        <div className="line_g"></div>
                            <div className="text-center pt-5 contribute">
                                <h5 className="mb-3">or Login with </h5>
                                <button className="btn_m6 text-white w-100 mb-3 p-2"><img src={facebook} />Sign In with facebook</button>
                                <button className="btn_m7 text-white w-100 mb-3 p-2"><img src={google} />Sign In with Google</button>
                                 <p>Not yet register? <a href="" className="link_m3 fweight_600">Sign Up</a> here </p>
                            </div>
                            </div>
</div>
</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Helmet>
    )
}

export default Signin;