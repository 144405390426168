import React from "react";
import Hero from "../components/UI/Hero";
import Summary from "../components/UI/Summary";
import Featured from "../components/UI/Featured";
import Helmet from "../components/Helmet/Helmet";
import Testimonials from "../components/UI/Testimonials";
import Funds from "../components/UI/Funds";
import Donate from "../components/UI/Donate";

import { useEffect } from 'react'

 
const Home = () => {
    useEffect(() => {
        document.body.classList.add('home-layout')
        return () => {
          document.body.classList.remove('home-layout')
        }
      }, []);
    return (
            <Helmet title={'Home'}>
                <Hero/>
                <Summary/>
                <Featured/>
              
                {/* <Funds/> */}
                <Testimonials/>
                {/* <Donate/> */}
            </Helmet>
            
    )
};

export default Home;