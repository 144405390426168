import './App.css';
import Layout from './components/Layout/Layout';
import React, { useEffect } from 'react';
 
import { useHistory } from 'react-router-dom';


function App() {
 
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

 
  return (
    
    <div className="App">
       <Layout/>
    </div>
  );
}

export default App;
