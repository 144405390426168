import React, { useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";  // Commented out reCAPTCHA import
import "../styles/organisationreg.css";
import user_icon from "../assets/images/input_iocn1.png";
import user_icon1 from "../assets/images/input_iocn2.png";
import user_icon2 from "../assets/images/input_iocn3.png";
import user_icon3 from "../assets/images/input_iocn4.png";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import Helmet from "../components/Helmet/Helmet";
import axios from "axios";

// const SITE_KEY = "6LepWjYqAAAAACI-CUjJyas27IAgYJepXFAYSn91"; // Replace with your Site Key - Commented out SITE_KEY

export const signUpSchema = Yup.object({
  fname: Yup.string()
    .matches(/^[A-Za-z]+$/, "First name should only contain letters")
    .min(3, "First name must be at least 3 characters")
    .required("Please enter first name"),
  email: Yup.string().email("Invalid email format").required("Please enter your email"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Only digits are allowed")
    .min(10, "Phone number must be at least 10 digits")
    .max(11, "Phone number must be at most 11 digits")
    .required("Please enter your Phone number"),
  comment: Yup.string()
    .min(5, "Comment must be at least 5 characters") // Added validation for the comment field
    .required("Please enter your comment"), // Make it required
});


const initialValues = {
  fname: "",
  lname: "",
  email: "",
  phoneNumber: "",
  comment: "",
};

// const verifyRecaptcha = async (req, res, next) => {  // Commented out verifyRecaptcha function
//   const { "g-recaptcha-response": token } = req.body;
//   const secretKey = "6LepWjYqAAAAAAahgM5dQlRfePuNdoGGGpijls5r"; // Replace with your Secret Key

//   try {
//     const response = await axios.post(`https://www.google.com/recaptcha/api/siteverify?secret=${secretKey}&response=${token}`);
//     if (response.data.success) {
//       next(); // Continue to process the form data
//     } else {
//       return res.status(400).json({ error: "reCAPTCHA verification failed. Please try again." });
//     }
//   } catch (error) {
//     console.error("Error verifying reCAPTCHA:", error);
//     res.status(500).json({ error: "Internal Server Error" });
//   }
// };

const Contact = () => {
  // const [captchaToken, setCaptchaToken] = useState(null);  // Commented out captchaToken state

  // Callback to handle reCAPTCHA change
  // const handleCaptchaChange = (token) => {  // Commented out handleCaptchaChange function
  //   setCaptchaToken(token);
  // };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: async (values, actions) => {
      // if (!captchaToken) {  // Commented out captcha validation
      //   alert("Please verify that you are not a robot.");
      //   return;
      // }

      try {
        const response = await axios.post('https://aiims.developers13.org/api/contactUs', {
          first_name: values.fname,
          last_name: values.lname,
          phone_number: values.phoneNumber,
          email: values.email,
          comments: values.comment,
          // "g-recaptcha-response": captchaToken, // Send the reCAPTCHA token - Commented out
        });

        console.log('Submitted Data:', response.data);
        actions.resetForm();
        // setCaptchaToken(null); // Reset captcha after form submission - Commented out
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
  });

  return (
    <Helmet title={"Register New User"}>
      <div className="organisation__reg py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="card card-body shadow">
                <div className="circle_m4 left_align"></div>
                <div className="organisation__header text-center mb-5">
                  <h5 className="fw-semibold">Contact Us</h5>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="float_input imp_star mb-5">
                        <img alt="" src={user_icon} />
                        <FloatingLabel controlId="floatingInput" label="First Name">
                          <Form.Control
                            name="fname"
                            value={values.fname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="First Name"
                            onInput={(e) => (e.target.value = e.target.value.replace(/[^A-Za-z]/g, ""))}  // Updated line
                          />
                        </FloatingLabel>
                        {errors.fname && touched.fname ? (
                          <p className="mb-0 c_5">{errors.fname}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="float_input imp_star mb-5">
                        <img alt="" src={user_icon} />
                        <FloatingLabel controlId="floatingInput" label="Last Name">
                          <Form.Control
                            value={values.lname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            name="lname"
                            placeholder="Last Name"
                          />
                        </FloatingLabel>
                        {errors.lname && touched.lname ? (
                          <p>{errors.lname}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="float_input imp_star mb-5">
                        <img alt="" src={user_icon1} />
                        <FloatingLabel controlId="floatingInput" label="Phone Number">
                          <Form.Control
                            type="tel"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Phone Number"
                            maxLength={11}  // Restrict input to 10 characters
                            minLength={10} 
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, "");  // Only digits
                            }}
                          />
                        </FloatingLabel>
                        {errors.phoneNumber && touched.phoneNumber ? (
                          <p className="mb-0 c_5">{errors.phoneNumber}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="float_input imp_star mb-5">
                        <img alt="" src={user_icon2} />

                        <FloatingLabel controlId="floatingInput" label="Email ID">
                          <Form.Control
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="email"
                            placeholder="Email ID"
                          />
                        </FloatingLabel>
                        {errors.email && touched.email ? (
                          <p className="mb-0 c_5">{errors.email}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-12">
  <div className="mb-2">
    <FloatingLabel controlId="floatingTextarea2" label="Comments">
      <Form.Control
        as="textarea"
        placeholder="Leave a comment here"
        name="comment"
        value={values.comment}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ height: "100px" }}
      />
    </FloatingLabel>
    {errors.comment && touched.comment ? ( // Display error message if validation fails
      <p className="mb-0 c_5">{errors.comment}</p>
    ) : null}
  </div>
</div>
                  </div>

                  {/* reCAPTCHA */}
                  {/* <div className="my-3">  // Commented out reCAPTCHA component
                    <ReCAPTCHA
                      sitekey={SITE_KEY}
                      onChange={handleCaptchaChange}
                    />
                    {captchaToken === null && <p className="text-danger">Please complete the CAPTCHA.</p>}
                  </div> */}

                  <div className="row">
                    <div className="col-sm-12 text-center">
                      <button type="submit" className="btn_m5 px-4">
                        Submit{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6 position-relative overflow-hidden">
              <div className="circle_m4 me-5"></div>
              <div>
                <h5>Registered Office:</h5>
                <h4 className="fw-semibold">
                  AIMS Human Rights Society survey’s
                </h4>
                <p>
                  Plot No. 1, H.No. 1-24-21/4, Sri Sai Nagar, Lothukunta, Alwal,
                  Secunderabad - 500015, Telangana, India.
                </p>
              </div>

              <div className="mt-5">
                <h5>Admin Office:</h5>
                <h4 className="fw-semibold">
                  AIMS Human Rights Society survey’s
                </h4>
                <p>
                  Extension to Road Number 36, Phase 1, Jubilee Hills, Plot
                  No.115, Madhapur Rd, Kavuri Hills, Hyderabad, Telangana
                  500033, India.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Helmet>
  );
};

export default Contact;
