import React from "react";
import "../../styles/summary.css";

import IconImg1 from "../../assets/images/icon9.png";
import IconImg2 from "../../assets/images/icon6.png";
import IconImg3 from "../../assets/images/icon7.png";
import IconImg4 from "../../assets/images/icon8.png";

const summary = [
    // {
    //     img: IconImg1,
    //     value: "3,35,768",
    //     text: "Total Donations",
    // },
    {
        img: IconImg2,
        value: "5,338",
        text: "Total Donors",
    },
    {
        img: IconImg3,
        value: "1230",
        text: "# of Volunteering Hours",
    },
    {
        img: IconImg4,
        value: "3095",
        text: "Lives Impacted",
    },
]

const Summary = () => {
    return (
        <div className="summary d-none">
           <div className="container">
           <div className="row">
           <div className="col-6 col-sm-3 summary_data text-center" >
                                <img src={IconImg1}/>
                                <h3><i class="fa fa-inr" aria-hidden="true"></i> 3,35,768</h3>
                                <p>Total Donations</p>
                        </div>
             {
                summary.map((summaryItem, index) => (
                        <div className="col-6 col-sm-3 summary_data text-center" key={index}>
                                <img src={summaryItem.img}/>
                                <h3> {summaryItem.value}</h3>
                                <p>{summaryItem.text}</p>
                        </div>
                ))
             }
             </div>
           </div>
        </div>
    )
};

export default Summary;