 
import "../styles/blogs.css";
import { Link } from "react-router-dom";
import blogsImg1 from "../assets/images/blog1.png";
import blogsImg2 from "../assets/images/blog2.png";
import blogsImg3 from "../assets/images/blog3.png";
import blogsImg4 from "../assets/images/blog4.png";
import blogsImg5 from "../assets/images/blog5.png";
import blogsImg6 from "../assets/images/blog6.png";
import blogsImg7 from "../assets/images/blog7.png";
import Donate from "../components/UI/Donate";
import { useFormik } from "formik";
import * as Yup from "yup";

import blogData from "../assets/data/blogData";

import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React, { useEffect , useState} from "react";
 
 
import Modal from 'react-bootstrap/Modal';

export const signUpSchema = Yup.object({
    email: Yup.string().email().required("Please enter your email"),
})

const initialValues = {
    email:"",
}

const Blogs = () => {



    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues,
        validationSchema: signUpSchema,
        onSubmit: (values, action) => {
          console.log(
            "~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
            values
          );
          action.resetForm();
        }
    })

    return (
        <div className="blogs py-2 py-md-5">
            <div className="container">
                {/* <div className="blogs__header text-center mb-5"><h1>Blogs</h1></div> */}

                <div className="row">
                    <div className="col-md-8 position-relative" >
                        <div className="banner_tr1">
                         <img src={blogsImg1} alt="Blog Banner" className="w-100"/>
                    </div>
                        <div className="block__text position-absolute">
                            <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tempus odio.</h5>
                            <ul>
                                <li><Link>Febraury 15, 2023</Link></li>
                                 <li>|</li>
                                <li><Link>Comments : 15</Link></li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-md-4 blogs__Banner-right">
                         <h4>Subscribe</h4>
                         <p className="pt-3">Enter Your email address to subscribe and receive notifications.</p>
                         <form onSubmit={handleSubmit}>
                            <div className="input_m2 py-3">
                                <label>Email ID <span className="star_imp">*</span></label>
                                <input value={values.email} onChange={handleChange} onBlur={handleBlur} type="text" name="email"/>
                                { errors.email && touched.email ? <p>{errors.email}</p> : null}
                            </div>
                            <div className="text-center">
                               <button  type="submit" className="btn_sub">Subscribe</button>
                            </div>
                         </form>
                    </div> */}

<div className="col-md-4">
                    
                    <div className="rounded-3 p-4  form_m2 bg_m8">
                    <h5 className="text-center text-uppercase fw-semibold">Subscribe</h5>
                         <p className="pt-3 text-center">Enter Your email address to subscribe and receive notifications.</p>
                           
                         
                            <form onSubmit={handleSubmit}>
                            <div className="input_m2 py-3">
                                <label>Email ID <span className="star_imp">*</span></label>
                                <input value={values.email} onChange={handleChange} onBlur={handleBlur} type="text" name="email"/>
                                { errors.email && touched.email ? <p>{errors.email}</p> : null}
                            </div>
                            <div className="text-center">
                               <button  type="submit" className="btn btn_m5  btn btn-outline-success">Subscribe</button>
                            </div>
                         </form>
                            
                    
                    </div>
                </div>


                </div>
            </div>
         
            <div className="container py-5">
            <div className="row">
                    {
                        blogData.map((eachItem) => (
                                <div className="col-md-4 cards mb-4"  key={eachItem.id}>
                                    <div className="card_m6">
                                 <img src={eachItem.img} alt="Card Image" className="w-100"/>
                                 <h5>{eachItem.title}</h5>
                                 {/* <ul className="list_m10">
                                    <li><a href="#">{eachItem.time}</a></li>
                                    <li>|</li>
                                    <li><a href="#">{eachItem.comment}</a></li>
                                 </ul> */}
                                 <p className="c4">{eachItem.text}</p>
                                 <button className="btn_m3" ><a  href="/exploreview">{eachItem.button}</a></button>
                                </div>
                            </div>
                        ))
                    }
                    
            </div>
        </div>


        </div>
    )
};

export default Blogs;