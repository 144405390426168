import React from "react";
import Routers from "../../routers/Routers";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
 import { useEffect } from 'react';

const Layout = () => {
    // function ScrollToTop({ history }) {
    //     useEffect(() => {
    //       const unlisten = history.listen(() => {
    //         window.scrollTo(0, 0);
    //       });
    //       return () => {
    //         unlisten();
    //       }
    //     }, []);
      
    //     return (null);
    //   }
    return (
        <div>
          
            <Header/>
            <div>
            {/* <ScrollToTop /> */}
                <Routers/>
            </div>
            <Footer/>
        </div>
    )
};

export default Layout;