import { Image } from "react-grid-gallery";
// Event 1 start here
import event1 from "../assets/images/amis/events/event1/event1.JPG";
import event2 from "../assets/images/amis/events/event1/event2.JPG";
import event3 from "../assets/images/amis/events/event1/event3.JPG";
import event4 from "../assets/images/amis/events/event1/event4.JPG";
import event5 from "../assets/images/amis/events/event1/event5.JPG";
import event6 from "../assets/images/amis/events/event1/event6.JPG";
import event7 from "../assets/images/amis/events/event1/event7.JPG";
import event8 from "../assets/images/amis/events/event1/event8.JPG";
import event9 from "../assets/images/amis/events/event1/event9.JPG";
import event10 from "../assets/images/amis/events/event1/event10.JPG";
import event11 from "../assets/images/amis/events/event1/event11.JPG";
import event12 from "../assets/images/amis/events/event1/event12.JPG";
import event13 from "../assets/images/amis/events/event1/event13.JPG";
import event14 from "../assets/images/amis/events/event1/event14.JPG";
import event15 from "../assets/images/amis/events/event1/event15.JPG";
import event16 from "../assets/images/amis/events/event1/event16.JPG";
import event17 from "../assets/images/amis/events/event1/event17.JPG";
import event18 from "../assets/images/amis/events/event1/event18.JPG";
import event19 from "../assets/images/amis/events/event1/event19.JPG";
import event20 from "../assets/images/amis/events/event1/event20.JPG";
import event21 from "../assets/images/amis/events/event1/event21.JPG";
import event22 from "../assets/images/amis/events/event1/event22.JPG";
import event23 from "../assets/images/amis/events/event1/event23.JPG";
import event24 from "../assets/images/amis/events/event1/event24.JPG";
import event25 from "../assets/images/amis/events/event1/event25.JPG";
import event26 from "../assets/images/amis/events/event1/event26.JPG";
import event27 from "../assets/images/amis/events/event1/event27.JPG";
import event28 from "../assets/images/amis/events/event1/event28.JPG";
// Event 1 End here

// Event 2 Start here
import event2_1 from "../assets/images/amis/events/2023/event1/event(1).jpg";
import event2_2 from "../assets/images/amis/events/2023/event1/event(2).jpg";
import event2_3 from "../assets/images/amis/events/2023/event1/event(3).jpg";
import event2_4 from "../assets/images/amis/events/2023/event1/event(4).jpg";
import event2_5 from "../assets/images/amis/events/2023/event1/event(5).JPG";
import event2_6 from "../assets/images/amis/events/2023/event1/event(6).JPG";
import event2_7 from "../assets/images/amis/events/2023/event1/event(7).JPG";
import event2_8 from "../assets/images/amis/events/2023/event1/event(8).JPG";
import event2_9 from "../assets/images/amis/events/2023/event1/event(9).JPG";
import event2_10 from "../assets/images/amis/events/2023/event1/event(10).JPG";
import event2_11 from "../assets/images/amis/events/2023/event1/event(11).jpg";
import event2_12 from "../assets/images/amis/events/2023/event1/event(12).jpg";
import event2_13 from "../assets/images/amis/events/2023/event1/event(13).jpg";
import event2_14 from "../assets/images/amis/events/2023/event1/event(14).jpg";
// import event2_15 from "../assets/images/amis/events/2023event1/event(15).jpg";
// import event2_16 from "../assets/images/amis/events/2023event1/event(16).jpg";
// import event2_17 from "../assets/images/amis/events/2023event1/event(17).jpg";
// import event2_18 from "../assets/images/amis/events/event2/event18.jpg";
// import event2_19 from "../assets/images/amis/events/event2/event19.jpg";
// import event2_20 from "../assets/images/amis/events/event2/event20.jpg";
// import event2_21 from "../assets/images/amis/events/event2/event21.jpg";
// import event2_22 from "../assets/images/amis/events/event2/event22.jpg";
// import event2_23 from "../assets/images/amis/events/event2/event23.jpg";
// import event2_24 from "../assets/images/amis/events/event2/event24.jpg";
// import event2_25 from "../assets/images/amis/events/event2/event25.jpg";
// import event2_26 from "../assets/images/amis/events/event2/event26.jpg";
// import event2_27 from "../assets/images/amis/events/event2/event27.jpg";
// import event2_28 from "../assets/images/amis/events/event2/event28.jpg";
// import event2_29 from "../assets/images/amis/events/event2/event29.jpg";
// import event2_30 from "../assets/images/amis/events/event2/event30.jpg";
// import event2_31 from "../assets/images/amis/events/event2/event31.jpg";
// import event2_32 from "../assets/images/amis/events/event2/event32.jpg";
// import event2_33 from "../assets/images/amis/events/event2/event33.jpg";
// import event2_34 from "../assets/images/amis/events/event2/event34.jpg";
// import event2_35 from "../assets/images/amis/events/event2/event35.jpg";
// import event2_36 from "../assets/images/amis/events/event2/event36.jpg";
// import event2_37 from "../assets/images/amis/events/event2/event37.jpg";
// import event2_38 from "../assets/images/amis/events/event2/event38.jpg";
// import event2_39 from "../assets/images/amis/events/event2/event39.jpg";
// import event2_40 from "../assets/images/amis/events/event2/event40.jpg";
// import event2_41 from "../assets/images/amis/events/event2/event41.jpg";
// import event2_42 from "../assets/images/amis/events/event2/event42.jpg";
// import event2_43 from "../assets/images/amis/events/event2/event43.jpg";
// import event2_44 from "../assets/images/amis/events/event2/event44.jpg";
// import event2_45 from "../assets/images/amis/events/event2/event45.jpg";
// import event2_46 from "../assets/images/amis/events/event2/event46.jpg";
// import event2_47 from "../assets/images/amis/events/event2/event47.jpg";
// import event2_48 from "../assets/images/amis/events/event2/event48.jpg";
// import event2_49 from "../assets/images/amis/events/event2/event49.jpg";
// import event2_50 from "../assets/images/amis/events/event2/event50.jpg";

// import event2_51 from "../assets/images/amis/events/event2/event51.jpg";
// import event2_52 from "../assets/images/amis/events/event2/event52.jpg";
// import event2_53 from "../assets/images/amis/events/event2/event53.jpg";
// import event2_54 from "../assets/images/amis/events/event2/event54.jpg";
// import event2_55 from "../assets/images/amis/events/event2/event55.jpg";
// import event2_56 from "../assets/images/amis/events/event2/event56.jpg";
// import event2_57 from "../assets/images/amis/events/event2/event57.jpg";
// import event2_58 from "../assets/images/amis/events/event2/event58.jpg";
// import event2_59 from "../assets/images/amis/events/event2/event49.jpg";
// import event2_60 from "../assets/images/amis/events/event2/event60.jpg";

// import event2_61 from "../assets/images/amis/events/event2/event61.jpg";
// import event2_62 from "../assets/images/amis/events/event2/event62.jpg";
// import event2_63 from "../assets/images/amis/events/event2/event63.jpg";
// import event2_64 from "../assets/images/amis/events/event2/event64.jpg";
// import event2_65 from "../assets/images/amis/events/event2/event65.jpg";
// import event2_66 from "../assets/images/amis/events/event2/event66.jpg";
// import event2_67 from "../assets/images/amis/events/event2/event67.jpg";
// import event2_68 from "../assets/images/amis/events/event2/event68.jpg";
 
// Event 2 End here




// Event 3 Start here
import event3_1 from "../assets/images/amis/events/event3/event1.jpg";
import event3_2 from "../assets/images/amis/events/event3/event2.jpg";
import event3_3 from "../assets/images/amis/events/event3/event3.jpg";
import event3_4 from "../assets/images/amis/events/event3/event4.jpg";
import event3_5 from "../assets/images/amis/events/event3/event5.jpg";
import event3_6 from "../assets/images/amis/events/event3/event6.jpg";
import event3_7 from "../assets/images/amis/events/event3/event7.jpg";
import event3_8 from "../assets/images/amis/events/event3/event8.jpg";
import event3_9 from "../assets/images/amis/events/event3/event9.jpg";
import event3_10 from "../assets/images/amis/events/event3/event10.jpg";
import event3_11 from "../assets/images/amis/events/event3/event11.jpg";
import event3_12 from "../assets/images/amis/events/event3/event12.jpg";
import event3_13 from "../assets/images/amis/events/event3/event13.jpg";
import event3_14 from "../assets/images/amis/events/event3/event14.jpg";
import event3_15 from "../assets/images/amis/events/event3/event15.jpg";
import event3_16 from "../assets/images/amis/events/event3/event16.jpg";
import event3_17 from "../assets/images/amis/events/event3/event17.jpg";
import event3_18 from "../assets/images/amis/events/event3/event18.jpg";
import event3_19 from "../assets/images/amis/events/event3/event19.jpg";
import event3_20 from "../assets/images/amis/events/event3/event20.jpg";
import event3_21 from "../assets/images/amis/events/event3/event21.jpg";
import event3_22 from "../assets/images/amis/events/event3/event22.jpg";
import event3_23 from "../assets/images/amis/events/event3/event23.jpg";
import event3_24 from "../assets/images/amis/events/event3/event24.jpg";
import event3_25 from "../assets/images/amis/events/event3/event25.jpg";
import event3_26 from "../assets/images/amis/events/event3/event26.jpg";
import event3_27 from "../assets/images/amis/events/event3/event27.jpg";
import event3_28 from "../assets/images/amis/events/event3/event28.jpg";
import event3_29 from "../assets/images/amis/events/event3/event29.jpg";
import event3_30 from "../assets/images/amis/events/event3/event30.jpg";
import event3_31 from "../assets/images/amis/events/event3/event31.jpg";
import event3_32 from "../assets/images/amis/events/event3/event32.jpg";
import event3_33 from "../assets/images/amis/events/event3/event33.jpg";
import event3_34 from "../assets/images/amis/events/event3/event34.jpg";
import event3_35 from "../assets/images/amis/events/event3/event35.jpg";
import event3_36 from "../assets/images/amis/events/event3/event36.jpg";
import event3_37 from "../assets/images/amis/events/event3/event37.jpg";
import event3_38 from "../assets/images/amis/events/event3/event38.jpg";
import event3_39 from "../assets/images/amis/events/event3/event39.jpg";
import event3_40 from "../assets/images/amis/events/event3/event40.jpg";
import event3_41 from "../assets/images/amis/events/event3/event41.jpg";
import event3_42 from "../assets/images/amis/events/event3/event42.jpg";
import event3_43 from "../assets/images/amis/events/event3/event43.jpg";
import event3_44 from "../assets/images/amis/events/event3/event44.jpg";
import event3_45 from "../assets/images/amis/events/event3/event45.jpg";
import event3_46 from "../assets/images/amis/events/event3/event46.jpg";
import event3_47 from "../assets/images/amis/events/event3/event47.jpg";
import event3_48 from "../assets/images/amis/events/event3/event48.jpg";
import event3_49 from "../assets/images/amis/events/event3/event49.jpg";
import event3_50 from "../assets/images/amis/events/event3/event50.jpg";

import event3_51 from "../assets/images/amis/events/event3/event51.jpg";
import event3_52 from "../assets/images/amis/events/event3/event52.jpg";
import event3_53 from "../assets/images/amis/events/event3/event53.jpg";
import event3_54 from "../assets/images/amis/events/event3/event54.jpg";
import event3_55 from "../assets/images/amis/events/event3/event55.jpg";
import event3_56 from "../assets/images/amis/events/event3/event56.jpg";
import event3_57 from "../assets/images/amis/events/event3/event57.jpg";
import event3_58 from "../assets/images/amis/events/event3/event58.jpg";
import event3_59 from "../assets/images/amis/events/event3/event49.jpg";
import event3_60 from "../assets/images/amis/events/event3/event60.jpg";

import event3_61 from "../assets/images/amis/events/event3/event61.jpg";
import event3_62 from "../assets/images/amis/events/event3/event62.jpg";
import event3_63 from "../assets/images/amis/events/event3/event63.jpg";
import event3_64 from "../assets/images/amis/events/event3/event64.jpg";
import event3_65 from "../assets/images/amis/events/event3/event65.jpg";
import event3_66 from "../assets/images/amis/events/event3/event66.jpg";
import event3_67 from "../assets/images/amis/events/event3/event67.jpg";
import event3_68 from "../assets/images/amis/events/event3/event68.jpg";
 
// Event 3 End here


// Event 4 Start here
import event4_1 from "../assets/images/amis/events/2006-hyd/event-(1).jpg";
import event4_2 from "../assets/images/amis/events/2006-hyd/event-(2).jpg";
import event4_3 from "../assets/images/amis/events/2006-hyd/event-(3).jpg";
import event4_4 from "../assets/images/amis/events/2006-hyd/event-(4).jpg";
// Event 4 End here

// Event 4 Start here
import event5_1 from "../assets/images/amis/events/2023/event3/event(1).jpg";
import event5_2 from "../assets/images/amis/events/2023/event3/event(2).jpg";
import event5_3 from "../assets/images/amis/events/2023/event3/event(3).jpg";
import event5_4 from "../assets/images/amis/events/2023/event3/event(4).jpg";
import event5_5 from "../assets/images/amis/events/2023/event3/event(5).jpg";
import event5_6 from "../assets/images/amis/events/2023/event3/event(6).jpg";
import event5_7 from "../assets/images/amis/events/2023/event3/event(7).jpg";
import event5_8 from "../assets/images/amis/events/2023/event3/event(8).jpg";
// Event 4 End here


// Event 4 Start here
import event6_1 from "../assets/images/amis/events/2007-kkd/event-(1).jpg";
import event6_2 from "../assets/images/amis/events/2007-kkd/event-(2).jpg";
import event6_3 from "../assets/images/amis/events/2007-kkd/event-(3).jpg";
import event6_4 from "../assets/images/amis/events/2007-kkd/event-(4).jpg";
import event6_5 from "../assets/images/amis/events/2007-kkd/event-(5).jpg";
// Event 4 End here

// Event 4 Start here
import event7_1 from "../assets/images/amis/events/2009/event-(1).jpg";
import event7_2 from "../assets/images/amis/events/2009/event-(2).jpg";
import event7_3 from "../assets/images/amis/events/2009/event-(3).jpg";
import event7_4 from "../assets/images/amis/events/2009/event-(4).jpg";
import event7_5 from "../assets/images/amis/events/2009/event-(5).jpg";
// Event 4 End here


// Event 7 Start here
import event8_1 from "../assets/images/amis/events/2013/event(1).JPG";
import event8_2 from "../assets/images/amis/events/2013/event(2).JPG";
import event8_3 from "../assets/images/amis/events/2013/event(3).JPG";
// import event8_4 from "../assets/images/amis/events/msn_charaties/event4.jpg";
// import event8_5 from "../assets/images/amis/events/msn_charaties/event5.jpg";
// import event8_6 from "../assets/images/amis/events/msn_charaties/event6.jpg";
// import event8_7 from "../assets/images/amis/events/msn_charaties/event7.jpg";
// import event8_8 from "../assets/images/amis/events/msn_charaties/event8.jpg";
// import event8_9 from "../assets/images/amis/events/msn_charaties/event9.jpg";
// import event8_10 from "../assets/images/amis/events/msn_charaties/event10.jpg";
// import event8_11 from "../assets/images/amis/events/msn_charaties/event11.jpg";
// import event8_12 from "../assets/images/amis/events/msn_charaties/event12.jpg";
// import event8_13 from "../assets/images/amis/events/msn_charaties/event13.jpg";
// import event8_14 from "../assets/images/amis/events/msn_charaties/event14.jpg";
// import event8_15 from "../assets/images/amis/events/msn_charaties/event15.jpg";
// import event8_16 from "../assets/images/amis/events/msn_charaties/event16.jpg";
// import event8_17 from "../assets/images/amis/events/msn_charaties/event17.jpg";
// import event8_18 from "../assets/images/amis/events/msn_charaties/event18.jpg";
// import event8_19 from "../assets/images/amis/events/msn_charaties/event19.jpg";
// Event 7 End here


// Event 9 Start here
import event9_1 from "../assets/images/amis/events/narayana_1/event1.jpg";
import event9_2 from "../assets/images/amis/events/narayana_1/event2.jpg";
import event9_3 from "../assets/images/amis/events/narayana_1/event3.jpg";
 
// Event 9 End here

// Event 10 Start here
import event10_1 from "../assets/images/amis/events/pressclub_hyd/event1.jpg";
import event10_2 from "../assets/images/amis/events/pressclub_hyd/event2.jpg";
import event10_3 from "../assets/images/amis/events/pressclub_hyd/event3.jpg";
// Event 10 End here

// Event 11 Start here
import event11_1 from "../assets/images/amis/events/rajasri/event1.jpg";
import event11_2 from "../assets/images/amis/events/rajasri/event2.jpg";
import event11_3 from "../assets/images/amis/events/rajasri/event3.jpg";
import event11_4 from "../assets/images/amis/events/rajasri/event4.jpg";
// Event 11 End here




// Event 12 Start here
import event12_1 from "../assets/images/amis/events/rajhamandruy/event1.JPG";
import event12_2 from "../assets/images/amis/events/rajhamandruy/event2.JPG";
import event12_3 from "../assets/images/amis/events/rajhamandruy/event3.JPG";
import event12_4 from "../assets/images/amis/events/rajhamandruy/event4.JPG";
import event12_5 from "../assets/images/amis/events/rajhamandruy/event5.JPG";
import event12_6 from "../assets/images/amis/events/rajhamandruy/event6.JPG";
import event12_7 from "../assets/images/amis/events/rajhamandruy/event7.JPG";
import event12_8 from "../assets/images/amis/events/rajhamandruy/event8.JPG";
import event12_9 from "../assets/images/amis/events/rajhamandruy/event9.JPG";
import event12_10 from "../assets/images/amis/events/rajhamandruy/event10.JPG";
import event12_11 from "../assets/images/amis/events/rajhamandruy/event11.JPG";
import event12_12 from "../assets/images/amis/events/rajhamandruy/event12.JPG";
import event12_13 from "../assets/images/amis/events/rajhamandruy/event13.JPG";
import event12_14 from "../assets/images/amis/events/rajhamandruy/event14.JPG";
import event12_15 from "../assets/images/amis/events/rajhamandruy/event15.JPG";
import event12_16 from "../assets/images/amis/events/rajhamandruy/event16.JPG";
import event12_17 from "../assets/images/amis/events/rajhamandruy/event17.JPG";
import event12_18 from "../assets/images/amis/events/rajhamandruy/event18.JPG";
import event12_19 from "../assets/images/amis/events/rajhamandruy/event19.JPG";
import event12_20 from "../assets/images/amis/events/rajhamandruy/event20.JPG";
import event12_21 from "../assets/images/amis/events/rajhamandruy/event21.JPG";
import event12_22 from "../assets/images/amis/events/rajhamandruy/event22.JPG";
import event12_23 from "../assets/images/amis/events/rajhamandruy/event23.JPG";
import event12_24 from "../assets/images/amis/events/rajhamandruy/event24.JPG";
import event12_25 from "../assets/images/amis/events/rajhamandruy/event25.JPG";
import event12_26 from "../assets/images/amis/events/rajhamandruy/event26.JPG";
import event12_27 from "../assets/images/amis/events/rajhamandruy/event27.JPG";
import event12_28 from "../assets/images/amis/events/rajhamandruy/event28.jpg";
import event12_29 from "../assets/images/amis/events/rajhamandruy/event29.jpg";
import event12_30 from "../assets/images/amis/events/rajhamandruy/event30.jpg";
import event12_31 from "../assets/images/amis/events/rajhamandruy/event31.jpg";
import event12_32 from "../assets/images/amis/events/rajhamandruy/event32.jpg";
import event12_33 from "../assets/images/amis/events/rajhamandruy/event33.jpg";
import event12_34 from "../assets/images/amis/events/rajhamandruy/event34.jpg";
import event12_35 from "../assets/images/amis/events/rajhamandruy/event35.jpg";
import event12_36 from "../assets/images/amis/events/rajhamandruy/event36.jpg";
import event12_37 from "../assets/images/amis/events/rajhamandruy/event37.jpg";
import event12_38 from "../assets/images/amis/events/rajhamandruy/event38.jpg";
import event12_39 from "../assets/images/amis/events/rajhamandruy/event39.jpg";
import event12_40 from "../assets/images/amis/events/rajhamandruy/event40.jpg";
import event12_41 from "../assets/images/amis/events/rajhamandruy/event41.jpg";
import event12_42 from "../assets/images/amis/events/rajhamandruy/event42.jpg";
import event12_43 from "../assets/images/amis/events/rajhamandruy/event43.jpg";
import event12_44 from "../assets/images/amis/events/rajhamandruy/event44.jpg";
import event12_45 from "../assets/images/amis/events/rajhamandruy/event45.jpg";
import event12_46 from "../assets/images/amis/events/rajhamandruy/event46.jpg";
import event12_47 from "../assets/images/amis/events/rajhamandruy/event47.jpg";
import event12_48 from "../assets/images/amis/events/rajhamandruy/event48.jpg";
import event12_49 from "../assets/images/amis/events/rajhamandruy/event49.jpg";
import event12_50 from "../assets/images/amis/events/rajhamandruy/event50.jpg";

import event12_51 from "../assets/images/amis/events/rajhamandruy/event51.jpg";
import event12_52 from "../assets/images/amis/events/rajhamandruy/event52.jpg";
import event12_53 from "../assets/images/amis/events/rajhamandruy/event53.jpg";
import event12_54 from "../assets/images/amis/events/rajhamandruy/event54.jpg";
import event12_55 from "../assets/images/amis/events/rajhamandruy/event55.jpg";
import event12_56 from "../assets/images/amis/events/rajhamandruy/event56.jpg";
import event12_57 from "../assets/images/amis/events/rajhamandruy/event57.jpg";
import event12_58 from "../assets/images/amis/events/rajhamandruy/event58.jpg";
import event12_59 from "../assets/images/amis/events/rajhamandruy/event49.jpg";
import event12_60 from "../assets/images/amis/events/rajhamandruy/event60.jpg";

import event12_61 from "../assets/images/amis/events/rajhamandruy/event61.jpg";
import event12_62 from "../assets/images/amis/events/rajhamandruy/event62.jpg";
import event12_63 from "../assets/images/amis/events/rajhamandruy/event63.jpg";
import event12_64 from "../assets/images/amis/events/rajhamandruy/event64.jpg";
import event12_65 from "../assets/images/amis/events/rajhamandruy/event65.jpg";
import event12_66 from "../assets/images/amis/events/rajhamandruy/event66.jpg";
import event12_67 from "../assets/images/amis/events/rajhamandruy/event67.jpg";
import event12_68 from "../assets/images/amis/events/rajhamandruy/event68.jpg";
import event12_69 from "../assets/images/amis/events/rajhamandruy/event69.jpg";
import event12_70 from "../assets/images/amis/events/rajhamandruy/event70.jpg";

import event12_71 from "../assets/images/amis/events/rajhamandruy/event71.jpg";
import event12_72 from "../assets/images/amis/events/rajhamandruy/event72.jpg";
import event12_73 from "../assets/images/amis/events/rajhamandruy/event73.jpg";
import event12_74 from "../assets/images/amis/events/rajhamandruy/event74.jpg";
import event12_75 from "../assets/images/amis/events/rajhamandruy/event75.jpg";
import event12_76 from "../assets/images/amis/events/rajhamandruy/event76.jpg";
import event12_77 from "../assets/images/amis/events/rajhamandruy/event77.jpg";
import event12_78 from "../assets/images/amis/events/rajhamandruy/event78.jpg";
import event12_79 from "../assets/images/amis/events/rajhamandruy/event79.jpg";
import event12_80 from "../assets/images/amis/events/rajhamandruy/event80.jpg";


import event12_81 from "../assets/images/amis/events/rajhamandruy/event81.jpg";
import event12_82 from "../assets/images/amis/events/rajhamandruy/event82.jpg";
import event12_83 from "../assets/images/amis/events/rajhamandruy/event83.jpg";
import event12_84 from "../assets/images/amis/events/rajhamandruy/event84.jpg";
import event12_85 from "../assets/images/amis/events/rajhamandruy/event85.jpg";
import event12_86 from "../assets/images/amis/events/rajhamandruy/event86.jpg";
import event12_87 from "../assets/images/amis/events/rajhamandruy/event87.jpg";
import event12_88 from "../assets/images/amis/events/rajhamandruy/event88.jpg";
import event12_89 from "../assets/images/amis/events/rajhamandruy/event89.jpg";
import event12_90 from "../assets/images/amis/events/rajhamandruy/event90.jpg";


import event12_91 from "../assets/images/amis/events/rajhamandruy/event91.jpg";
import event12_92 from "../assets/images/amis/events/rajhamandruy/event92.jpg";
import event12_93 from "../assets/images/amis/events/rajhamandruy/event93.jpg";
import event12_94 from "../assets/images/amis/events/rajhamandruy/event94.jpg";
import event12_95 from "../assets/images/amis/events/rajhamandruy/event95.jpg";
import event12_96 from "../assets/images/amis/events/rajhamandruy/event96.jpg";
import event12_97 from "../assets/images/amis/events/rajhamandruy/event97.jpg";
import event12_98 from "../assets/images/amis/events/rajhamandruy/event98.jpg";
import event12_99 from "../assets/images/amis/events/rajhamandruy/event99.jpg";
import event12_100 from "../assets/images/amis/events/rajhamandruy/event100.jpg";

import event12_101 from "../assets/images/amis/events/rajhamandruy/event101.jpg";
import event12_102 from "../assets/images/amis/events/rajhamandruy/event102.jpg";
import event12_103 from "../assets/images/amis/events/rajhamandruy/event103.jpg";
import event12_104 from "../assets/images/amis/events/rajhamandruy/event104.jpg";
import event12_105 from "../assets/images/amis/events/rajhamandruy/event105.jpg";
import event12_106 from "../assets/images/amis/events/rajhamandruy/event106.jpg";
import event12_107 from "../assets/images/amis/events/rajhamandruy/event107.jpg";
import event12_108 from "../assets/images/amis/events/rajhamandruy/event108.jpg";
import event12_109 from "../assets/images/amis/events/rajhamandruy/event109.jpg";
import event12_110 from "../assets/images/amis/events/rajhamandruy/event110.jpg";
 
import event12_111 from "../assets/images/amis/events/rajhamandruy/event111.jpg";
import event12_112 from "../assets/images/amis/events/rajhamandruy/event112.jpg";
import event12_113 from "../assets/images/amis/events/rajhamandruy/event113.jpg";
import event12_114 from "../assets/images/amis/events/rajhamandruy/event114.JPG";
import event12_115 from "../assets/images/amis/events/rajhamandruy/event115.JPG";
import event12_116 from "../assets/images/amis/events/rajhamandruy/event116.JPG";
 
// Event 12 End here




// Event 12 Start here
// import event13_1 from "../assets/images/amis/events/ramachandra_puram/event-(1).JPG";
// import event13_2 from "../assets/images/amis/events/ramachandra_puram/event-(2).JPG";
// import event13_3 from "../assets/images/amis/events/ramachandra_puram/event-(3).JPG";
// import event13_4 from "../assets/images/amis/events/ramachandra_puram/event-(4).JPG";
// import event13_5 from "../assets/images/amis/events/ramachandra_puram/event-(5).JPG";
import event13_6 from "../assets/images/amis/events/ramachandra_puram/event-(6).jpg";
import event13_7 from "../assets/images/amis/events/ramachandra_puram/event-(7).jpg";
import event13_8 from "../assets/images/amis/events/ramachandra_puram/event-(8).jpg";
import event13_9 from "../assets/images/amis/events/ramachandra_puram/event-(9).jpg";
import event13_10 from "../assets/images/amis/events/ramachandra_puram/event-(10).jpg";
import event13_11 from "../assets/images/amis/events/ramachandra_puram/event-(11).jpg";
import event13_12 from "../assets/images/amis/events/ramachandra_puram/event-(12).jpg";
import event13_13 from "../assets/images/amis/events/ramachandra_puram/event-(13).jpg";
import event13_14 from "../assets/images/amis/events/ramachandra_puram/event-(14).jpg";
import event13_15 from "../assets/images/amis/events/ramachandra_puram/event-(15).jpg";
import event13_16 from "../assets/images/amis/events/ramachandra_puram/event-(16).jpg";
import event13_17 from "../assets/images/amis/events/ramachandra_puram/event-(17).jpg";
import event13_18 from "../assets/images/amis/events/ramachandra_puram/event-(18).jpg";
import event13_19 from "../assets/images/amis/events/ramachandra_puram/event-(19).jpg";
import event13_20 from "../assets/images/amis/events/ramachandra_puram/event-(20).jpg";
import event13_21 from "../assets/images/amis/events/ramachandra_puram/event-(21).jpg";
import event13_22 from "../assets/images/amis/events/ramachandra_puram/event-(22).jpg";
import event13_23 from "../assets/images/amis/events/ramachandra_puram/event-(23).jpg";
import event13_24 from "../assets/images/amis/events/ramachandra_puram/event-(24).jpg";
import event13_25 from "../assets/images/amis/events/ramachandra_puram/event-(25).jpg";
import event13_26 from "../assets/images/amis/events/ramachandra_puram/event-(26).jpg";
import event13_27 from "../assets/images/amis/events/ramachandra_puram/event-(27).jpg";
import event13_28 from "../assets/images/amis/events/ramachandra_puram/event-(28).jpg";
import event13_29 from "../assets/images/amis/events/ramachandra_puram/event-(29).jpg";
import event13_30 from "../assets/images/amis/events/ramachandra_puram/event-(30).jpg";
import event13_31 from "../assets/images/amis/events/ramachandra_puram/event-(31).jpg";
import event13_32 from "../assets/images/amis/events/ramachandra_puram/event-(32).jpg";
import event13_33 from "../assets/images/amis/events/ramachandra_puram/event-(33).jpg";
import event13_34 from "../assets/images/amis/events/ramachandra_puram/event-(34).jpg";
import event13_35 from "../assets/images/amis/events/ramachandra_puram/event-(35).jpg";
import event13_36 from "../assets/images/amis/events/ramachandra_puram/event-(36).jpg";
import event13_37 from "../assets/images/amis/events/ramachandra_puram/event-(37).jpg";
import event13_38 from "../assets/images/amis/events/ramachandra_puram/event-(38).jpg";
import event13_39 from "../assets/images/amis/events/ramachandra_puram/event-(39).jpg";
import event13_40 from "../assets/images/amis/events/ramachandra_puram/event-(40).jpg";
import event13_41 from "../assets/images/amis/events/ramachandra_puram/event-(41).jpg";
import event13_42 from "../assets/images/amis/events/ramachandra_puram/event-(42).jpg";
import event13_43 from "../assets/images/amis/events/ramachandra_puram/event-(43).jpg";
import event13_44 from "../assets/images/amis/events/ramachandra_puram/event-(44).jpg";
import event13_45 from "../assets/images/amis/events/ramachandra_puram/event-(45).jpg";
import event13_46 from "../assets/images/amis/events/ramachandra_puram/event-(46).jpg";
import event13_47 from "../assets/images/amis/events/ramachandra_puram/event-(47).jpg";
import event13_48 from "../assets/images/amis/events/ramachandra_puram/event-(48).jpg";
import event13_49 from "../assets/images/amis/events/ramachandra_puram/event-(49).jpg";
import event13_50 from "../assets/images/amis/events/ramachandra_puram/event-(50).jpg";

import event13_51 from "../assets/images/amis/events/ramachandra_puram/event-(51).jpg";
import event13_52 from "../assets/images/amis/events/ramachandra_puram/event-(52).jpg";
import event13_53 from "../assets/images/amis/events/ramachandra_puram/event-(53).jpg";
import event13_54 from "../assets/images/amis/events/ramachandra_puram/event-(54).jpg";
import event13_55 from "../assets/images/amis/events/ramachandra_puram/event-(55).jpg";
import event13_56 from "../assets/images/amis/events/ramachandra_puram/event-(56).jpg";
import event13_57 from "../assets/images/amis/events/ramachandra_puram/event-(57).jpg";
import event13_58 from "../assets/images/amis/events/ramachandra_puram/event-(58).jpg";
import event13_59 from "../assets/images/amis/events/ramachandra_puram/event-(49).jpg";
import event13_60 from "../assets/images/amis/events/ramachandra_puram/event-(60).jpg";

import event13_61 from "../assets/images/amis/events/ramachandra_puram/event-(61).jpg";
import event13_62 from "../assets/images/amis/events/ramachandra_puram/event-(62).jpg";
import event13_63 from "../assets/images/amis/events/ramachandra_puram/event-(63).jpg";
import event13_64 from "../assets/images/amis/events/ramachandra_puram/event-(64).jpg";
import event13_65 from "../assets/images/amis/events/ramachandra_puram/event-(65).jpg";
import event13_66 from "../assets/images/amis/events/ramachandra_puram/event-(66).jpg";
import event13_67 from "../assets/images/amis/events/ramachandra_puram/event-(67).jpg";
import event13_68 from "../assets/images/amis/events/ramachandra_puram/event-(68).jpg";
import event13_69 from "../assets/images/amis/events/ramachandra_puram/event-(69).jpg";
import event13_70 from "../assets/images/amis/events/ramachandra_puram/event-(70).jpg";

import event13_71 from "../assets/images/amis/events/ramachandra_puram/event-(71).jpg";
import event13_72 from "../assets/images/amis/events/ramachandra_puram/event-(72).jpg";
import event13_73 from "../assets/images/amis/events/ramachandra_puram/event-(73).jpg";
import event13_74 from "../assets/images/amis/events/ramachandra_puram/event-(74).jpg";
import event13_75 from "../assets/images/amis/events/ramachandra_puram/event-(75).jpg";
import event13_76 from "../assets/images/amis/events/ramachandra_puram/event-(76).jpg";
import event13_77 from "../assets/images/amis/events/ramachandra_puram/event-(77).jpg";
import event13_78 from "../assets/images/amis/events/ramachandra_puram/event-(78).jpg";
import event13_79 from "../assets/images/amis/events/ramachandra_puram/event-(79).jpg";
import event13_80 from "../assets/images/amis/events/ramachandra_puram/event-(80).jpg";


import event13_81 from "../assets/images/amis/events/ramachandra_puram/event-(81).jpg";
import event13_82 from "../assets/images/amis/events/ramachandra_puram/event-(82).jpg";
import event13_83 from "../assets/images/amis/events/ramachandra_puram/event-(83).jpg";
import event13_84 from "../assets/images/amis/events/ramachandra_puram/event-(84).jpg";
import event13_85 from "../assets/images/amis/events/ramachandra_puram/event-(85).jpg";
import event13_86 from "../assets/images/amis/events/ramachandra_puram/event-(86).jpg";
import event13_87 from "../assets/images/amis/events/ramachandra_puram/event-(87).jpg";
import event13_88 from "../assets/images/amis/events/ramachandra_puram/event-(88).jpg";
import event13_89 from "../assets/images/amis/events/ramachandra_puram/event-(89).jpg";
import event13_90 from "../assets/images/amis/events/ramachandra_puram/event-(90).jpg";


import event13_91 from "../assets/images/amis/events/ramachandra_puram/event-(91).jpg";
import event13_92 from "../assets/images/amis/events/ramachandra_puram/event-(92).jpg";
import event13_93 from "../assets/images/amis/events/ramachandra_puram/event-(93).jpg";
import event13_94 from "../assets/images/amis/events/ramachandra_puram/event-(94).jpg";
import event13_95 from "../assets/images/amis/events/ramachandra_puram/event-(95).jpg";
import event13_96 from "../assets/images/amis/events/ramachandra_puram/event-(96).jpg";
import event13_97 from "../assets/images/amis/events/ramachandra_puram/event-(97).jpg";
import event13_98 from "../assets/images/amis/events/ramachandra_puram/event-(98).jpg";
import event13_99 from "../assets/images/amis/events/ramachandra_puram/event-(99).jpg";
import event13_100 from "../assets/images/amis/events/ramachandra_puram/event-(100).jpg";

import event13_101 from "../assets/images/amis/events/ramachandra_puram/event-(101).jpg";
import event13_102 from "../assets/images/amis/events/ramachandra_puram/event-(102).jpg";
import event13_103 from "../assets/images/amis/events/ramachandra_puram/event-(103).jpg";
import event13_104 from "../assets/images/amis/events/ramachandra_puram/event-(104).jpg";
import event13_105 from "../assets/images/amis/events/ramachandra_puram/event-(105).jpg";
import event13_106 from "../assets/images/amis/events/ramachandra_puram/event-(106).jpg";
import event13_107 from "../assets/images/amis/events/ramachandra_puram/event-(107).jpg";
import event13_108 from "../assets/images/amis/events/ramachandra_puram/event-(108).jpg";
import event13_109 from "../assets/images/amis/events/ramachandra_puram/event-(109).jpg";
import event13_110 from "../assets/images/amis/events/ramachandra_puram/event-(110).jpg";
 
import event13_111 from "../assets/images/amis/events/ramachandra_puram/event-(111).jpg";
 
 
// Event 12 End here

// Event 14 Start here
 import event14_1 from "../assets/images/amis/events/wc_2/event-(1).jpg";
 import event14_2 from "../assets/images/amis/events/wc_2/event-(2).jpg";
 import event14_3 from "../assets/images/amis/events/wc_2/event-(3).jpg";
 import event14_4 from "../assets/images/amis/events/wc_2/event-(4).jpg";
 

// Event 14 End here


// Event 15 Start here
import event15_1 from "../assets/images/amis/events/wesley/event-(1).jpg";
import event15_2 from "../assets/images/amis/events/wesley/event-(2).jpg";
import event15_3 from "../assets/images/amis/events/wesley/event-(3).jpg";
import event15_4 from "../assets/images/amis/events/wesley/event-(4).jpg";
import event15_5 from "../assets/images/amis/events/wesley/event-(5).jpg";
import event15_6 from "../assets/images/amis/events/wesley/event-(6).jpg";
import event15_7 from "../assets/images/amis/events/wesley/event-(7).jpg";
// Event 15 End here


// Event 16 Start here
import event16_1 from "../assets/images/amis/events/2023/event4/event(1).jpg";
import event16_2 from "../assets/images/amis/events/2023/event4/event(2).jpg";
import event16_3 from "../assets/images/amis/events/2023/event4/event(3).jpg";
import event16_4 from "../assets/images/amis/events/2023/event4/event(4).jpg";

import event16_5 from "../assets/images/amis/events/2023/event4/event(5).jpg";
import event16_6 from "../assets/images/amis/events/2023/event4/event(6).jpg";
import event16_7 from "../assets/images/amis/events/2023/event4/event(7).jpg";
import event16_8 from "../assets/images/amis/events/2023/event4/event(8).jpg";
 
// Event 16 End here


export interface CustomImage extends Image {original: string;}
export interface CustomImage1 extends Image {original: string;}
export interface CustomImage2 extends Image {original: string;}
export interface CustomImage3 extends Image {original: string;}
export interface CustomImage4 extends Image {original: string;}
export interface CustomImage5 extends Image {original: string;}
export interface CustomImage6 extends Image {original: string;}
export interface CustomImage7 extends Image {original: string;}
export interface CustomImage8 extends Image {original: string;}
export interface CustomImage9 extends Image {original: string;}
export interface CustomImage10 extends Image {original: string;}
export interface CustomImage11 extends Image {original: string;}
export interface CustomImage12 extends Image {original: string;}
export interface CustomImage13 extends Image {original: string;}
export interface CustomImage14 extends Image {original: string;}
export interface CustomImage15 extends Image {original: string;}

export const images: CustomImage[] = [
  {
    src: event27,
    original: event27,
    width: 320,
    height: 183,
  },
  {
    src: event5,
    original: event5,
    width: 320,
    height: 183,
  },
  {
    src: event23,
    original: event23,
    width: 320,
    height: 183,
  },
  {
    src: event16,
    original: event16,
    width: 320,
    height: 183,
  },
  // {
  //   src: event1,
  //   original: event1,
  //   width: 320,
  //   height: 183,
  // },
  // {
  //   src: event2,
  //   original: event2,
  //   width: 320,
  //   height: 183,
  // },
  // {
  //   src: event3,
  //   original: event3,
  //   width: 320,
  //   height: 183,
  // },
  // {
  //   src: event4,
  //   original: event4,
  //   width: 320,
  //   height: 183,
  // },

  // {
  //   src: event6,
  //   original: event6,
  //   width: 320,
  //   height: 183,
  // },
  // {
  //   src: event7,
  //   original: event7,
  //   width: 320,
  //   height: 183,
  // },
  // {
  //   src: event8,
  //   original: event8,
  //   width: 320,
  //   height: 183,
  // },
  // {
  //   src: event9,
  //   original: event9,
  //   width: 320,
  //   height: 183,
  // },
  // {
  //   src: event10,
  //   original: event10,
  //   width: 320,
  //   height: 183,
  // },
 
  ////// 11th images start
//   {
//    src: event11,
//    original: event11,
//    width: 320,
//    height: 183,
//  },
//  {
//    src: event12,
//    original: event12,
//    width: 320,
//    height: 183,
//  },
//  {
//    src: event13,
//    original: event13,
//    width: 320,
//    height: 183,
//  },
//  {
//    src: event14,
//    original: event14,
//    width: 320,
//    height: 183,
//  },
//  {
//    src: event15,
//    original: event15,
//    width: 320,
//    height: 183,
//  },

//  {
//    src: event17,
//    original: event17,
//    width: 320,
//    height: 183,
//  },
//  {
//    src: event18,
//    original: event18,
//    width: 320,
//    height: 183,
//  },
//  {
//    src: event19,
//    original: event19,
//    width: 320,
//    height: 183,
//  },
//  {
//    src: event20,
//    original: event20,
//    width: 320,
//    height: 183,
//  },

  ////// 21th images start
//   {
//    src: event21,
//    original: event21,
//    width: 320,
//    height: 183,
//  },
//  {
//    src: event22,
//    original: event22,
//    width: 320,
//    height: 183,
//  },

//  {
//    src: event24,
//    original: event24,
//    width: 320,
//    height: 183,
//  },
//  {
//    src: event25,
//    original: event25,
//    width: 320,
//    height: 183,
//  },
//  {
//    src: event26,
//    original: event26,
//    width: 320,
//    height: 183,
//  },

//  {
//    src: event28,
//    original: event28,
//    width: 320,
//    height: 183,
//  },
  
 
];

// SECTION START 1
export const images1: CustomImage1[] = [
   {src: event2_1,original: event2_1, width: 320,height: 183},
   {src: event2_2,original: event2_2, width: 320,height: 183},
   {src: event2_3,original: event2_3, width: 320,height: 183},
   {src: event2_4,original: event2_4, width: 320,height: 183},
   {src: event2_5,original: event2_5, width: 320,height: 183},
   {src: event2_6,original: event2_6, width: 320,height: 183},
   {src: event2_7,original: event2_7, width: 320,height: 183},
   {src: event2_8,original: event2_8, width: 320,height: 183},
   {src: event2_9,original: event2_9, width: 320,height: 183},
   {src: event2_10,original: event2_10, width: 320,height: 183},
   {src: event2_11,original: event2_11, width: 320,height: 183},
   {src: event2_12,original: event2_12, width: 320,height: 183},
   {src: event2_13,original: event2_13, width: 320,height: 183},
   {src: event2_14,original: event2_14, width: 320,height: 183},
  //  {src: event2_15,original: event2_15, width: 320,height: 183},
  //  {src: event2_16,original: event2_16, width: 320,height: 183},
  //  {src: event2_17,original: event2_17, width: 320,height: 183},
  //  {src: event2_18,original: event2_18, width: 320,height: 183},
  //  {src: event2_19,original: event2_19, width: 320,height: 183},
  //  {src: event2_20,original: event2_20, width: 320,height: 183},
  //  {src: event2_21,original: event2_21, width: 320,height: 183},
  //  {src: event2_22,original: event2_22, width: 320,height: 183},
  //  {src: event2_23,original: event2_23, width: 320,height: 183},
  //  {src: event2_24,original: event2_24, width: 320,height: 183},
  //  {src: event2_25,original: event2_25, width: 320,height: 183},
  //  {src: event2_26,original: event2_26, width: 320,height: 183},
  //  {src: event2_27,original: event2_27, width: 320,height: 183},
  //  {src: event2_28,original: event2_28, width: 320,height: 183},
  //  {src: event2_29,original: event2_29, width: 320,height: 183},
  //  {src: event2_30,original: event2_30, width: 320,height: 183},
  //  {src: event2_31,original: event2_31, width: 320,height: 183},
  //  {src: event2_32,original: event2_32, width: 320,height: 183},
  //  {src: event2_33,original: event2_33, width: 320,height: 183},
  //  {src: event2_34,original: event2_34, width: 320,height: 183},
  //  {src: event2_35,original: event2_35, width: 320,height: 183},
  //  {src: event2_36,original: event2_36, width: 320,height: 183},
  //  {src: event2_37,original: event2_37, width: 320,height: 183},
  //  {src: event2_38,original: event2_38, width: 320,height: 183},
  //  {src: event2_39,original: event2_39, width: 320,height: 183},
  //  {src: event2_40,original: event2_40, width: 320,height: 183},
  //  {src: event2_41,original: event2_41, width: 320,height: 183},
  //  {src: event2_42,original: event2_42, width: 320,height: 183},
  //  {src: event2_43,original: event2_43, width: 320,height: 183},
  //  {src: event2_44,original: event2_44, width: 320,height: 183},
  //  {src: event2_45,original: event2_45, width: 320,height: 183},
  //  {src: event2_46,original: event2_46, width: 320,height: 183},
  //  {src: event2_47,original: event2_47, width: 320,height: 183},
  //  {src: event2_48,original: event2_48, width: 320,height: 183},
  //  {src: event2_49,original: event2_49, width: 320,height: 183},
  //  {src: event2_50,original: event2_50, width: 320,height: 183},
  //  {src: event2_51,original: event2_51, width: 320,height: 183},
  //  {src: event2_52,original: event2_52, width: 320,height: 183},
  //  {src: event2_53,original: event2_53, width: 320,height: 183},
  //  {src: event2_54,original: event2_54, width: 320,height: 183},
  //  {src: event2_55,original: event2_55, width: 320,height: 183},
  //  {src: event2_56,original: event2_56, width: 320,height: 183},
  //  {src: event2_57,original: event2_57, width: 320,height: 183},
  //  {src: event2_58,original: event2_58, width: 320,height: 183},
  //  {src: event2_59,original: event2_59, width: 320,height: 183},
  //  {src: event2_60,original: event2_60, width: 320,height: 183},
  //  {src: event2_61,original: event2_61, width: 320,height: 183},
  //  {src: event2_62,original: event2_62, width: 320,height: 183},
  //  {src: event2_63,original: event2_63, width: 320,height: 183},
  //  {src: event2_64,original: event2_64, width: 320,height: 183},
  //  {src: event2_65,original: event2_65, width: 320,height: 183},
  //  {src: event2_66,original: event2_66, width: 320,height: 183},
  //  {src: event2_67,original: event2_67, width: 320,height: 183},
  //  {src: event2_68,original: event2_68, width: 320,height: 183},
 
  
 ];
 
// SECTION END 1
// SECTION START 2
export const images2: CustomImage2[] = [
  //  {src: event3_1,original: event3_1, width: 320,height: 183},
  //  {src: event3_2,original: event3_2, width: 320,height: 183},
  //  {src: event3_3,original: event3_3, width: 320,height: 183},
  //  {src: event3_4,original: event3_4, width: 320,height: 183},
  //  {src: event3_5,original: event3_5, width: 320,height: 183},
  //  {src: event3_6,original: event3_6, width: 320,height: 183},
  //  {src: event3_7,original: event3_7, width: 320,height: 183},
  //  {src: event3_8,original: event3_8, width: 320,height: 183},
  //  {src: event3_9,original: event3_9, width: 320,height: 183},
  //  {src: event3_10,original: event3_10, width: 320,height: 183},
  //  {src: event3_11,original: event3_11, width: 320,height: 183},
  //  {src: event3_12,original: event3_12, width: 320,height: 183},
  //  {src: event3_13,original: event3_13, width: 320,height: 183},
   {src: event3_14,original: event3_14, width: 320,height: 183},
  //  {src: event3_15,original: event3_15, width: 320,height: 183},
  //  {src: event3_16,original: event3_16, width: 320,height: 183},
  //  {src: event3_17,original: event3_17, width: 320,height: 183},
  //  {src: event3_18,original: event3_18, width: 320,height: 183},
  //  {src: event3_19,original: event3_19, width: 320,height: 183},
  //  {src: event3_20,original: event3_20, width: 320,height: 183},
  //  {src: event3_21,original: event3_21, width: 320,height: 183},
  //  {src: event3_22,original: event3_22, width: 320,height: 183},
  //  {src: event3_23,original: event3_23, width: 320,height: 183},
  //  {src: event3_24,original: event3_24, width: 320,height: 183},
  //  {src: event3_25,original: event3_25, width: 320,height: 183},
  //  {src: event3_26,original: event3_26, width: 320,height: 183},
  //  {src: event3_27,original: event3_27, width: 320,height: 183},
  //  {src: event3_28,original: event3_28, width: 320,height: 183},
  //  {src: event3_29,original: event3_29, width: 320,height: 183},
  //  {src: event3_30,original: event3_30, width: 320,height: 183},
  //  {src: event3_31,original: event3_31, width: 320,height: 183},
  //  {src: event3_32,original: event3_32, width: 320,height: 183},
  //  {src: event3_33,original: event3_33, width: 320,height: 183},
   {src: event3_34,original: event3_34, width: 320,height: 183},
  //  {src: event3_35,original: event3_35, width: 320,height: 183},
  //  {src: event3_36,original: event3_36, width: 320,height: 183},
  //  {src: event3_37,original: event3_37, width: 320,height: 183},
  //  {src: event3_38,original: event3_38, width: 320,height: 183},
  //  {src: event3_39,original: event3_39, width: 320,height: 183},
  //  {src: event3_40,original: event3_40, width: 320,height: 183},
  //  {src: event3_41,original: event3_41, width: 320,height: 183},
  //  {src: event3_42,original: event3_42, width: 320,height: 183},
  //  {src: event3_43,original: event3_43, width: 320,height: 183},
  //  {src: event3_44,original: event3_44, width: 320,height: 183},
  //  {src: event3_45,original: event3_45, width: 320,height: 183},
  //  {src: event3_46,original: event3_46, width: 320,height: 183},
  //  {src: event3_47,original: event3_47, width: 320,height: 183},
  //  {src: event3_48,original: event3_48, width: 320,height: 183},
  //  {src: event3_49,original: event3_49, width: 320,height: 183},
  //  {src: event3_50,original: event3_50, width: 320,height: 183},
  //  {src: event3_51,original: event3_51, width: 320,height: 183},
  //  {src: event3_52,original: event3_52, width: 320,height: 183},
  //  {src: event3_53,original: event3_53, width: 320,height: 183},
  //  {src: event3_54,original: event3_54, width: 320,height: 183},
  //  {src: event3_55,original: event3_55, width: 320,height: 183},
  //  {src: event3_56,original: event3_56, width: 320,height: 183},
  //  {src: event3_57,original: event3_57, width: 320,height: 183},
  //  {src: event3_58,original: event3_58, width: 320,height: 183},
  //  {src: event3_59,original: event3_59, width: 320,height: 183},
  //  {src: event3_60,original: event3_60, width: 320,height: 183},
  //  {src: event3_61,original: event3_61, width: 320,height: 183},
  // {src: event3_62,original: event3_62, width: 320,height: 183},
  {src: event3_63,original: event3_63, width: 320,height: 183},
  // {src: event3_64,original: event3_64, width: 320,height: 183},
  //  {src: event3_65,original: event3_65, width: 320,height: 183},
  //  {src: event3_66,original: event3_66, width: 320,height: 183},
  //  {src: event3_67,original: event3_67, width: 320,height: 183},
  //  {src: event3_68,original: event3_68, width: 320,height: 183},
 
  
 ];
 
// SECTION END 2

// SECTION START 2
export const images3: CustomImage3[] = [
  {src: event4_1,original: event4_1, width: 320,height: 183},
  {src: event4_2,original: event4_2, width: 320,height: 183},
  {src: event4_3,original: event4_3, width: 320,height: 183},
  {src: event4_4,original: event4_4, width: 320,height: 183},
];

// SECTION END 2

// SECTION START 2
export const images4: CustomImage4[] = [
  {src: event5_1,original: event5_1, width: 320,height: 183},
  {src: event5_2,original: event5_2, width: 320,height: 183},
  {src: event5_3,original: event5_3, width: 320,height: 183},
  {src: event5_4,original: event5_4, width: 320,height: 183},
  {src: event5_5,original: event5_5, width: 320,height: 183},
  {src: event5_6,original: event5_6, width: 320,height: 183},
  {src: event5_7,original: event5_7, width: 320,height: 183},
  {src: event5_8,original: event5_8, width: 320,height: 183},
];

// SECTION END 2

// SECTION START 2
export const images5: CustomImage5[] = [
  {src: event6_1,original: event6_1, width: 320,height: 183},
  {src: event6_2,original: event6_2, width: 320,height: 183},
  {src: event6_3,original: event6_3, width: 320,height: 183},
  {src: event6_4,original: event6_4, width: 320,height: 183},
  {src: event6_5,original: event6_5, width: 320,height: 183},
];

// SECTION END 2


// SECTION START 2
export const images6: CustomImage6[] = [
  {src: event7_1,original: event7_1, width: 320,height: 183},
  {src: event7_2,original: event7_2, width: 320,height: 183},
  {src: event7_3,original: event7_3, width: 320,height: 183},
  {src: event7_4,original: event7_4, width: 320,height: 183},
  {src: event7_5,original: event7_5, width: 320,height: 183},
];

// SECTION END 2

// SECTION START 8
export const images7: CustomImage7[] = [
  {src: event8_1,original: event8_1, width: 320,height: 183},
  {src: event8_2,original: event8_2, width: 320,height: 183},
  {src: event8_3,original: event8_3, width: 320,height: 183},
  // {src: event8_4,original: event8_4, width: 320,height: 183},
  // {src: event8_5,original: event8_5, width: 320,height: 183},
  // {src: event8_6,original: event8_6, width: 320,height: 183},
  // {src: event8_7,original: event8_7, width: 320,height: 183},
  // {src: event8_8,original: event8_8, width: 320,height: 183},
  // {src: event8_9,original: event8_9, width: 320,height: 183},
  // {src: event8_10,original: event8_10, width: 320,height: 183},
  // {src: event8_11,original: event8_11, width: 320,height: 183},
  // {src: event8_12,original: event8_12, width: 320,height: 183},
  // {src: event8_13,original: event8_13, width: 320,height: 183},
  // {src: event8_14,original: event8_14, width: 320,height: 183},
  // {src: event8_15,original: event8_15, width: 320,height: 183},
  // {src: event8_16,original: event8_16, width: 320,height: 183},
  // {src: event8_17,original: event8_17, width: 320,height: 183},
  // {src: event8_18,original: event8_18, width: 320,height: 183},
  // {src: event8_19,original: event8_19, width: 320,height: 183},
];

// SECTION END 8



// SECTION START 8
export const images8: CustomImage8[] = [
  {src: event9_1,original: event9_1, width: 320,height: 183},
  {src: event9_2,original: event9_2, width: 320,height: 183},
  {src: event9_3,original: event9_3, width: 320,height: 183},
  
];

// SECTION END 8

// SECTION START 9
export const images9: CustomImage9[] = [
  {src: event10_1,original: event10_1, width: 320,height: 183},
  {src: event10_2,original: event10_2, width: 320,height: 183},
  {src: event10_3,original: event10_3, width: 320,height: 183},
  
];

// SECTION END 9

// SECTION START 10
export const images10: CustomImage10[] = [
  {src: event11_1,original: event11_1, width: 320,height: 183},
  {src: event11_2,original: event11_2, width: 320,height: 183},
  {src: event11_3,original: event11_3, width: 320,height: 183},
  {src: event11_4,original: event11_4, width: 320,height: 183},
];

// SECTION END 10



// SECTION START 11
export const images11: CustomImage11[] = [
  {src: event12_1,original: event12_1, width: 320,height: 183},
  {src: event12_2,original: event12_2, width: 320,height: 183},
  {src: event12_3,original: event12_3, width: 320,height: 183},
  {src: event12_4,original: event12_4, width: 320,height: 183},
  {src: event12_5,original: event12_5, width: 320,height: 183},
  {src: event12_6,original: event12_6, width: 320,height: 183},
  {src: event12_7,original: event12_7, width: 320,height: 183},
  {src: event12_8,original: event12_8, width: 320,height: 183},
  {src: event12_9,original: event12_9, width: 320,height: 183},
  {src: event12_10,original: event12_10, width: 320,height: 183},
  {src: event12_11,original: event12_11, width: 320,height: 183},
  {src: event12_12,original: event12_12, width: 320,height: 183},
  {src: event12_13,original: event12_13, width: 320,height: 183},
  {src: event12_14,original: event12_14, width: 320,height: 183},
  {src: event12_15,original: event12_15, width: 320,height: 183},
  {src: event12_16,original: event12_16, width: 320,height: 183},
  {src: event12_17,original: event12_17, width: 320,height: 183},
  {src: event12_18,original: event12_18, width: 320,height: 183},
  {src: event12_19,original: event12_19, width: 320,height: 183},
  {src: event12_20,original: event12_20, width: 320,height: 183},
  {src: event12_21,original: event12_21, width: 320,height: 183},
  {src: event12_22,original: event12_22, width: 320,height: 183},
  {src: event12_23,original: event12_23, width: 320,height: 183},
  {src: event12_24,original: event12_24, width: 320,height: 183},
  {src: event12_25,original: event12_25, width: 320,height: 183},
  {src: event12_26,original: event12_26, width: 320,height: 183},
  {src: event12_27,original: event12_27, width: 320,height: 183},
  {src: event12_28,original: event12_28, width: 320,height: 183},
  {src: event12_29,original: event12_29, width: 320,height: 183},
  {src: event12_30,original: event12_30, width: 320,height: 183},
  {src: event12_31,original: event12_31, width: 320,height: 183},
  {src: event12_32,original: event12_32, width: 320,height: 183},
  {src: event12_33,original: event12_33, width: 320,height: 183},
  {src: event12_34,original: event12_34, width: 320,height: 183},
  {src: event12_35,original: event12_35, width: 320,height: 183},
  {src: event12_36,original: event12_36, width: 320,height: 183},
  {src: event12_37,original: event12_37, width: 320,height: 183},
  {src: event12_38,original: event12_38, width: 320,height: 183},
  {src: event12_39,original: event12_39, width: 320,height: 183},
  {src: event12_40,original: event12_40, width: 320,height: 183},
  {src: event12_41,original: event12_41, width: 320,height: 183},
  {src: event12_42,original: event12_42, width: 320,height: 183},
  {src: event12_43,original: event12_43, width: 320,height: 183},
  {src: event12_44,original: event12_44, width: 320,height: 183},
  {src: event12_45,original: event12_45, width: 320,height: 183},
  {src: event12_46,original: event12_46, width: 320,height: 183},
  {src: event12_47,original: event12_47, width: 320,height: 183},
  {src: event12_48,original: event12_48, width: 320,height: 183},
  {src: event12_49,original: event12_49, width: 320,height: 183},
  {src: event12_50,original: event12_50, width: 320,height: 183},
  {src: event12_51,original: event12_51, width: 320,height: 183},
  {src: event12_52,original: event12_52, width: 320,height: 183},
  {src: event12_53,original: event12_53, width: 320,height: 183},
  {src: event12_54,original: event12_54, width: 320,height: 183},
  {src: event12_55,original: event12_55, width: 320,height: 183},
  {src: event12_56,original: event12_56, width: 320,height: 183},
  {src: event12_57,original: event12_57, width: 320,height: 183},
  {src: event12_58,original: event12_58, width: 320,height: 183},
  {src: event12_59,original: event12_59, width: 320,height: 183},
  {src: event12_60,original: event12_60, width: 320,height: 183},
  {src: event12_61,original: event12_61, width: 320,height: 183},
  {src: event12_62,original: event12_62, width: 320,height: 183},
  {src: event12_63,original: event12_63, width: 320,height: 183},
  {src: event12_64,original: event12_64, width: 320,height: 183},
  {src: event12_65,original: event12_65, width: 320,height: 183},
  {src: event12_66,original: event12_66, width: 320,height: 183},
  {src: event12_67,original: event12_67, width: 320,height: 183},
  {src: event12_68,original: event12_68, width: 320,height: 183},
  {src: event12_67,original: event12_67, width: 320,height: 183},
  {src: event12_68,original: event12_68, width: 320,height: 183},
  {src: event12_69,original: event12_69, width: 320,height: 183},
  {src: event12_70,original: event12_70, width: 320,height: 183},
  {src: event12_71,original: event12_71, width: 320,height: 183},
  {src: event12_72,original: event12_72, width: 320,height: 183},
  {src: event12_73,original: event12_73, width: 320,height: 183},
  {src: event12_74,original: event12_74, width: 320,height: 183},
  {src: event12_75,original: event12_75, width: 320,height: 183},
  {src: event12_76,original: event12_76, width: 320,height: 183},
  {src: event12_77,original: event12_77, width: 320,height: 183},
  {src: event12_78,original: event12_78, width: 320,height: 183},
  {src: event12_79,original: event12_79, width: 320,height: 183},
  {src: event12_80,original: event12_80, width: 320,height: 183},
  {src: event12_81,original: event12_81, width: 320,height: 183},
  {src: event12_82,original: event12_82, width: 320,height: 183},
  {src: event12_83,original: event12_83, width: 320,height: 183},
  {src: event12_84,original: event12_84, width: 320,height: 183},
  {src: event12_85,original: event12_85, width: 320,height: 183},
  {src: event12_86,original: event12_86, width: 320,height: 183},
  {src: event12_87,original: event12_87, width: 320,height: 183},
  {src: event12_88,original: event12_88, width: 320,height: 183},
  {src: event12_89,original: event12_89, width: 320,height: 183},
  {src: event12_90,original: event12_90, width: 320,height: 183},
  {src: event12_91,original: event12_91, width: 320,height: 183},
  {src: event12_92,original: event12_92, width: 320,height: 183},
  {src: event12_93,original: event12_93, width: 320,height: 183},
  {src: event12_94,original: event12_94, width: 320,height: 183},
  {src: event12_95,original: event12_95, width: 320,height: 183},
  {src: event12_96,original: event12_96, width: 320,height: 183},
  {src: event12_97,original: event12_97, width: 320,height: 183},
  {src: event12_98,original: event12_98, width: 320,height: 183},
  {src: event12_99,original: event12_99, width: 320,height: 183},
  {src: event12_100,original: event12_100, width: 320,height: 183},
  {src: event12_101,original: event12_101, width: 320,height: 183},
  {src: event12_102,original: event12_102, width: 320,height: 183},
  {src: event12_103,original: event12_103, width: 320,height: 183},
  {src: event12_104,original: event12_104, width: 320,height: 183},
  {src: event12_105,original: event12_105, width: 320,height: 183},
  {src: event12_106,original: event12_106, width: 320,height: 183},
  {src: event12_107,original: event12_107, width: 320,height: 183},
  {src: event12_108,original: event12_108, width: 320,height: 183},
  {src: event12_109,original: event12_109, width: 320,height: 183},
  {src: event12_110,original: event12_110, width: 320,height: 183},
  {src: event12_111,original: event12_111, width: 320,height: 183},
  {src: event12_112,original: event12_112, width: 320,height: 183},
  {src: event12_113,original: event12_113, width: 320,height: 183},
  {src: event12_114,original: event12_114, width: 320,height: 183},
  {src: event12_115,original: event12_115, width: 320,height: 183},
  {src: event12_116,original: event12_116, width: 320,height: 183},
 
 
];
// SECTION END 11
// SECTION START 12
export const images12: CustomImage12[] = [
  // {src: event13_1,original: event13_1, width: 320,height: 183},
  // {src: event13_2,original: event13_2, width: 320,height: 183},
  // {src: event13_3,original: event13_3, width: 320,height: 183},
  // {src: event13_4,original: event13_4, width: 320,height: 183},
  // {src: event13_5,original: event13_5, width: 320,height: 183},
  {src: event13_6,original: event13_6, width: 320,height: 183},
  {src: event13_7,original: event13_7, width: 320,height: 183},
  {src: event13_8,original: event13_8, width: 320,height: 183},
  {src: event13_9,original: event13_9, width: 320,height: 183},
  {src: event13_10,original: event13_10, width: 320,height: 183},
  {src: event13_11,original: event13_11, width: 320,height: 183},
  {src: event13_12,original: event13_12, width: 320,height: 183},
  {src: event13_13,original: event13_13, width: 320,height: 183},
  {src: event13_14,original: event13_14, width: 320,height: 183},
  {src: event13_15,original: event13_15, width: 320,height: 183},
  {src: event13_16,original: event13_16, width: 320,height: 183},
  {src: event13_17,original: event13_17, width: 320,height: 183},
  {src: event13_18,original: event13_18, width: 320,height: 183},
  {src: event13_19,original: event13_19, width: 320,height: 183},
  {src: event13_20,original: event13_20, width: 320,height: 183},
  {src: event13_21,original: event13_21, width: 320,height: 183},
  {src: event13_22,original: event13_22, width: 320,height: 183},
  {src: event13_23,original: event13_23, width: 320,height: 183},
  {src: event13_24,original: event13_24, width: 320,height: 183},
  {src: event13_25,original: event13_25, width: 320,height: 183},
  {src: event13_26,original: event13_26, width: 320,height: 183},
  {src: event13_27,original: event13_27, width: 320,height: 183},
  {src: event13_28,original: event13_28, width: 320,height: 183},
  {src: event13_29,original: event13_29, width: 320,height: 183},
  {src: event13_30,original: event13_30, width: 320,height: 183},
  {src: event13_31,original: event13_31, width: 320,height: 183},
  {src: event13_32,original: event13_32, width: 320,height: 183},
  {src: event13_33,original: event13_33, width: 320,height: 183},
  {src: event13_34,original: event13_34, width: 320,height: 183},
  {src: event13_35,original: event13_35, width: 320,height: 183},
  {src: event13_36,original: event13_36, width: 320,height: 183},
  {src: event13_37,original: event13_37, width: 320,height: 183},
  {src: event13_38,original: event13_38, width: 320,height: 183},
  {src: event13_39,original: event13_39, width: 320,height: 183},
  {src: event13_40,original: event13_40, width: 320,height: 183},
  {src: event13_41,original: event13_41, width: 320,height: 183},
  {src: event13_42,original: event13_42, width: 320,height: 183},
  {src: event13_43,original: event13_43, width: 320,height: 183},
  {src: event13_44,original: event13_44, width: 320,height: 183},
  {src: event13_45,original: event13_45, width: 320,height: 183},
  {src: event13_46,original: event13_46, width: 320,height: 183},
  {src: event13_47,original: event13_47, width: 320,height: 183},
  {src: event13_48,original: event13_48, width: 320,height: 183},
  {src: event13_49,original: event13_49, width: 320,height: 183},
  {src: event13_50,original: event13_50, width: 320,height: 183},
  {src: event13_51,original: event13_51, width: 320,height: 183},
  {src: event13_52,original: event13_52, width: 320,height: 183},
  {src: event13_53,original: event13_53, width: 320,height: 183},
  {src: event13_54,original: event13_54, width: 320,height: 183},
  {src: event13_55,original: event13_55, width: 320,height: 183},
  {src: event13_56,original: event13_56, width: 320,height: 183},
  {src: event13_57,original: event13_57, width: 320,height: 183},
  {src: event13_58,original: event13_58, width: 320,height: 183},
  {src: event13_59,original: event13_59, width: 320,height: 183},
  {src: event13_60,original: event13_60, width: 320,height: 183},
  {src: event13_61,original: event13_61, width: 320,height: 183},
  {src: event13_62,original: event13_62, width: 320,height: 183},
  {src: event13_63,original: event13_63, width: 320,height: 183},
  {src: event13_64,original: event13_64, width: 320,height: 183},
  {src: event13_65,original: event13_65, width: 320,height: 183},
  {src: event13_66,original: event13_66, width: 320,height: 183},
  {src: event13_67,original: event13_67, width: 320,height: 183},
  {src: event13_68,original: event13_68, width: 320,height: 183},
  {src: event13_67,original: event13_67, width: 320,height: 183},
  {src: event13_68,original: event13_68, width: 320,height: 183},
  {src: event13_69,original: event13_69, width: 320,height: 183},
  {src: event13_70,original: event13_70, width: 320,height: 183},
  {src: event13_71,original: event13_71, width: 320,height: 183},
  {src: event13_72,original: event13_72, width: 320,height: 183},
  {src: event13_73,original: event13_73, width: 320,height: 183},
  {src: event13_74,original: event13_74, width: 320,height: 183},
  {src: event13_75,original: event13_75, width: 320,height: 183},
  {src: event13_76,original: event13_76, width: 320,height: 183},
  {src: event13_77,original: event13_77, width: 320,height: 183},
  {src: event13_78,original: event13_78, width: 320,height: 183},
  {src: event13_79,original: event13_79, width: 320,height: 183},
  {src: event13_80,original: event13_80, width: 320,height: 183},
  {src: event13_81,original: event13_81, width: 320,height: 183},
  {src: event13_82,original: event13_82, width: 320,height: 183},
  {src: event13_83,original: event13_83, width: 320,height: 183},
  {src: event13_84,original: event13_84, width: 320,height: 183},
  {src: event13_85,original: event13_85, width: 320,height: 183},
  {src: event13_86,original: event13_86, width: 320,height: 183},
  {src: event13_87,original: event13_87, width: 320,height: 183},
  {src: event13_88,original: event13_88, width: 320,height: 183},
  {src: event13_89,original: event13_89, width: 320,height: 183},
  {src: event13_90,original: event13_90, width: 320,height: 183},
  {src: event13_91,original: event13_91, width: 320,height: 183},
  {src: event13_92,original: event13_92, width: 320,height: 183},
  {src: event13_93,original: event13_93, width: 320,height: 183},
  {src: event13_94,original: event13_94, width: 320,height: 183},
  {src: event13_95,original: event13_95, width: 320,height: 183},
  {src: event13_96,original: event13_96, width: 320,height: 183},
  {src: event13_97,original: event13_97, width: 320,height: 183},
  {src: event13_98,original: event13_98, width: 320,height: 183},
  {src: event13_99,original: event13_99, width: 320,height: 183},
  {src: event13_100,original: event13_100, width: 320,height: 183},
  {src: event13_101,original: event13_101, width: 320,height: 183},
  {src: event13_102,original: event13_102, width: 320,height: 183},
  {src: event13_103,original: event13_103, width: 320,height: 183},
  {src: event13_104,original: event13_104, width: 320,height: 183},
  {src: event13_105,original: event13_105, width: 320,height: 183},
  {src: event13_106,original: event13_106, width: 320,height: 183},
  {src: event13_107,original: event13_107, width: 320,height: 183},
  {src: event13_108,original: event13_108, width: 320,height: 183},
  {src: event13_109,original: event13_109, width: 320,height: 183},
  {src: event13_110,original: event13_110, width: 320,height: 183},
  {src: event13_111,original: event13_111, width: 320,height: 183},
 
 
 
];

// SECTION END 12




// SECTION START 13
export const images13: CustomImage13[] = [
   {src: event14_1,original: event14_1, width: 320,height: 183},
   {src: event14_2,original: event14_2, width: 320,height: 183},
   {src: event14_3,original: event14_3, width: 320,height: 183},
   {src: event14_4,original: event14_4, width: 320,height: 183},
 ];

// SECTION END 13

// SECTION START 14
export const images14: CustomImage14[] = [
  {src: event15_1,original: event15_1, width: 320,height: 183},
  {src: event15_2,original: event15_2, width: 320,height: 183},
  {src: event15_3,original: event15_3, width: 320,height: 183},
  {src: event15_4,original: event15_4, width: 320,height: 183},
  {src: event15_5,original: event15_5, width: 320,height: 183},
  {src: event15_6,original: event15_6, width: 320,height: 183},
  {src: event15_7,original: event15_7, width: 320,height: 183},
];

// SECTION END 14


// SECTION START 15
export const images15: CustomImage15[] = [
  {src: event16_1,original: event16_1, width: 320,height: 183},
  {src: event16_2,original: event16_2, width: 320,height: 183},
  {src: event16_3,original: event16_3, width: 320,height: 183},
  {src: event16_4,original: event16_4, width: 320,height: 183},
  {src: event16_5,original: event16_5, width: 320,height: 183},
  {src: event16_6,original: event16_6, width: 320,height: 183},
  {src: event16_7,original: event16_7, width: 320,height: 183},
  {src: event16_8,original: event16_8, width: 320,height: 183},
 
];

// SECTION END 15