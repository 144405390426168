import React from "react";
  

const Events = () => {
    return (
        <div className="Events">
             <div className='container pt-5 pb-5'>
      <div className="row ">
                    <div className="col-sm-12 ">
            <ul className="list_m14">
                <li>
                        <h3>Events 1 <small className="font_16"> (20 June 2022)</small></h3>
                        <p>NHRC notice to DGP , Maharashtra over mob lynching of three persons in police presence in Palghar District</p>
                </li>
                <li>
                        <h3>Events 2</h3>
                        <p>
NHRC asks Centre to issue suitable advisory to States and UTs to implement lock down guidelines without undermining people’s right to life, liberty and dignity</p>
                </li>
                <li>
                        <h3>Events 3</h3>
                        <p>
NHRC asks Centre about the arrangements made for mentally ill people roaming on streets during COVID lockdown: Asks to issue directions to States and Union Territories in this regard and to ensure they don’t become easy carriers of the deadly virus</p>
                </li>
                <li>
                        <h3>Events 4</h3>
                        <p>
NHRC dismisses the complaint of the mother of Nirbhaya case convict Mukesh Kumar seeking its intervention to prevent his execution</p>
                </li>
                <li>
                        <h3>Events 5</h3>
                        <p>NHRC OPENS TWITTER ACCOUNT</p>
                </li>
                <li>
                        <h3>Events 6</h3>
                        <p>Curtain Raiser: Conference on Human Rights of LGBTQI: Challenges and Way Forward at NLU, Bengaluru on 26th February, 2020</p>
                </li>
                <li>
                        <h3>Events 7</h3>
                        <p>NHRC-SHRCs meeting concludes with several important suggestions for protection of Human Rights</p>
                </li>
                <li>
                        <h3>Events 8</h3>
                        <p>NHRC notice to the Government of Uttar Pradesh over reported deaths due to malnutrition in Ojhaganj village of the Basti district</p>
                </li>
                <li>
                        <h3>Events 9</h3>
                        <p>Curtain raiser: NHRC-SHRCs meeting at India Habitat Centre, New Delhi tomorrow on 20th February, 2020</p>
                </li>
                <li>
                        <h3>Events 10</h3>
                        <p>On 8th March 2016, AIMS conducted painting exhibition at Women Commission Office Budha Bhavan, Secunderabad to create awareness on women and children rights. The exhibition was conducted with the cooperation of A.P State Women Commission.</p>
                </li>

           </ul>
            
            </div>
            </div>
            </div>
        </div>
    )
};

export default Events;
 