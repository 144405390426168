import React from "react";
import "../styles/organisationreg.css";
import user_icon from "../assets/images/input_iocn1.png"
import user_icon1 from "../assets/images/input_iocn2.png"
import user_icon2 from "../assets/images/input_iocn3.png"
import user_icon3 from "../assets/images/input_iocn4.png"
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import { useFormik } from "formik";
import * as Yup from "yup";
import Helmet from "../components/Helmet/Helmet";
 

export const signUpSchema = Yup.object({
    nameOrg: Yup.string().min(2).max(25).required("Please enter your Organisation name"),
    emailOfc: Yup.string().email().required("Please enter your Office email"),
    phoneNumberOfc: Yup.string().min(6).max(12).required("Please enter your Office Phone Number"),
    website: Yup.string().min(2).required("Please enter your Website"),
    NgoNumber: Yup.string().min(6).max(12).required("Please enter your NGO Number"),
    address: Yup.string().min(2).required("Please enter your Address"),
    city: Yup.string().min(2).required("Please enter your City"),
    state: Yup.string().min(2).required("Please enter your State"),
    country: Yup.string().min(2).required("Please enter your Country"),
    pincode: Yup.string().min(6).max(6).required("Please enter your Pin Code"),
    name: Yup.string().min(2).max(25).required("Please enter your name"),
    post: Yup.string().min(2).max(25).required("Please enter your Post"),
    email: Yup.string().email().required("Please enter your email"),
    phoneNumber: Yup.string().min(6).max(12).required("Please enter your Phone Number"),
})

const initialValues = {
    nameOrg:"",
    emailOfc:"",
    phoneNumberOfc:"",
    website:"",
    NgoNumber:"",
    address:"",
    city:"",
    state:"",
    country:"",
    pincode:"",
    name:"",
    post:"",
    email:"",
    phoneNumber:"",
}

const UserReg = () => {


    // form validation

    const {values, errors, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues,
        validationSchema: signUpSchema,
        onSubmit: (values, action) => {
          console.log(
            "~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
            values
          );
          action.resetForm();
        }
    })
    return (
        <Helmet title={'Register New User'}>
        <div className="organisation__reg py-5">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-6 offset-xxl-3 col-lg-8 offset-lg-2">
                        <div className="card card-body shadow">
                            <div className="circle_m4"></div>
                            <div className="circle_m4 left_align"></div>
                <div className="organisation__header text-center mb-5">
                    <h5 className="fw-semibold">Register as New User</h5>
                </div>
              
              <form onSubmit={handleSubmit}  >
                <div className="row">
                    <div className="col-md-6">
                  
                                <div className="float_input imp_star mb-5">
                                   <img alt="" src={user_icon} />
                                {/* <FloatingLabelInput  id="example-3" label="First Name" /> */}
                                <FloatingLabel controlId="floatingInput" label="First Name" >
                            <Form.Control name="fname" value={values.fname} onChange={handleChange} onBlur={handleBlur} placeholder="First Name"/>
                       </FloatingLabel>
                                  { errors.fname && touched.fname ? <p>{errors.fname}</p> : null}
                                  
                                </div>
                    </div>
                    <div className="col-md-6">
                                <div className="float_input imp_star mb-5">
                                <img alt="" src={user_icon}/>
                                   
                                     {/* <FloatingLabelInput label="Last Name" value={values.lname} onChange={handleChange} onBlur={handleBlur} type="text" name="lname" /> */}
                                     <FloatingLabel controlId="floatingInput" label="Last Name" >
                            <Form.Control value={values.lname} onChange={handleChange} onBlur={handleBlur} type="text" name="lname" placeholder="Last Name"/>
                       </FloatingLabel>
                                    { errors.lname && touched.lname ? <p>{errors.lname}</p> : null}
                                </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                    <div className="float_input imp_star mb-5">
                                <img alt="" src={user_icon1} />
                              
                                 {/* <FloatingLabelInput label="phone Number" id="example-3" value={values.phoneNumber} onChange={handleChange}    onBlur={handleBlur} name="phoneNumber"/> */}
                                 <FloatingLabel controlId="floatingInput" label="phone Number" >
                            <Form.Control  value={values.phoneNumber} onChange={handleChange}    onBlur={handleBlur} name="phoneNumber" placeholder="phone Number"/>
                       </FloatingLabel>
                                 { errors.phoneNumber && touched.phoneNumber ? <p className="mb-0 c_5">{errors.phoneNumber}</p> : null} 
                            </div>
                    </div>
                    <div className="col-md-6">
                    <div className="float_input imp_star mb-5">
                                <img alt="" src={user_icon2} />
                             
                                 {/* <FloatingLabelInput label="Email ID" value={values.email} onChange={handleChange} onBlur={handleBlur} name="email" /> */}
                                 <FloatingLabel controlId="floatingInput" label="Email ID" >
                            <Form.Control value={values.email} onChange={handleChange} onBlur={handleBlur} name="email" placeholder="Email ID"/>
                       </FloatingLabel>
                                { errors.email && touched.email ? <p className="mb-0 c_5">{errors.email}</p> : null}
                            </div>
                    </div>

                    <div className="col-md-6">
                    <div className="float_input imp_star mb-5">
                                <img alt="" src={user_icon3} />
                                {/* <FloatingLabelInput label="Password"  name="email" /> */}
                                <FloatingLabel controlId="floatingInput" label="Password" >
                            <Form.Control  placeholder="Password"/>
                       </FloatingLabel>
                     </div>
                    </div>

                    <div className="col-md-6">
                    <div className="float_input imp_star mb-5">
                                <img alt="" src={user_icon3} />
                                {/* <FloatingLabelInput label="Confirm Password"  name="email" /> */}
                                <FloatingLabel controlId="floatingInput" label="Confirm Password" >
                            <Form.Control  placeholder="Confirm Password"/>
                       </FloatingLabel>
                     </div>
                    </div>

                    
                </div>


                {/* <div className="sub__header text-center my-3">
                    <h5 className="py-3">Address</h5>
                </div> */}

                {/* <div className="row">
                    <div className="col-md-6">
                               <div className="input_m2">
                                    <label>Address <span className="star_imp">*</span></label>
                                    <input value={values.address} onChange={handleChange} onBlur={handleBlur} type="text" name="address"/>
                                    { errors.address && touched.address ? <p>{errors.address}</p> : null}
                                </div>
                    </div>
                    <div className="col-md-6">
                           <div className="input_m2">
                                <label>City <span className="star_imp">*</span></label>
                                <input type="text" value={values.city} onChange={handleChange} onBlur={handleBlur} name="city"/>
                                { errors.city && touched.city ? <p>{errors.city}</p> : null}
                            </div>
                    </div>
                </div> */}

                {/* <div className="row">
                    <div className="col-md-6">
                            <div className="input_m2">
                                <label>State <span className="star_imp">*</span></label>
                                <input type="text" value={values.state} onChange={handleChange} onBlur={handleBlur} name="state"/>
                                { errors.state && touched.state ? <p>{errors.state}</p> : null}
                            </div>
                    </div>
                    <div className="col-md-6">
                            <div className="input_m2">
                                <label>Country <span className="star_imp">*</span></label>
                                <input type="text" value={values.country} onChange={handleChange} onBlur={handleBlur} name="country"/>
                                { errors.country && touched.country ? <p>{errors.country}</p> : null}
                            </div>
                    </div>
                </div> */}

                {/* <div className="row">
                    <div className="col-md-6">
                            <div className="input_m2">
                                <label>Pin Code <span className="star_imp">*</span></label>
                                <input type="number" value={values.pincode} onChange={handleChange} onBlur={handleBlur} name="pincode"/>
                                { errors.pincode && touched.pincode ? <p>{errors.pincode}</p> : null}
                            </div>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-sm-12 text-center">
                            <button  type="submit" className="btn_m5 px-4">Sign Up</button>
                    </div>
                </div>
                </form>

                
                <div className="row mt-4">
                    <div className="col-md-6">
                        <p className="m-0">Already have an account? <a href="#!" className="link_m3 fw-semibold">Sign In</a> </p>
                    </div>
                    <div className="col-md-6">
                        <p className="m-0">Are you an NGO / Organisation? <a href="#!" className="link_m3 fw-semibold">Register</a> </p>
                    </div>
                </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </Helmet>
    )
};

export default UserReg;