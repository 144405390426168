import { Image } from "react-grid-gallery";
// Event 1 start here
import child1 from "../assets/images/amis/paints/childrens/child-(1).jpg";
import child2 from "../assets/images/amis/paints/childrens/child-(2).JPG";
import child3 from "../assets/images/amis/paints/childrens/child-(3).jpg";
import child4 from "../assets/images/amis/paints/childrens/child-(4).jpg";
import child5 from "../assets/images/amis/paints/childrens/child-(5).jpg";
import child6 from "../assets/images/amis/paints/childrens/child-(6).jpg";
import child7 from "../assets/images/amis/paints/childrens/child-(7).jpg";
import child8 from "../assets/images/amis/paints/childrens/child-(8).jpg";
import child9 from "../assets/images/amis/paints/childrens/child-(9).jpg";
import child10 from "../assets/images/amis/paints/childrens/child-(10).jpg";
import child11 from "../assets/images/amis/paints/childrens/child-(11).jpg";
import child12 from "../assets/images/amis/paints/childrens/child-(12).jpg";
import child13 from "../assets/images/amis/paints/childrens/child-(13).jpg";
import child14 from "../assets/images/amis/paints/childrens/child-(14).jpg";
import child15 from "../assets/images/amis/paints/childrens/child-(15).jpg";
import child16 from "../assets/images/amis/paints/childrens/child-(16).jpg";
import child17 from "../assets/images/amis/paints/childrens/child-(17).jpg";
import child18 from "../assets/images/amis/paints/childrens/child-(18).jpg";
import child19 from "../assets/images/amis/paints/childrens/child-(19).jpg";
import child20 from "../assets/images/amis/paints/childrens/child-(20).jpg";
import child21 from "../assets/images/amis/paints/childrens/child-(21).jpg";


import child22 from "../assets/images/amis/paints/childrens/5.png";
import child23 from "../assets/images/amis/paints/childrens/6.png";
import child24 from "../assets/images/amis/paints/childrens/7.png";
import child25 from "../assets/images/amis/paints/childrens/9.png";
import child26 from "../assets/images/amis/paints/childrens/10.png";
import child27 from "../assets/images/amis/paints/childrens/4.png";
// Event 1 End here

// Event 2 start here
import womens1 from "../assets/images/amis/paints/womens/women-(1).jpg";
import womens2 from "../assets/images/amis/paints/womens/women-(2).jpg";
import womens3 from "../assets/images/amis/paints/womens/women-(3).jpg";
import womens4 from "../assets/images/amis/paints/womens/women-(4).jpg";
import womens5 from "../assets/images/amis/paints/womens/women-(5).jpg";
import womens6 from "../assets/images/amis/paints/womens/women-(6).jpg";
import womens7 from "../assets/images/amis/paints/womens/women-(7).JPG";
import womens8 from "../assets/images/amis/paints/womens/women-(8).jpg";
import womens9 from "../assets/images/amis/paints/womens/women-(9).jpg";
import womens10 from "../assets/images/amis/paints/womens/women-(10).jpg";
import womens11 from "../assets/images/amis/paints/womens/women-(11).jpg";
import womens12 from "../assets/images/amis/paints/womens/women-(12).jpg";
import womens13 from "../assets/images/amis/paints/womens/women-(13).jpg";
import womens14 from "../assets/images/amis/paints/womens/women-(14).jpg";
import womens15 from "../assets/images/amis/paints/womens/women-(15).jpg";
import womens16 from "../assets/images/amis/paints/womens/women-(16).jpg";
import womens17 from "../assets/images/amis/paints/womens/women-(17).jpg";
import womens18 from "../assets/images/amis/paints/womens/women-(18).jpg";

// Event 2 End here

// Event 3 start here
import society1 from "../assets/images/amis/paints/society/society-(1).jpg";
import society2 from "../assets/images/amis/paints/society/society-(2).jpg";
import society3 from "../assets/images/amis/paints/society/society-(3).jpg";
import society4 from "../assets/images/amis/paints/society/society-(4).jpg";
import society5 from "../assets/images/amis/paints/society/society-(5).jpg";
import society6 from "../assets/images/amis/paints/society/society-(6).jpg";
import society7 from "../assets/images/amis/paints/society/society-(7).jpg";
import society8 from "../assets/images/amis/paints/society/society-(8).jpg";
import society9 from "../assets/images/amis/paints/society/society-(9).jpg";
import society10 from "../assets/images/amis/paints/society/society-(10).jpg";
import society11 from "../assets/images/amis/paints/society/society-(11).jpg";
import society12 from "../assets/images/amis/paints/society/society-(12).jpg";
import society13 from "../assets/images/amis/paints/society/society-(13).jpg";
import society14 from "../assets/images/amis/paints/society/society-(14).jpg";
import society15 from "../assets/images/amis/paints/society/society-(15).jpg";
import society16 from "../assets/images/amis/paints/society/society-(16).jpg";
import society17 from "../assets/images/amis/paints/society/society-(17).jpg";
import society18 from "../assets/images/amis/paints/society/society-(18).jpg";
import society19 from "../assets/images/amis/paints/society/society-(19).jpg";
import society20 from "../assets/images/amis/paints/society/society-(20).jpg";
import society21 from "../assets/images/amis/paints/society/society-(21).jpg";

import society22 from "../assets/images/amis/paints/society/society-(22).jpg";
import society23 from "../assets/images/amis/paints/society/society-(23).jpg";
import society24 from "../assets/images/amis/paints/society/society-(24).jpg";
import society25 from "../assets/images/amis/paints/society/society-(25).jpg";
import society26 from "../assets/images/amis/paints/society/society-(26).jpg";
import society27 from "../assets/images/amis/paints/society/society-(27).jpg";
import society28 from "../assets/images/amis/paints/society/society-(28).jpg";
import society29 from "../assets/images/amis/paints/society/society-(29).jpg";
import society30 from "../assets/images/amis/paints/society/society-(30).jpg";

import society31 from "../assets/images/amis/paints/society/1.png";
import society32 from "../assets/images/amis/paints/society/2.png";
import society33 from "../assets/images/amis/paints/society/3.png";
import society34 from "../assets/images/amis/paints/society/8.png";
import society35 from "../assets/images/amis/paints/society/11.png";
 
// Event 3 End here

import awards1 from "../assets/images/amis/documents/1.png";
import awards2 from "../assets/images/amis/documents/2.png";
import awards3 from "../assets/images/amis/documents/3.png";

// ENGLISH PAINTS START HERE

import english_paint1 from "../assets/images/amis/english/1.png";
import english_paint2 from "../assets/images/amis/english/2.png";
import english_paint3 from "../assets/images/amis/english/3.png";
import english_paint4 from "../assets/images/amis/english/4.png";
import english_paint5 from "../assets/images/amis/english/5.png";
import english_paint6 from "../assets/images/amis/english/6.png";
import english_paint7 from "../assets/images/amis/english/7.png";
import english_paint8 from "../assets/images/amis/english/8.png";
import english_paint9 from "../assets/images/amis/english/9.png";
import english_paint10 from "../assets/images/amis/english/10.png";
import english_paint11 from "../assets/images/amis/english/11.png";
import english_paint12 from "../assets/images/amis/english/12.png";
import english_paint13 from "../assets/images/amis/english/13.png";
import english_paint14 from "../assets/images/amis/english/14.png";
import english_paint15 from "../assets/images/amis/english/15.png";
import english_paint16 from "../assets/images/amis/english/16.png";
import english_paint17 from "../assets/images/amis/english/17.png";
import english_paint18 from "../assets/images/amis/english/18.png";
import english_paint19 from "../assets/images/amis/english/19.png";
import english_paint20 from "../assets/images/amis/english/20.png";
import english_paint21 from "../assets/images/amis/english/21.png";
import english_paint22 from "../assets/images/amis/english/22.png";
import english_paint23 from "../assets/images/amis/english/23.png";
import english_paint24 from "../assets/images/amis/english/24.png";
import english_paint25 from "../assets/images/amis/english/25.png";
import english_paint26 from "../assets/images/amis/english/26.png";
import english_paint27 from "../assets/images/amis/english/27.png";
import english_paint28 from "../assets/images/amis/english/28.png";
import english_paint29 from "../assets/images/amis/english/29.png";
import english_paint30 from "../assets/images/amis/english/30.png";
import english_paint31 from "../assets/images/amis/english/31.png";
import english_paint32 from "../assets/images/amis/english/32.png";
import english_paint33 from "../assets/images/amis/english/33.png";
import english_paint34 from "../assets/images/amis/english/34.png";
import english_paint35 from "../assets/images/amis/english/35.png";
import english_paint36 from "../assets/images/amis/english/36.png";
import english_paint37 from "../assets/images/amis/english/37.png";
import english_paint38 from "../assets/images/amis/english/38.png";
import english_paint39 from "../assets/images/amis/english/39.png";
import english_paint40 from "../assets/images/amis/english/40.png";
import english_paint41 from "../assets/images/amis/english/41.png";
import english_paint42 from "../assets/images/amis/english/42.png";
import english_paint43 from "../assets/images/amis/english/43.png";
// ENGLISH PAINTS END HERE

export interface CustomImage extends Image {
  original: string;
}
export interface CustomImage1 extends Image {
  original: string;
}
export interface CustomImage2 extends Image {
  original: string;
}
export interface CustomImage3 extends Image {
  original: string;
}

export interface CustomImage4 extends Image {
  original: string;
}
export interface CustomImage5 extends Image {
  original: string;
}
export interface CustomImage6 extends Image {
  original: string;
}

export const images: CustomImage[] = [
  { src: child1, original: child1, width: 320, height: 183 },
  { src: child2, original: child2, width: 320, height: 183 },
  { src: child3, original: child3, width: 320, height: 183 },
  { src: child4, original: child4, width: 320, height: 183 },
  { src: child5, original: child5, width: 320, height: 183 },
  { src: child6, original: child6, width: 320, height: 183 },
  { src: child7, original: child7, width: 320, height: 183 },
  { src: child8, original: child8, width: 320, height: 183 },
  { src: child9, original: child9, width: 320, height: 183 },
  { src: child10, original: child10, width: 320, height: 183 },
  { src: child11, original: child11, width: 320, height: 183 },
  { src: child12, original: child12, width: 320, height: 183 },
  { src: child13, original: child13, width: 320, height: 183 },
  { src: child14, original: child14, width: 320, height: 183 },
  { src: child15, original: child15, width: 320, height: 183 },
  { src: child16, original: child16, width: 320, height: 183 },
  { src: child17, original: child17, width: 320, height: 183 },
  { src: child18, original: child18, width: 320, height: 183 },
  { src: child19, original: child19, width: 320, height: 183 },
  { src: child20, original: child20, width: 320, height: 183 },
  { src: child21, original: child21, width: 320, height: 183 },

  { src: child22, original: child22, width: 320, height: 183 },
  { src: child23, original: child23, width: 320, height: 183 },
  { src: child24, original: child24, width: 320, height: 183 },
  { src: child25, original: child25, width: 320, height: 183 },
  { src: child26, original: child26, width: 320, height: 183 },
  { src: child27, original: child27, width: 320, height: 183 },
];

export const images1: CustomImage1[] = [
  { src: womens1, original: womens1, width: 320, height: 183 },
  { src: womens2, original: womens2, width: 320, height: 183 },
  { src: womens3, original: womens3, width: 320, height: 183 },
  { src: womens4, original: womens4, width: 320, height: 183 },
  { src: womens5, original: womens5, width: 320, height: 183 },
  { src: womens6, original: womens6, width: 320, height: 183 },
  { src: womens7, original: womens7, width: 320, height: 183 },
  { src: womens8, original: womens8, width: 320, height: 183 },
  { src: womens9, original: womens9, width: 320, height: 183 },
  { src: womens10, original: womens10, width: 320, height: 183 },
  { src: womens11, original: womens11, width: 320, height: 183 },
  { src: womens12, original: womens12, width: 320, height: 183 },
  { src: womens13, original: womens13, width: 320, height: 183 },
  { src: womens14, original: womens14, width: 320, height: 183 },
  { src: womens15, original: womens15, width: 320, height: 183 },
  { src: womens16, original: womens16, width: 320, height: 183 },
  { src: womens17, original: womens17, width: 320, height: 183 },
  { src: womens18, original: womens18, width: 320, height: 183 },
];

export const images2: CustomImage2[] = [
  { src: society1, original: society1, width: 320, height: 183 },
  { src: society2, original: society2, width: 320, height: 183 },
  { src: society3, original: society3, width: 320, height: 183 },
  { src: society4, original: society4, width: 320, height: 183 },
  { src: society5, original: society5, width: 320, height: 183 },
  { src: society6, original: society6, width: 320, height: 183 },
  { src: society7, original: society7, width: 320, height: 183 },
  { src: society8, original: society8, width: 320, height: 183 },
  { src: society9, original: society9, width: 320, height: 183 },
  { src: society10, original: society10, width: 320, height: 183 },
  { src: society11, original: society11, width: 320, height: 183 },
  { src: society12, original: society12, width: 320, height: 183 },
  { src: society13, original: society13, width: 320, height: 183 },
  { src: society14, original: society14, width: 320, height: 183 },
  { src: society15, original: society15, width: 320, height: 183 },
  { src: society16, original: society16, width: 320, height: 183 },
  { src: society17, original: society17, width: 320, height: 183 },
  { src: society18, original: society18, width: 320, height: 183 },
  { src: society19, original: society19, width: 320, height: 183 },
  { src: society20, original: society20, width: 320, height: 183 },
  { src: society21, original: society21, width: 320, height: 183 },
  { src: society22, original: society22, width: 320, height: 183 },
  { src: society23, original: society23, width: 320, height: 183 },
  { src: society24, original: society24, width: 320, height: 183 },
  { src: society25, original: society25, width: 320, height: 183 },
  { src: society26, original: society26, width: 320, height: 183 },
  { src: society27, original: society27, width: 320, height: 183 },
  { src: society28, original: society28, width: 320, height: 183 },
  { src: society29, original: society29, width: 320, height: 183 },
  { src: society30, original: society30, width: 320, height: 183 },
  

  { src: society31, original: society31, width: 320, height: 183 },
  { src: society32, original: society32, width: 320, height: 183 },
  { src: society33, original: society33, width: 320, height: 183 },
  { src: society34, original: society34, width: 320, height: 183 },
  { src: society35, original: society35, width: 320, height: 183 },
];

export const images3: CustomImage3[] = [
  { src: awards1, original: awards1 },
  { src: awards2, original: awards2 },
  { src: awards3, original: awards3 },
];

export const images4: CustomImage4[] = [
  { src: english_paint9, original: english_paint9 },
  { src: english_paint12, original: english_paint12 },
  { src: english_paint18, original: english_paint18 },
  { src: english_paint20, original: english_paint20 },
  { src: english_paint28, original: english_paint28 },
  { src: english_paint29, original: english_paint29 },
  { src: english_paint30, original: english_paint30 },
  { src: english_paint31, original: english_paint31 },
  { src: english_paint32, original: english_paint32 },
  { src: english_paint33, original: english_paint33 },

  { src: english_paint34, original: english_paint34 },
  { src: english_paint35, original: english_paint35 },
  { src: english_paint36, original: english_paint36 },
  { src: english_paint37, original: english_paint37 },
  { src: english_paint38, original: english_paint38 },
  { src: english_paint39, original: english_paint39 },
];

export const images5: CustomImage5[] = [
  { src: english_paint1, original: english_paint1 },
  { src: english_paint3, original: english_paint3 },
  { src: english_paint4, original: english_paint4 },
  { src: english_paint5, original: english_paint5 },
  { src: english_paint6, original: english_paint6 },
  { src: english_paint7, original: english_paint7 },
  { src: english_paint8, original: english_paint8 },
  { src: english_paint10, original: english_paint10 },
  { src: english_paint11, original: english_paint11 },
  { src: english_paint13, original: english_paint13 },
  { src: english_paint14, original: english_paint14 },
  { src: english_paint15, original: english_paint15 },
];

export const images6: CustomImage6[] = [
  { src: english_paint2, original: english_paint2 },
  { src: english_paint7, original: english_paint7 },
  { src: english_paint16, original: english_paint16 },

  { src: english_paint17, original: english_paint17 },
  { src: english_paint19, original: english_paint19 },
  { src: english_paint21, original: english_paint21 },
  { src: english_paint22, original: english_paint22 },
  { src: english_paint23, original: english_paint23 },
  { src: english_paint24, original: english_paint24 },
  { src: english_paint25, original: english_paint25 },
  { src: english_paint26, original: english_paint26 },
  { src: english_paint27, original: english_paint27 },

  { src: english_paint40, original: english_paint40 },
  { src: english_paint41, original: english_paint41 },
  { src: english_paint42, original: english_paint42 },
  { src: english_paint43, original: english_paint43 },
];
