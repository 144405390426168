import React from "react";
//import banner from "../assets/images/Activities_banner.png"

import campainsImg1 from "../assets/images/campaigns1.png";
import right_arrow from "../assets/images/right_arrow.png";
import { Tabs, Tab,TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import campaign from "../assets/images/campaign.png";
import aboutBanner1 from "../assets/images/group_99.png";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Link } from "react-router-dom";
import arrow_right from "../assets/images/circle-right-arrow.png"
import donate1 from "../assets/images/donate1.png"
import donate2 from "../assets/images/donate2.png"
import 'react-circular-progressbar/dist/styles.css';
import events_activities from "../assets/images/events_activities.png";
const percentage = 66;

const Activities = () => {
    
    const [activeTab, setActiveTab] = React.useState(0);
     
    const onTabClick = (e, index) => {
        
        setActiveTab(index);
      };

    //  const [activeTab1, setActiveTab1] = React.useState(0);
    //   const onTabClick1 = (e, index) => {
    //       console.log(e);
    //       setActiveTab1(index);
    //     };
    return (
        <div>
            <div className="sub_level_banner">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-4  offset-md-1">
                <div className="about__banner-text">
                  <h1 className="block__title">Activities</h1>
                  <h5>
                  Aims Human  Rights Society activities during the year 2009 to 2024
                  </h5>
                </div>
              </div>
              <div className="col-md-6 offset-md-1 about__banner-img pr-md-0">
                <div className=" sub_pages_banner">
                  <img
                    src={events_activities}
                    alt="About Banner"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
             
         
                <div className="bg_m4">
                <div className="container py-5">
                {/* <div className="row pt-2 mb-0 pb-4">
                    <div className="col-md-8 offset-md-2 text-center block__header mb-0">
                        <h1 className="block__title mb-1 position-relative">OUR ACTIVITIES</h1>
                        <p className="block__paragraph mb-0 color_1">Aims Human  Rights Society activities during the year 2009 to 2024</p>
                    </div>
                </div> */}
                    <div className="tabs_m4">
        <Tabs activeTab={activeTab} onTabClick={onTabClick}>
           <Tab key='Tab1'>2009</Tab>
           <Tab key='Tab2'>2010</Tab>
           <Tab key='Tab3'>2011</Tab>
           <Tab key='Tab4'>2012</Tab>
           <Tab key='Tab5'>2013</Tab>
           <Tab key='Tab6'>2014</Tab>
           <Tab key='Tab7'>2015</Tab>
           <Tab key='Tab8'>2016</Tab>
           <Tab key='Tab9'>2017</Tab>
           <Tab key='Tab10'>2018</Tab>
           <Tab key='Tab11'>2019</Tab>
           <Tab key='Tab12'>2020</Tab>
           <Tab key='Tab13'>2021</Tab>
           <Tab key='Tab14'>2022</Tab>
           <Tab key='Tab15'>2023</Tab>
           <Tab key='Tab16'>2024</Tab>
        </Tabs>
             <TabScreen  key='Tab1' activeTab={activeTab}  index={0} className="some-animation-class">
                <ul className="list_m15">
                        <li><span><small>January </small><small>24th </small></span><p className="m-0">Aims conducted painting exhibition at Word and deed school, Hayath  nagar  to create awarenessof Human Rights, among teachers, students, women and children. Dr. Narasimha Rao Additional Judge was the honourable chief guest. </p></li>
                        <li><span><small>April</small><small>12th </small></span><p className="m-0">Rights awareness campaigns were conducted in slums around Saroornagar,which includeds distribution of literature and interactive sessions with local women and children. </p></li>
                        <li><span><small>May</small><small>24th </small></span><p className="m-0">Rights awareness campaign was conducted in Banjara Colony, Hayath nagar mandal. Interactive session was conducted with local women groups. Discussion on Domestic violence and legal Remedies were also sorted out.</p></li>
                        <li><span><small>July</small><small>19th </small></span><p className="m-0">Rights awareness campaign was conducted at L.B. nagar slums  involving few local women volunteers. Here Distribution of literature and Interactive session on Domestic violence, Dowry harassment and children rights were elaborated.</p></li>
                        <li><span><small>September</small><small>27th</small></span><p className="m-0">visited slums in Santosh nagar area where factories are located. Interaction with few trade union workers regarding child labour. Distribution of literature. </p></li>
                        <li><span><small>November</small><small>15th</small></span><p className="m-0">Visited backward areas of Bandlaguda. Interaction with few women groups of the area on Domestic violence and women rights were discussed. Distribution of literatures took place</p></li>
                </ul>
             </TabScreen>
             <TabScreen  key='Tab2' activeTab={activeTab}  index={1} className="some-animation-class">
            
             <ul className="list_m15">
                        <li><span><small>January</small><small>30st</small></span><p className="m-0">Aims conducted Rights awareness program in Nacharam industrial  and Uppal industrial areas. Interaction with  Women workers  on wages, domestic violence and harassment at working place were discussed. Distribution of literature also took place.</p></li>
                        <li><span><small>May</small><small>23rd</small></span><p className="m-0">Rights awareness program were conducted in Dammaiguda slums. Interaction with local women volunteers on women and children rights were discussed. Distribution of literature also took place. </p></li>
                        <li><span><small>August</small><small>23 and 24</small></span><p className="m-0">Aims had  interaction with college going students at Habsiguda X roads. Distribution of pocket books on Ragging printed in Telugu and English were distributed.</p></li>
                        <li><span><small>December</small><small>10th</small></span><p className="m-0">Aims ,on the eve of Universal declaration of Human rights, Visited few colleges and schools in Hyderabad and Secunderabad and Distributed pamphlets  and books Published by the society to create awareness of Human rights.</p></li>
                      
                        </ul>

             </TabScreen>
             <TabScreen  key='Tab3' activeTab={activeTab}  index={2} className="some-animation-class">
             <ul className="list_m15">
                        <li><span><small>January</small><small>23rd </small></span><p className="m-0">Aims visited slums in Jagadgiri gutta area in western part of Ranga Reddy district. Interaction with local women leaders on women and children rights and eve teasing were discussed. Distribution of  literature of the society also took place.</p></li>
                        <li><span><small>January</small><small>31st</small></span><p className="m-0">Aims visited backward areas of Gopanna palli Tanda. Interaction with the local women dailywage workers, workers rights and domestic violence were discussed. Distribution of literature also took place. </p></li>
                        <li><span><small>December</small><small>10th</small></span><p className="m-0">Aims conducted paintings exhibition on atrocities of Women. Children and their Human rights were discussed at the premises of AP Women’s commission office, Hyderabad. Mrs.Sarala Rajyalakshmi, Secretary, Women’s commission was the chief guest.</p></li>
                      
           </ul>
             </TabScreen>
             <TabScreen  key='Tab4' activeTab={activeTab}  index={3} className="some-animation-class">
             <ul className="list_m15">
                        <li><span><small>March</small><small>8th</small></span><p className="m-0">On the eve of International Women’s day, Conducted paintings exhibition at Press club, Somajiguda, Hyderabad, to create awareness on women and children rights. The exhibition was conducted with the cooperation of AP state working women Journalists. </p></li>

                        <li><span><small>May</small><small>20th</small></span><p className="m-0">Aims visited Industrial area of Katedan and  Interacted with  local trade union leaders. Explained to them about problems and legal issues pertaining  to child labour. We also Distributed literature among all of them.</p></li>

                        <li><span><small>July</small><small>15th</small></span><p className="m-0">Aims Visited slums in Santhosh nagar. Interacted with local women groups and explained to them about Domestic violence and Dowry harassment acts and Distributed literature among all of them.</p></li>
                        <li><span><small>September</small><small>9th</small></span><p className="m-0">Aims visited Industrial areas of Saidabad. Interaction with women workers and trade union activists took place  on different issues pertaining to minimum wages, child labor, and harassment of women at their place of work was discussed.</p></li>
                        <li><span><small>December</small><small>12th</small></span><p className="m-0">Aims conducted  workshop of legal awareness about  rights and violations against women and children.Books and broachers  about  laws  distributed to Anganwadi workers and college students at A.P Women’s commission, Buddhabhavan on the occasion of ‘Universal Human rights declaration day’Signature campaign conducted with A.P Women’s commission about visual paintings of the rights and violations.</p></li>
            </ul>
             </TabScreen>
             <TabScreen  key='Tab5' activeTab={activeTab}  index={4} className="some-animation-class">
             <ul className="list_m15">
                        <li><span><small>March</small><small>8th</small></span><p className="m-0">AIMS conducted painting exhibition at Women Commission office, Budha Bhavan, Secunderabad, to create awareness on women and children rights. The exhibition was conducted with the cooperation of A.P. State Women Commission.</p></li>
                        <li><span><small>June </small><small>1st</small></span><p className="m-0">2013 AIMS visited nursing colleges in and around Hyderabad and Secunderabad, explained to them about problems and legal issues pertaining to child labor. We also distributed literature among all of them.</p></li>
                        <li><span><small>August</small><small>15th</small></span><p className="m-0">AIMS visited slums in Subhashnagar in Malkajgiri area, interacted with local women groups and explained to them about Domestic Violence and Dowry harassment acts and Distributed literature among all of them.</p></li>
                        <li><span><small>September</small><small>19th</small></span><p className="m-0">AIMS visited GHMC street cleaning workers in morning hours. Interaction with women workers and trade union activists took place also on different issues pertaining to minimum wages, child labor, and harassment of women at their place of work was discussed.</p></li>
                        <li><span><small>December </small><small>10th</small></span><p className="m-0">AIMS conducted workshop of legal awareness about rights and violations against women and children. Books and brochures about laws distributed at Rajasree Vidya Mandir, Pedda Amberpet, Ranga Reddy District, on the occasion of Universal Human Rights Declaration Day and also signature campaign conducted about visual paintings of the Rights and Violations.</p></li>
                        </ul>
             </TabScreen>
             <TabScreen  key='Tab6' activeTab={activeTab}  index={5} className="some-animation-class">
             <ul className="list_m15">
                <li><span><small>March</small><small>8th </small></span><p className="m-0">AIMS conducted painting exhibition in different schools and colleges in L.B.Nagar ,Malkajghery and Medichal to create awareness on women and children rights. The exhibition was conducted with the cooperation of Advocates..</p></li>
                <li><span><small>June </small><small>15st</small></span><p className="m-0">AIMS visited Women’s colleges HayatNagar and Uppal Mandals explained to them about problems and legal issues pertaining to Women and child labor. We also distributed literature among all of them.</p></li>
                <li><span><small>August </small><small>15th </small></span><p className="m-0">AIMS visited slums in Safilguda in Malkajgiri area, interacted with local women groups and explained to them about Domestic Violence and Dowry harassment acts and Distributed literature among all of them.</p></li>
                <li><span><small>October</small><small>18th</small></span><p className="m-0">AIMS volunteers visited different slum areas like Parasigutta, Adikmet and Moulali had interaction with women workers who belongs to unorganized sector like vegetable market vendors and hospital sanitary staff. Interaction with women workers on different issues like gender equality, payment system, discrimination and sexual harassment. in working areas</p></li>
                <li><span><small>December</small><small>10th</small></span><p className="m-0">AIMS conducted workshop of legal awareness about rights and violations against women and children. at  Aims Register office premises Alwal among women teachers and family court advocates enquired different practical case details facing by women and students in society .Reaction and mechanism of new law like “nirbhaya” act,on occasion of Universal Human Rights Declaration  Day and also collected suggestions for awareness steps for unorganized sector and working class women and children.</p></li>
               </ul>
             </TabScreen>
             <TabScreen  key='Tab7' activeTab={activeTab}  index={6} className="some-animation-class">
             <ul className="list_m15">
                <li><span><small>March</small><small>8th </small></span><p className="m-0">AIMS conducted paintings exhibition at Press club, somaji guda, Hyderabad for women journalists  to create awareness of new laws and cases of women and children rights. The exhibition was conducted with the cooperation of women journalists to learn their experience in society while doing their job.</p></li>
                <li><span><small>March</small><small>8th </small></span><p className="m-0">AIMS conducted paintings exhibition at Press club, somaji guda, Hyderabad for women journalists  to create awareness of new laws and cases of women and children rights. The exhibition was conducted with the cooperation of women journalists to learn their experience in society while doing their job.</p></li>
                <li><span><small>Jully</small><small>11th </small></span><p className="m-0">AIMS visited Women’s colleges medichel and  malkhajigiri  Mandals explained to them about problems and legal issues pertaining to Women and child labor. We  have  distributed literature among all of them for awareness</p></li>
                <li><span><small>August</small><small>15th</small></span><p className="m-0">AIMS visited slums in Safilguda in Malkajgiri area, interacted with local women groups and explained to them about Domestic Violence and Dowry harassment acts and Distributed literature among all of them for awareness and suggestions.</p></li>
                <li><span><small>December</small><small>10th</small></span><p className="m-0">AIMS conducted workshop of legal awareness about rights and violations against women and children. at  Aims Register office premises Alwal among women teachers and family court advocates enquired different practical case details facing by women and students in society .Reaction and mechanism of new law like “nirbhaya” act,on occasion of Universal Human Rights Declaration  Day and also collected suggestions for awareness steps for unorganized sector and working class women and children.</p></li>
               </ul>
             </TabScreen>
             <TabScreen  key='Tab8' activeTab={activeTab}  index={7} className="some-animation-class">
             <ul className="list_m15">
             <li><span><small>March</small><small>8th </small></span><p className="m-0">AIMS conducted painting exhibition at Women Commission office, Budha Bhavan, Secunderabad, to create awareness on women and children rights. The exhibition was conducted with the cooperation of A.P. State Women Commission.</p></li>
                <li><span><small>June </small><small>7st  </small></span><p className="m-0">AIMS visited nursing colleges in and around Hyderabad and Secunderabad, explained to them about problems and legal issues pertaining to child labor. We also distributed literature among all of them.</p></li>
                <li><span><small>August </small><small>11th  </small></span><p className="m-0">AIMS visited slums in Subhashnagar in Malkajgiri area, interacted with local women groups and explained to them about Domestic Violence ,Child marriages, Child  labour and Dowry harassment acts and Distributed literature among  them. And Explained their Rights</p></li>
                <li><span><small>September </small><small>19th  </small></span><p className="m-0">AIMS visited GHMC street cleaning workers in morning hours. Interaction with women workers and trade union activists took place also on different issues pertaining to minimum wages, child labor, and harassment of women at their place of work was discussed.</p></li>
                <li><span><small>December </small><small>10th  </small></span><p className="m-0">Aims conducted Universal declaration day of Human Rights at our Register office premises  planed for program me for 2018.we have discussed Fund Raising from Pharmacy companies to promote malnutrition in rural areas with education and Eliminate child labour in certain Villages </p></li>
                </ul>
                </TabScreen>

                <TabScreen  key='Tab9' activeTab={activeTab}  index={8} className="some-animation-class">
             <ul className="list_m15">
               
                <li><span><small>March</small><small>8th </small></span><p className="m-0">AIMS conducted painting exhibition at Women Commission office, Budha Bhavan, Secunderabad, to create awareness on women and children rights. The exhibition was conducted with the cooperation of A.P. State Women Commission.</p></li>
                <li><span><small>June </small><small>1st  </small></span><p className="m-0">AIMS visited nursing colleges in and around Hyderabad and Secunderabad, explained to them about problems and legal issues pertaining to child labor. We also distributed literature among all of them.</p></li>
                <li><span><small>August </small><small>15th </small></span><p className="m-0">AIMS visited slums in Subhashnagar in Malkajgiri area, interacted with local women groups and explained to them about Domestic Violence and Dowry harassment acts and Distributed literature among all of them.</p></li>
                <li><span><small>September </small><small>19th  </small></span><p className="m-0">AIMS visited GHMC street cleaning workers in morning hours. Interaction with women workers and trade union activists took place also on different issues pertaining to minimum wages, child labor, and harassment of women at their place of work was discussed.</p></li>
                <li><span><small>December </small><small>10th  </small></span><p className="m-0">Aims conducted Legal awareness program about Rights and violations against women and Children at Aims Register office Premises Alwal  Employees of Alwal municipalities and Teachers from Private schools By Explain about their Rights under our Constitution  as well as distributed books and Nirbhya booklets.</p></li>
                </ul>
                </TabScreen>

                <TabScreen  key='Tab10' activeTab={activeTab}  index={9} className="some-animation-class">
             <ul className="list_m15">
                <li><span><small>March</small><small>8th </small></span><p className="m-0">On the eve of International Women’s Day, 8th March 2018, AIMS distributed books and brochures in different schools and colleges</p></li>
                <li><span><small>June</small><small>1st</small></span><p className="m-0">AIMS visited Kalwakurty and Jadcherla   We also distributed to un organized workers and street vendors</p></li>
                <li><span><small>July</small><small>15th </small></span><p className="m-0">AIMS visited villages and hamlets adjacent to Kodangal,,,Pargi and Moinabad  area, interacted with local women groups and explained to them about Domestic Violence and Dowry harassment acts and Distributed literature among all of them.</p></li>
                <li><span><small>September</small><small>22th</small></span><p className="m-0">AIMS visited GHMC street cleaning workers in morning hours. Interaction with women workers and done survey about early hours accidents on road sides and Precautions about road safety awareness’s</p></li>
                <li><span><small>December</small><small>10th</small></span><p className="m-0">Aims conducted Legal awareness program about Rights and violations against women and Children at Aims Register office Premises Alwal Employees of Alwal municipalities and Teachers from Private schools By Explain about their Rights under our Constitution as well as distributed books and Nirbhaya booklets.</p></li>
               </ul>
                </TabScreen>

                <TabScreen  key='Tab11' activeTab={activeTab}  index={10} className="some-animation-class">
             <ul className="list_m15">
                <li><span><small>March</small><small>8th </small></span><p className="m-0">On the eve of International Women’s Day, 8th March 2019, AIMS conducted painting exhibition at Wesley women’s college at Secunderabad. conducted seminar and Interaction programme among students about present problems they are facing in colleges and schools like transportation, harassment, Raging, inequality among colleges on social status and other discrimination s.as well as conducted Workshop social media effect among college and School students</p></li>
                <li><span><small>June </small><small>1st  </small></span><p className="m-0">
                AIMS visited in and around Hyderabad and Secunderabad, collected road accident data’s and. reasons We also distributed literature among all of them. Identify the black patches on City accident places and </p></li>
                <li><span><small>August </small><small>15th  </small></span><p className="m-0">AIMS visited slums in Subhash nagar in Malkajgiri area, interacted with local women groups and explained to them about Domestic Violence and Dowry harassment acts and Distributed literature among all of them.</p></li>
                <li><span><small>September </small><small>19th  </small></span><p className="m-0">AIMS visited GHMC street cleaning workers in morning hours. Interaction with women workers and trade union activists took place also on different issues pertaining to minimum wages, child labor, and harassment of women at their place of work was discussed.</p></li>
                <li><span><small>December </small><small>10th  </small></span><p className="m-0">Aims conducted Legal awareness program about Rights and violations against women and Children at Aims Register office Premises Alwal Employees of Alwal municipalities and Teachers from Private schools By Explain about their Rights under our Constitution as well as distributed books and Nirbhaya booklets.</p></li>
               </ul>
                </TabScreen>

                <TabScreen  key='Tab12' activeTab={activeTab}  index={11} className="some-animation-class">
             <ul className="list_m15">
                <li><span><small>June </small><small>1st  </small></span><p className="m-0">AIMS visited in and around Hyderabad and Secunderabad, visited various places where street side and foot path living people distrusted Food packets because of Corona curfew </p></li>
                <li><span><small>September</small><small>21th</small></span><p className="m-0">AIMS visited GHMC street cleaning workers in afternoon hours distributed food packets   </p></li>
              
                </ul>
                </TabScreen>

                <TabScreen  key='Tab13' activeTab={activeTab}  index={12} className="some-animation-class">
             <ul className="list_m15">
                <li><span><small>March  </small><small>8th  </small></span><p className="m-0">AIMS visited in Sikh village, Picket, Venkata Puram in afternoon hours distributed books to women and children for awareness about their Rights due to Corona virus keep distance safety measures</p></li>
                <li><span><small>March  </small><small>8th  </small></span><p className="m-0">AIMS conducted awareness program at the Registered office in the afternoon hours distributed books to women and children for awareness about their Rights.</p></li>

                <li><span><small>December  </small><small>10th</small></span><p className="m-0">Human Rights Day we have distributed To Nursing Hostels in Znims Hospitals and Gandhi Hospitals.</p></li>
                <li><span><small>Not</small><small>Available</small></span><p className="m-0">Due to colleges and schools are closed we could not conduct any kind of Programmes in this Year  also due to virus problems </p></li>
              </ul>
                </TabScreen>

                <TabScreen  key='Tab14' activeTab={activeTab}  index={13} className="some-animation-class">
             <ul className="list_m15">
                <li><span><small>Not</small><small>Available</small></span><p className="m-0">
                  Aims conducted Programs at Famous Reformer in Kerala state and later spread his thoughts across India. Sri Narayana swamy school at Military Dairy Firm Road, Secunderabad Principal conducted workshop and given Lectures along with High court Advocate Smt Pavani Kalyan about Their legal rights and Court Trails
                  </p></li>
                  <li><span><small>Not</small><small>Available</small></span><p className="m-0">
                    Also conducted surveys Road safety for Pedestrians who walk on Road with any space left for walkers except Roads due to occupied by Hawkers and Road salesmen by violating Road and safety rules resolution passed in 2010 in Parliament
                    </p></li>
               
             </ul>
                </TabScreen>

                <TabScreen  key='Tab15' activeTab={activeTab}  index={14} className="some-animation-class">
             <ul className="list_m15">
             <li className="date_not_avl">
                <p>
                AIMS HUMAN RIGHTS SOCIETY is primarily concerned about the Rights of Women and Children and has taken up many awareness programs involving women volunteers from different walks of life. As most of the women and children are belongs to backward   and downtrodden sectors and are not much literate. AIMS is trying to educate them through photos, paintings and literature.
                </p>
              </li>
              <li  className="date_not_avl"><p> This year we have conducted workshops and seminars on domestic violence, dowry harassment, child labor Sexual harassment, Child Marriage and Ragging. AIMS mainly concentrated on awareness programs by distributing literature pertaining to domestic violence, eve teasing and by conducting group discussions among women, child workers and Students to promote awareness of the prevailing laws for the protection of their Rights in East Godavari dist. Andhra Pradesh.</p></li>
              <li  className="date_not_avl"><p>Aims conducted Programs at Indian Famous Social reformers college “Kandukuri Rajalakshmi Govt   women’s college, Rajahmundry on 23/8/2023. Seminar conducted and along with Paintings Exhibition of social evils as well as violations against women and children. Dy superintendent of police, DISHA   mahila division, Sri Tirumala Rao who chaired with Principal conducted workshop and given Lectures.</p></li>
              <li  className="date_not_avl"><p>Aims conducted Program at historical education reformer late Naicker who founded a college M.S.N.  Charities, Jagannatha Puram, Kakinada for Downtrodden society on 24/8/2023 with Disha mahila Police Officials conducted Awareness Programme distributed books of their rights to defend themselves under our Constitution. </p></li>
              <li  className="date_not_avl"><p>Aims conducted another Programme at St Ann Gloria EM High School, Ramachandra Puram on  
26/8/2023 We have conducted a survey about their rights and Modern Life and conducted survey with question paper and answers from them about their ideas and behavior. As a Chief guest Deputy Superintendent of Police, Sri T, S.R. K. Prasad, and Principal Mr. Sharat Kumar participated and given very good Lectures for Young children about their rights and norms of life and keeping their minds free to get good education and Playing Sports to keep their health.
</p></li>
             </ul>
                </TabScreen>

                <TabScreen  key='Tab16' activeTab={activeTab}  index={15} className="some-animation-class">
             <ul className="list_m15">
            <h3 className="text-center p-3">Data Not Available</h3>

             </ul>
                </TabScreen>

              </div>

              </div>
              </div>
{/* Tabs Start */}
<div className="shape_m5 d-none">
    <div className="container">
        <div className="row">
            <div className="col-sm-12 text-center">
<div className="basic__header text-center mb-5 pt-5">   
                    <h1 className="block__title mt-5">you can <span className="highlight">campaign</span> for</h1>
                   
                  
                   
                    </div>
                </div>
                {/* <div className="tabs_m4">
        <Tabs activeTab={activeTab1} onTabClick={onTabClick1}>
           <Tab key='Tab1'>Poverty</Tab>
           <Tab key='Tab2'>Health</Tab>
           <Tab key='Tab3'>Children</Tab>
           <Tab key='Tab4'>Hunger</Tab>
           <Tab key='Tab5'>Education</Tab>
           <Tab key='Tab6'>Old Age</Tab>
           <Tab key='Tab7'>Environment</Tab>
        </Tabs>
             <TabScreen  key='Tab1' activeTab={activeTab1}  index={0} className="some-animation-class">
                <div className="row">
                <div className="col-sm-8">
                    <h5>Poverty</h5>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <p className="mt-5">It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                <div class="pt-4 pb-4">
                    
                 
                    <div className=" mt-5">
     <button className="btn btn_m5  ">Start a Campaign <img src={arrow_right} className="ms-2" alt=""/></button>
</div>
                    </div>
                </div>
                <div className="col-sm-4">
                 <img src={campaign} alt="Why U-Saver Image" className="img-fluid"/>  
                </div>
                </div>
             </TabScreen>
             <TabScreen  key='Tab2' activeTab={activeTab1}  index={1} className="some-animation-class">2</TabScreen>
             <TabScreen  key='Tab3' activeTab={activeTab1}  index={2} className="some-animation-class">3</TabScreen>
             <TabScreen  key='Tab4' activeTab={activeTab1}  index={3} className="some-animation-class">4</TabScreen>
             <TabScreen  key='Tab5' activeTab={activeTab1}  index={4} className="some-animation-class">5</TabScreen>
             <TabScreen  key='Tab6' activeTab={activeTab1}  index={5} className="some-animation-class">6</TabScreen>
             <TabScreen  key='Tab7' activeTab={activeTab1}  index={6} className="some-animation-class">7</TabScreen>
              </div> */}

              

               
                </div>

                <div className="row">
                <div className="col-sm-8">
                    <h5 className="fw-semibold">Poverty</h5>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <p className="mt-5">It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                <div class="pt-4 pb-4">
                    
                 
                    <div className=" mt-2">
     <button className="btn btn_m5 pe-2 ">Start a Campaign <img src={arrow_right} className="ms-2" alt=""/></button>
</div>
                    </div>
                </div>
                <div className="col-sm-4">
                 <img src={campaign} alt="Why U-Saver" className="img-fluid"/>  
                </div>
                </div>

                </div>
                </div>
{/* Tabs End */}

<div className="container">
          <div className="row ">
            <div className="col-sm-12  mt-5">
              <h1 class="block__title">Main activities including</h1>
              <p>
                <span className="fw-bold">Subjects/issues covered:</span> Fundamental
                Rights/Freedom/Expression/Equality under our Indian Constitution
                and also Universal Declaration of Human Rights of Women and
                Children.
              </p>
              <p>
                <span  className="fw-bold">Social coverage by specific social group/community:</span>{" "}
                Because literacy is a very low by exhibiting paintings of Rights
                and Violations of Women and Children by this rural people and
                semi urban can aware of their Rights. Apart from this the
                density of Domestic Violence Act and other amended laws
                awakening among women and children.
              </p>
              <p>
                <span  className="fw-bold">Geographical coverage: </span> Rangareddy Dt., Hyderabad
                and Secunderabad.
              </p>
              <p>
                <span  className="fw-bold">Source (s) of funding: </span> 2005 to 2011 society
                assembled their own funds from the members of the Society. Now
                realized unless and until oblige and take grants of the
                different central government organisations and donors cannot
                reach rural, semi-urban and suppressed people of the society.
              </p>
            </div>
            <div className="col-md-12  mb-5 ps-4">
              <p className="mb-0">a) Has the organisation already conducted any activity similar to the proposed project and source of funding:</p>
              <p>Till now we are conducted six exhibitions with own assembled funds and a small donation to meet partial expenditure by donors. Now we got sanction of Income Tax exemptions to raise funds and completed five years hence we got audit reports for applying grants to conduct these exhibitions and to meet expenditure towards awareness literature and books.</p>
              <p>b) The list of current trustees/governing body members with their addresses, and date of their election and appointment and the respective terms: Enclosed</p>
              <p>c) Information about the director/chief functionary</p>
              <p className="ps-3">1) brief note on the background/experience and current address and telephone numbers (Office/Residence): Enclosed</p>
              <p>d) Accounts/Audit arrangements: Enclosed</p>
              <p>e) Registration details (Trust/Society/Co-operative/etc., Registered Office-full postal address with details of pin code, telephone, fax, email etc.): Enclosed</p>
              <p>f) Income Tax exemption status, if any:</p>
              <p className="ps-3">1) Income tax order u/s 12AA(1)(b)(i) of the Income Tax Act, 1961, dated 28-05-2010.</p>
              <p className="ps-3">2) Approval u/s 80G(S)(vi) of the Income Tax Act, 1961, dated 1-03-2011.</p>
              <p>g) Banker details (name of the bank and branch with address, account numbers):</p>
              <p>i) Andhra Pradesh State Cooperative Bank Ltd, Alwal, Lothukunta Branch, Secunderabad. A/c No. 36/3761/508241</p>
              <p>ii) Union Bank, Secunderabad Branch, A/c, No. 02010095430</p>

            </div>
           
          </div>
        </div>
  
             
               
                </div>
      
    )
};

export default Activities;