import React from "react";
//import announcemnts from "../assets/images/amis/announcemnts.jpg";

const Announcement = () => {
    return (
        <div className="Announcement">
             <div className='container pt-5 pb-5'>
      <div className="row ">
                    <div className="col-sm-8 offset-md-2 ">
                    {/* <img src={announcemnts} alt="Why U-Saver Image" className="img-fluid"/>    */}
            
            </div>
            </div>
            </div>
        </div>
    )
};

export default Announcement;
 